
$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$blue_fade: rgba(3, 63, 99, 0.7);
$ming-green: #28666E;
$bright-ornge: #FFBC42;
$dGreen:#7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP:#BEB7DF;
$medPurple:#8A84E2;
$purp_fade:rgba(138, 132, 226, 0.7);
$navajo_white: #FEDC97;
$navajoWhite:#FCEBC9;
$off-black:#282c33;
$off-white:#f2e7d2;
$ming-fade: rgba(40, 102, 110, 0.7);
$gold-fade: rgba(255, 188, 66, 0.7);
$navajo_fade: rgba(254, 220, 151, 0.7);
$sage: #B5B682;
$sage-fade: rgba(181, 182, 130, 0.7);
$totem: #9e2e0b;



//hobby,//zazen,med,practical,yoga/movement,relaxation
.MedPhotoIcon{
    width:70px;
    height:70px;
    border-radius: 50%;
    border-color: $dGreen;
    border-style: solid;
    border-width: 2px;
    display: block;
    float: left;
    box-shadow: $dGreen 0px 0px 10px;
    margin-left: -5px;
    margin-top: 5px;
    
  }


.launch-planner-session{
    width:fit-content;
    padding:3px;
    margin:auto;
    background-color: $off-white;
    border-radius: 5px;
    border-color: $navajo_white;
    border-width: 2px;
    border-left-width: 5px;
    border-style: solid;
}
  .journal-skip{
    width:fit-content;
    margin:auto;
    color:$totem;
  }

  .journalMedPhoto{
    margin:auto;
  }

  .journal-practice-title{
    color:$gold-fade;
  }

  .journal-log-header{
    text-align: center;
    margin: auto;
    width: fit-content;
    margin-top:20px;
  }
.journal-date{
    margin-top:5px;
    margin-bottom: 5px;
    color:$navajo_fade;
}
  .journal-entries-holder{
    height: 55vh;
    overflow-y: scroll;
    padding-bottom: 250px;
  }
.journal-title-shared{
    margin-bottom: 0;
    margin-top: 0;
}
  .choose-session-modify-type-holder{
    padding-top:25px;
    margin:auto;
    display: flex;
    flex-direction: row;
    width:340px;
  }
  .choose-session-modify-type-icon{

    width:140px;
    height:140px;
    margin: auto;;
    background-color: $navajo_fade;
    border-radius: 50%;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
  }

  .planner-edit-type-icon{
    background-size: cover;
    background-image: url("../../static/photos/languageIcons/editIcon.png");
  }

  .choose-session-modify-type{

    width:fit-content;
    margin: auto;
  }

  .planner-link-content-icon{
    background-image: url("../../static/photos/languageIcons/plusIcon.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

  }

  .recommended-link-session-holder{
    margin-top:25px;
    height:650px;
    overflow-y: scroll;
    padding-top: 15px;;
  }
.edit-session-form{
    text-align: center;
    padding-top:30px;
  }
  .choosePPHeader{
      padding-top: 3vh;
  }
  .choosePPHolder{
      height: 100vh;
      position:fixed;
      top:0;
      max-height: 100vh;
      padding-bottom:250px;
      overflow-y: scroll;
      width: 100vw;
      display:"flex"; 
      flex-direction:"row"; 
      width:"350px";
      margin:"auto"
  }
.my-zen-bookmarks{
    width: 100%;
}
.MyZenGoals{
    width:100%;
    position: fixed;
    top:0;
    padding-top: 170px;
    height: 100vh;
    background-color: $navajoWhite;
}
.zen-goals-skill-map{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    height:350px;
    display: block;
    margin-bottom: 50px;
    opacity:0.3;
}

.editSessionHolder{
    
}

.blackOut{
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: $off-black;
    opacity: 0.4;
    float: left;
    top: 0;
    position: absolute;
}
.focused-item{
    z-index: 999;
    position: absolute;
    
}

.ZenGoalsOptionChosen{
    width: 115px;
    opacity: 0.9;
    border: $toms_blue;
    border-width: 2px;
    border-style: solid;
    margin:auto;
    border-radius: 5px;

}

.chosenGoalsList{
    padding-top: 15px;
}



.chooseGoalsList{
    width:350px;
    padding-top: 15px;
    max-width: 100vw !important;
}
  
  .addZenGoal{
    position: absolute;
    right: 5px;
    display: inline-block;
    height: 35px;
    width: 35px;
    background-size: contain;
    background-image: url("../../static/photos/languageIcons/addIcon.png");
    border-radius: 50%;
  }

  .removeZenGoal{
    position: absolute;
    right: 5px;
    display: inline-block;
    height: 35px;
    width: 35px;
    background-size: cover;
    background-image: url("../../static/photos/languageIcons/deleteIcon.png");
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
    border-radius: 50%;
  }


.my-zen-FAV{
    width: 100vw;;
    max-width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 40px;
}
.ZenGoalsOptionText{
    width: 100%;
    margin: auto;
}

.ZenGoalsOption::before{
    background-color: #C1666B;
}

.option-button-small{
    background-color: $off-white;
    border-width: 0;
    border-left-width: 10px;
    border-style: solid;
    border-radius: 5px;
    border-color: $budd-fade;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    color: $primary_black;
    display:inline-block;
    margin:auto;
    text-align: center;
    overflow: hidden;
    min-width:100px;
    padding: 5px;
    margin-bottom: 10px;
    margin-left:10px;
    margin-right: 10px;
}

.days-option-gen{
    display:inline-block;
    margin:auto;
    text-align: center;
    overflow: hidden;
    min-width:100px;
    margin-bottom: 10px;
    margin-left:20px;
    margin-right: 20px;
}
.ZenGoalsOption{
    background-color: $navajoWhite;
    border-width: 0;
    border-left-width: 10px;
    border-right-width: 10px;
    border-style: solid;
    border-radius: 5px;
    border-color: $navajo_white;
    border-right-color: $navajoWhite;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    color: $primary_black;
    margin:auto;
    text-align: center;
    width: 110px;
    overflow: hidden;
    padding: 5px;
    margin-bottom: 10px;
    height: 70px;
    vertical-align: middle;
}
.set-traits-holder{
    padding-top: 25px;
}
.chosenZenGoal{
    background-color: $sage-fade;
    border-color: $sage;
}

.chooseGoalsList{
    max-width: 100vw !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ZenGoalsOptions{
    min-width: 325px;
    padding-top: 15px;
    margin:auto;
}

.confirmValidation{
    width: 150px;
    background-color: $sage-fade;
    border-width: 0;
    border-bottom-width: 10px;
    border-style: solid;
    border-radius: 5px;
    border-color: $sage;
    width: fit-content;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
    color: $primary_black;
    margin:auto ;
    margin-top: 30px;
  }

  
.confirmButton{
    width: 150px;
    background-color: $sage-fade;
    border-width: 0;
    border-left-width: 10px;
    border-style: solid;
    border-radius: 5px;
    border-color: $sage;
    width: fit-content;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
    color: $primary_black;
    margin:auto ;
    font-size: 15px;
    line-height: 10px;
    margin:10px;
  }

  .journalConfirmButton{
    max-height:40px;
  }
.ResendValidation{
    width: 150px;
    background-color: $budd-fade;
    border-width: 0;
    border-bottom-width: 10px;
    border-style: solid;
    border-radius: 5px;
    border-color: $budd_orange;
    width: fit-content;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
    color: $primary_black;
    margin:auto ;
    margin-top: 14px;
  }
  .ValidationResent{
    width: 80%;
    margin: auto;
    margin-top: 14px;
  }

.vi{
  border: none;
}

.EnterValidationCode{
    font-size:25px;
}

.email-validator *{
    box-shadow: none;
    border-radius: 5px;
    font-family: 'ZenKurenaido';
    -webkit-appearance: none;
}
.email-validator{
    width: 300px;
    max-width: 300px;
    margin: auto;
    font-family: 'ZenKurenaido' !important;
}
.email-validator input{
    background-color: $navajo_fade;
    border-style: solid !important;
    border-width: 0;
    border-bottom-width: 10px !important;
    border-color: $navajo_white !important;
    border-radius: 5px !important;
    overflow: hidden;
    font-size:25px;
    min-width:30px;
    max-width:45px;
    margin-left: 4px;
    font-family: 'ZenKurenaido' !important;
    -webkit-appearance: none;
}

// .email-validator input{
//     background-color: $navajo_fade;
//     border-style: solid;
//     border-width: 0;
//     border-bottom-width: 10px;
//     border-color: $navajo_white;
//     border-radius: 5px;
//     font-size:25px;
//     padding-bottom: 5px;
//     min-width:50px;
// }

.EnterValidationCodeCharacter{
    background-color: $navajo_fade;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 10px;
    border-color: $navajo_white;
    border-radius: 5px;
    font-size:25px;
    padding-bottom: 5px;
    min-width:50px;
}

.val-email-header{
  padding-top: 25px;
  padding-bottom: 15px;
}
.EnterValidationCodeHolder{
  width: 324px;
  margin: auto;
  position: static;
  display: block;
  margin-top: 60px;
  font-size: 20px;
}


.input .mood-search{
    height: 120px;
}
.journal-button{
    background-image: url("../../static/photos/languageIcons/addIcon.png");
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
    background-size: cover;
    margin-top: 10px;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px inset, rgba(0, 0, 0, 0.35) 0px 5px 10px;
    transition: .2s;
}


.journal-button:hover{
    width: 90px;
    height: 90px;
    border-radius: 45px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px inset, rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.log-mood-button{

    background-image: url("../../static/photos/mood.png");
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
    background-size: cover;
    margin: auto;
    margin-top: 10px;
    border-radius: 40px;
    box-shadow:  rgba(0, 0, 0, 0.35) 0px 5px 10px;
}
.sessionControl{
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
    background-size: cover;
    margin: auto;
    margin-top: 10px;
    border-radius: 20px;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;

}
.editSessionAttribute{
    font-size: 24px;
    line-height: 48px;
}
.editSessionControl{
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    background-size: cover;
    border-radius: 18px;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
    display: inline-block;
    margin-left: 12px;
    position: relative;
    top: 6px;
}
.deleteSession{
    background-image: url("../../static/photos/languageIcons/deleteIcon.png");
}

.chosenSessionType{
    box-shadow: $budd_orange 0px 0px 15px;

}


.editSession{
    background-image: url("../../static/photos/languageIcons/editIcon.png");
}

.blueprint-session-controls{
    float: right;
    margin-top: -10px;
    
}
.planner-session-controls{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.practice-session-title-row{
    width: 100%;
    min-width: 100%;
}

.cancelCreateSessionHolder{
    background-color: $budd-fade;
    text-align: center;
    padding: 7px;
    width: fit-content;
    margin: auto;
    margin:10px;
    color: $off-black;
    height: fit-content;
    border-style: solid;
    border-width: 0;
    border-left-width: 12px;
    border-radius: 7.5px;
    border-color: $budd_orange;
    padding-right: 10px;  
    font-size:15px;
    line-height: 15px;
}

.confirmCreateSessionHolder{
    background-color: $sage-fade;
    text-align: center;
    padding: 7px;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
    color: $off-black;
    height: 30px;
    border-style: solid;
    border-width: 0;
    border-left-width: 15px;
    border-radius: 7.5px;
    border-color: $sage;
    padding-right: 10px;  
    font-size:15px;
    line-height: 15px;

}
.addSessionHolder{
    background-color: $off-white;
    text-align: center;
    width: 50vw;
    margin: auto;
    margin-top: 0px;
    color: $off-black;
    height: 35px;
    border-style: solid;
    border-width: 0;
    border-left-width: 15px;
    border-radius: 7.5px;
    border-color: $toms_blue;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}
.addSessionHolderActive{
    box-shadow: $toms_blue 0px 5px 10px;
}
.addSessionPlus{
    background-image: url("../../static/photos/languageIcons/plusLanguageIcon.png");
    width: 30px;
    height: 30px;
    background-size: cover;
    border-radius: 15px;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
    position: relative;
}
.addSessionHeader{
    width: 100%;
    min-width: 100%;
    font-weight: 600;
    font-size: 125%;
}
.addSessionPlusO{
    background-image: url("../../static/photos/languageIcons/plusLanguageIcon.png");
    width: 30px;
    height: 30px;
    margin: auto;
    margin-top: 12px;
    background-size: cover;
}
.sessionSetPractice{
    background-image: url("../../static/photos/languageIcons/addIcon.png");
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
    background-size: cover;
    margin-top: 10px;
    border-radius: 20px;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.sessionStartPractice{
    background-image: url("../../static/photos/languageIcons/goIcon.png");
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
    background-size: cover;
    margin-top: 10px;
    border-radius: 20px;
    box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 10px;
}
.create-session-page{
    margin-top: 20px;
}

.practice-preview-type{
    padding:7px;
    border-radius:5px;
    width: fit-content;
    margin: auto;
    padding-top: 0;
    padding-bottom: 3px;
    font-size: 80%;
    font-weight: 600;
    margin-top: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}
.session-preview-duration{
    
    font-size:90%;
    padding:0;
    color: $off-black;
    margin:auto;
    line-height: 25px;
    height: 25px;
    text-align: center;
    width: fit-content;
    font-weight: 900;
}
.session-preview-title{
    font-size:90%;
    padding:0;
    color: $off-black;
    margin:auto;
    line-height: 15px;
    height: fit-content;
    text-align: center;
    width: fit-content;
}
.session-preview-subtitle{
    font-size:80%;
    padding:0;
    color: $off-black;
    margin:auto;
    line-height: 20px;
    height: 20px;
    text-align: center;
    width: fit-content;
}
.med-session .practice-preview-type{
    background-color: $sage;
}
.med-session{
    background-color: $sage-fade;
    border-color: $sage;
}
.chooseSessionType{
    width: 25vw;
    min-width: 100px;
    height: 30px;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    padding-top: 5px;
    font-weight: 900;
    margin-bottom: 10px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 8px;
}

.create-session-form{
    text-align: center;
    padding-right: 25px;
    width: 100vw;
    margin-top: 10px;

}

.ChooseMyZenBlueprintGoals{
}
.bp-goal-title{
    padding-bottom: 15px;
    width: 80%;
    margin: auto;

}
.bp-goal-choice{
    text-align: center;
    margin: auto;
    width: 80vw;
    border-width: 0;
    border-left-width: 10px;
    border-style: solid;
    border-radius: 5px; 
    padding: 5px;
    padding-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
    margin-bottom: 10px;
    background-color: $navajo_white;
    color:$primary_black;
    max-height: 10vh;
}

.bp-goal-choice-alt{
    background-color: $navajoWhite
}

.bp-goal-choice h2{
    font-size: 100%;
    margin:0;
}
.bp-goal-choice h3{
    font-size: 80%;
    margin:0;
}

.rec-icon{
    width: 30px;
    height: 30px;
    background-size: contain;
    background-image: url("../../static/photos/zencyclogo.png");
    display: inline-block;
    position: relative;
    top: 10px;
}
.rec-tag{
    position: absolute;
    color: $budd_orange;
    margin-left: 5px;
    margin-top: 15px;
    width: 25px;
    height: 22px;
    background-size: contain;
    background-image: url("../../static/photos/zencyclogo.png");
    background-repeat:no-repeat;
}
.recommended-goal{
    background-color: $bright-ornge;
}
.chooseSessionRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
}
.bookmark-bookmark-filter{
    
    background-size: contain;
    background-image: url("../../static/photos/languageIcons/bookmark.png");
}
.bookmark-bookmark-filter-selected{
    
    background-size: contain;
    background-image: url("../../static/photos/languageIcons/bookmarked.png");
}
.bookmark-filter{
    margin-top:5px;
    width: 33%;
    height:70px;
    font-size: 200%;
    padding-top: 25px;
    background-repeat: no-repeat;
    margin:auto;
    background-position: center;
}

.bookmark-favourite-filter{
    background-size: contain;
    background-image: url("../../static/photos/languageIcons/heart.png");
}
.bookmark-favourite-filter-selected{
    background-size: contain;
    background-image: url("../../static/photos/languageIcons/heartSelected.png");

}
.lecture-session{
    background-color: $blue_fade;
    border-color: $toms_blue;
}
.my-zen-planner-bookmarks{
    padding-top: 10px;
}
.my-zen-planner{
    width: 100vw;
}

.bookmark-search{
}
.bm-saveForLaterIcon{
    color: $toms_blue;
}

.bookmarks-search-bar-container{
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    align-content: center;
    width: 33%;
    min-width: 33%;
    margin-top: 25px;
  }
.yoga-session{
    background-color: $navajo_fade;
    border-color: $navajo_white;
}
.relax-session{
    background-color: $purp_fade;
    border-color: $medPurple;
}
.hobby-session{
    background-color: $ming-fade;
    border-color: $ming-green;
}
.zazen-session{
    background-color: $gold-fade;
    border-color: $bright-ornge;
}
.planned-session{
    background-color: $sage-fade;
    border-color: $sage;
}
.my-zen-blueprint{
    padding-top: 10px;
    height: 100%;
    max-height: 100vh;
    position: fixed;
    width: 100vw;
    
}
.my-zen-planner-blueprint-holder{
    width: 100vw;
}

.my-zen-planner-items{
    max-width: 100vw;
    padding: 25px;
    padding-top: 0px;
    padding-bottom: 50px;
    max-height: 80vh;
    margin-top: 10px;
    overflow-y: scroll;
}

.my-zen-planner-blueprint{
    max-width: 100vw;
    padding: 25px;
    padding-top: 0px;
    padding-bottom: 50px;
    max-height: 60vh;
    margin-top: 10px;
    overflow-y: scroll;
}
.my-zen-blueprint-goals{
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 35px;
    
}
.blueprint-intro-text{
    padding-left: 20px;
}
.blueprint-intro-text-inspiration{
    padding-left: 20px;
    font-size: 80%;
    color: $toms_blue;
    padding-bottom: 20px;
}

.MyZenPracticeSession-elip{
    float: right;
}
.MyZenPracticeSession-action{
    width: 33%;
    min-width: 33%;
    text-align: center;
    font-size: 200%;
}

.MyZenPracticeSessionTitle{
    width: 80%;
    float: right;
    padding-top: 4px;
}
.MyZenPracticeSessionDuration{
    width: 20%;
    float: left;
}

.zen-blueprint-goals-header{
    color: $bright-ornge;
    font-family: 'Okashi';

}

.target-succeed{
    color: $sage;
}
.target-miss{
    color: $budd_orange;
}

.okashi{
    font-family: Okashi;
}

.zenK{
    font-family: ZenKurenaido;
}
@font-face {
    font-family: 'ZenKurenaido';
    src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

  }

  @font-face {
    font-family: 'Okashi';
    src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

  }

  @media only screen and (max-width:376px) {
  .EnterValidationCodeCharacter{
      min-width: 30px;
  }

.EnterValidationCodeHolder{
    width: 300px;
}
.recommended-link-session-holder{
    height:400px;
}

}


@media only screen and (max-width:322px) {
    
.recommended-link-session-holder{
    height:350px;
}
.bp-goal-choice{
    font-size: 80%;
    max-height: 10vh;
}
.bp-goal-choice h3{
    font-size: 100%;
}

}



@media only screen and (min-width:1024px) {
.MyZenGoals{
    padding-top: 175px;
}
}