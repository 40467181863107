
$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen:#7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP:#BEB7DF;
$medPurple:#8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite:#FCEBC9;
$off-black:#282c33;
$totem:#9e2e0b;
$navajoLow:#f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white:#f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);


.path-completion-meter{
  transform:scale(0.45);
  position:relative;
  float:right;
  margin-right:-50px;
  margin-top:-25px;
  margin-left:0;

}

.path-completion-meter-large{
  //transform:scale(1);
  position:relative;
  margin:auto;

}

.dashboard {
  display: flex;
}

.path-completion-meter-svg {
  width: 114px;
  height: 114px;
  margin: 1em;
}

.bg {
  fill: none;
  stroke-width: 10px;
  stroke: #1A2C34;
}

[class^="meter-"] {
  fill: none;
  stroke-width: 10px;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  
}

.meter-10 {
  stroke-dasharray: 360;
  stroke-dashoffset: 324;
  stroke: $sage;
  animation: progress-10 1s ease-out;
}
.meter-20 {
  stroke-dasharray: 360;
  stroke-dashoffset: 288;
  stroke: $sage;
  animation: progress-20 1s ease-out;
}
.meter-30 {
  stroke-dasharray: 360;
  stroke-dashoffset: 252;
  stroke: $sage;
  animation: progress-30 1s ease-out;
}
.meter-40 {
  stroke-dasharray: 360;
  stroke-dashoffset: 216;
  stroke: $sage;
  animation: progress-40 1s ease-out;
}
.meter-50 {
  stroke-dasharray: 360;
  stroke-dashoffset: 180;
  stroke: $sage;
  animation: progress-50 1s ease-out;
}
.meter-60 {
  stroke-dasharray: 360;
  stroke-dashoffset: 144;
  stroke: $sage;
  animation: progress-60 1s ease-out;
}
.meter-70 {
  stroke-dasharray: 360;
  stroke-dashoffset: 108;
  stroke: $sage;
  animation: progress-70 1s ease-out;
}
.meter-80 {
  stroke-dasharray: 360;
  stroke-dashoffset: 72;
  stroke: $sage;
  animation: progress-80 1s ease-out;
}
.meter-90 {
  stroke-dasharray: 360;
  stroke-dashoffset: 36;
  stroke: $sage;
  animation: progress-90 1.5s ease-out;
}
@keyframes progress-10 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 324;
    }
}
@keyframes progress-20 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 288;
    }
}
@keyframes progress-30 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 252;
    }
}
@keyframes progress-40 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 216;
    }
}
@keyframes progress-50 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 180;
    }
}
@keyframes progress-60 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 144;
    }
}
@keyframes progress-70 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 108;
    }
}
@keyframes progress-80 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 72;
    }
}
@keyframes progress-90 {
    from {
        stroke-dashoffset: 360;
    }
    to {
        stroke-dashoffset: 36;
    }
}

.path-progress {
  margin:auto;
  background: rgba(255,255,255,0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 100px;
}
.path-progress-alt{
  background: $ming-green;
}

.path-progress-value {
  box-shadow: 0 10px 40px -10px $off-white;
  border-radius: 100px;
  background: $sage;
  height: 7px;
  width: 0;
}

.progress10{
  animation: load10 2s normal forwards;
}

.progress20{
  animation: load20 2s normal forwards;
}
.progress30{
  animation: load30 2.5s normal forwards;
}
.progress40{
  animation: load40 2.5s normal forwards;
}
.progress50{
  animation: load50 3s normal forwards;
}
.progress60{
  animation: load60 3s normal forwards;
}
.progress70{
  animation: load70 3s normal forwards;
}
.progress80{
  animation: load80 3.5s normal forwards;
}
.progress90{
  animation: load90 3.5s normal forwards;
}
.progress100{
  animation: load100 4s normal forwards;
}
@keyframes load10 {
  0% { width: 0; }
  100% { width: 10%; }
}

@keyframes load20 {
  0% { width: 0; }
  100% { width: 20%; }
}
@keyframes load30 {
  0% { width: 0; }
  100% { width: 30%; }
}
@keyframes load40 {
  0% { width: 0; }
  100% { width: 40%; }
}
@keyframes load50 {
  0% { width: 0; }
  100% { width: 50%; }
}
@keyframes load60 {
  0% { width: 0; }
  100% { width: 60%; }
}
@keyframes load70 {
  0% { width: 0; }
  100% { width: 70%; }
}
@keyframes load80 {
  0% { width: 0; }
  100% { width: 80%; }
}
@keyframes load90 {
  0% { width: 0; }
  100% { width: 90%; }
}
@keyframes load100 {
  0% { width: 0; }
  100% { width: 100%; }
}