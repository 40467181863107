$bright-ornge: #FFBC42;
$budd_orange: #D1603D;

.scroll-hint-holder {
  height: 290px;
  width: 290px;
  border-radius: 145px;;
  margin: auto;
  background-color: #333;
  box-shadow: $budd_orange 0px 0px 15px inset, $budd_orange 0px 0px 15px;
}

.scroll-hint-p-holder {
  height: 300px;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100vw;
  min-width: 100vw;
  border-radius: 100px;
  z-index: 9;
  animation: fadeIn 1s;
  padding-top: 50px;
  top: 60px
}




.scroll-container {
  width: 30px;
  height: 60px;
  border: 3px solid #fff;
  border-radius: 15px;
  position: relative;
  margin: auto;
  text-align: center;
  top:55px;
}

.scroller {
  width: 16px;
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 34px;
  animation: scroller 1500ms ease-out infinite;
}


@media only screen and (min-width:1024px) {
  .scroll-hint-p-holder{
    
  top: 80px
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scroller {
  0% {
    bottom: 34px;
  }

  5% {
    top: 4px;
  }

  32% {
    bottom: 4px;
  }

  66% {
    top: 34px;
    bottom: 4px;
  }

  100% {
    top: 4px;
    bottom: 34px;
  }
}