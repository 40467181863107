$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen: #7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP: #BEB7DF;
$medPurple: #8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite: #FCEBC9;
$off-black: #282c33;
$totem: #9e2e0b;
$navajoLow: #f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white: #f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);



// .confirmButton{
//   width: 150px;
//   background-color: $sage-fade;
//   border-width: 0;
//   border-left-width: 10px;
//   border-style: solid;
//   border-radius: 5px;
//   border-color: $sage;
//   width: fit-content;
//   padding: 6px;
//   padding-left: 10px;
//   padding-right: 10px;
//   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
//   color: $primary_black;
//   margin:auto ;
//   font-size: 15px;
//   line-height: 10px;
//   margin:10px;
// }

.zenc-pop-up{
  width:80vw;
  min-height:300px;
  background-color: $navajo_white;
  display:block;
  position:relative;
  top:50px;
  margin:auto;
  border-style: solid;
  border-color: $ming-green;
  border-radius: 15px;
  border-width: 15px 2px 15px 2px;
  min-width:300px;
  z-index:51 !important;
  padding:15px;
}

.nordiiImage{
  min-width:130px;
  height:150px;
  border-radius:15px;
  background-image: url("../../static/photos/home/NRD.png");
  background-size: contain;
  display: block;
  margin: auto;
  margin-left:25px;
}

.nordiiDesktopAbout{
  min-width:250px;
  height:275px;
  margin-top: -50px;
}

.zenBotImage{
  width:150px;
  min-width:150px;
  height:150px;
  border-radius:15px;
  background-image: url("../../static/photos/exploreIcons/zenBot.png");
  background-size: contain;
  display: block;
  margin: auto;
  box-shadow: inset $primary_black 0px 5px 9px;
}

.AboutSectionTwo{
  color:$off-black;
  margin-bottom: 0;
  padding-bottom: 0;
}

.informationExample{
  width:140px;
  min-width:140px;
  height:300px;
  border-radius:15px;
  background-image: url("../../static/photos/home/infoExample.png");
  background-size: contain;
  display: block;
  margin: auto;
  margin-right:25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;

}

.desktopInfoExample{
  min-width:150px;
  margin:auto;
  margin-top: -25px;;

}
.zen-splash-pop-up{
  
  width:225px;
  max-width:225px;
  height:375px;
  margin:auto;
  display:block;
  position:relative;
  background-image: url("../../static/photos/blueSplash.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index:51 !important;
  padding-left:75px;
  padding-right: 75px;
  font-size: 75%;
  padding-top:115px;
  text-align: center;
  color:$off-white;
}

.warning-pop-up h4{
  font-family: Okashi;
  color:$budd-fade;
  font-weight: 800 !important;
  font-size: 110%;
}


.zenc-pop-up-warning {
  border-color: $totem;
}

.zencTextButton {
  width: fit-content;
  // border-width: 3px;
  // border-left-width: 8px;
  // border-right-width: 8px;
  // border-style: solid;
  border-radius: 5px;
  border-color: $ming-green;
  background-color: $ming-fade;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px inset, rgba(0, 0, 0, 0.35) 0px 0px 5px;
  color: $primary_black;
  margin: auto;
  // font-size: 15px;
  // line-height: 10px;
  padding: 7.5px;
  padding-top: 4px;
  padding-bottom: 5px;
  text-align: center;
  min-width: 55px;
  font-weight: 900;
  color: $primary_black;
}

.zen-large-button {
  background-color: $navajoWhite;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  width: fit-content;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: $primary_black;
  margin: auto;
  margin-top:25px;
}

.zencSideBorderButton {}

.zencRedButton {
  border-color: $budd_orange;
  background-color: $budd-fade;
}

.zencGreenButton {
  border-color: $sage;
  background-color: $sage-fade;
}

.zenc-button-row {
  display: flex;
  flex-direction: row;
  width: 250px;
  margin: auto;
  margin-top: 10px;
}

.ming-page {
  position: fixed;
  top:0;
  z-index: 55;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #28666E;
  box-shadow: inset $primary_black 0px 0px 45px;//$off-black 0px 0px 45px;
  text-align: center;
  color: $bright-ornge;
  display:block;
  overflow-y: scroll;
  padding-bottom:200px;
}

.ming-page-text {
  padding-top:25px;
  margin: auto;
  width:70%;
  color:$navajoWhite;
}


.okashi {
  font-family: Okashi;
}

.zenc-next-button {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  width: 35px;
  height: 35px;
  margin: auto;
  background-size: contain;
  border-radius: 50%;
  margin-top: 15px;
  box-shadow: inset $off-black 0px 0px 25px;
}


@font-face {
  font-family: 'Okashi';
  src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

}