$bright-ornge: #FFBC42;
$budd_orange: #D1603D;
$primary_black: #272727;
$toms_blue: #033f63;
$blue_fade: rgba(3, 63, 99, 0.7);
$blue_fade_hex: #225974;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajoWhite: #FCEBC9;
$sage: #B5B682;
$sage-fade: rgba(181, 182, 130, 0.7);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$ming-fade-hex: #69949a;
$off-white: #f2e7d2;
$gold-fade: rgba(255, 188, 66, 0.7);
$budd-fade: rgba(209, 96, 61, 0.7);
$totem: #9e2e0b; 

.zen-info-page-reg{
  background-color: $navajoWhite;
}

.practitioner-preview-bio{
  max-height: 100px;
  width: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center !important;
}

.confirmChooseSessionBanner{
  position:fixed;
  bottom:0;
  left:0;
  width:100vw;
  height:100px;
  background-color: $off-white;
  box-shadow: $primary_black 0px 0px 10px;
  border-width: 0;
  border-top-width: 2px;
  border-style: solid;
  border-color: $toms_blue;
  display:flex;
  flex-direction: row;
  padding-top: 15px;
}




.chooseSessionBannerButton{
  width:150px;
  margin:auto;
  margin-top: 10px;
  background-color: $toms_blue;
  color:$bright-ornge;
  text-align: center;
  border-radius: 5px;
  
}

.zen-info-page-alt{
  background-color: $off-white;

}


.article-text-page{
  padding-bottom: 25px;
  padding-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px, inset rgba(0, 0, 0, 0.35) 0px 0px 10px;
  h4{
    text-align: justify;
  }
  
}

.path-add-content-banner{
  width:310px;
  margin:auto;
  background-color: $off-white;
  border-width: 2px;
  border-color: $primary_black;
  border-style: solid;
  height:70px;
  border-radius: 35px;
  display:flex;
  flex-direction: row;
}

.add-content-recc-banner{
  //background-color: $sage-fade;
  //border-color: $sage;
}

.addContentPlus{
  width:50px;
  height:50px;
  background-image: url("../../static/photos/languageIcons/addIcon.png");
  background-size: contain;
  margin:auto;
  margin-top: 12px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 7px, inset rgba(0, 0, 0, 0.35) 0px 0px 7px;
}

.zen-search-tag-filters-container {
  display: block;
  position: relative;
  // background-color: $off-white; 
  // border-radius:15px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px, inset rgba(0, 0, 0, 0.35) 0px 0px 10px;
  height: fit-content;
  padding-bottom: 14px;
  padding-top: 5px;
  margin: auto;
  margin-top: 25px;
  width: 350px;
  max-width: 90vw;
  margin-left: 0;
  left: 0;
}

.zen-search-tag-filters {

  height: fit-content;
  min-height: 50px;
  max-height: 50px;
  margin: auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.zen-search-filter-tag-holder {
  margin: auto;
  min-width: 75px;
  margin-top: 5px;
  height: 20px;
  text-align: center;
  color: $navajo_white;
}

.zen-search-filter-tag {
  margin: auto;
  width: fit-content;
  min-width: 65px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 70%;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px, inset rgba(0, 0, 0, 0.35) 0px 0px 5px;
  opacity: 0.9;
  background-color: $ming-fade;
}

.active-filter-tag {
  opacity: 1;
  background-color: $ming-green;
}

.budd {
  background-color: $budd_orange;

}

.cardBrowser {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 275px;
  max-width: 100vw;
  overflow-x: auto;
  margin-bottom: 25px;

}

.zen-milestone-notice {
  width: fit-content;
  margin: auto;
}

.dRedText {
  color: $totem;
}

.cardsGroupHolder {
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

.cardsGroup {
  width: fit-content;
}

/* width */
::-webkit-scrollbar {
  //.cardBrowser{
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  //.cardBrowser{
  background: $off-white;
  margin: 0;
  border: none;
  height: 5px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $toms_blue;
  height: 100%;
  width: 25px;
  border-radius: 5px;
}


.zen-icons-container {
  background-color: $navajoWhite;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  padding-top: 170px;
  padding-bottom: 100px;
  overflow-y: scroll;
}

.zenc-thecondary-option {
  width: 100px;
  margin: auto;
  background-color: $navajo_fade;
  padding: 3px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: $ming-green;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.thecondary-option-selected {
  margin: auto;
  background-color: $sage_fade;
  padding: 3px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: $sage;
}

.zenCardPreview {
  width: 200px;
  min-width: 190px;
  height: 250px;
  margin: auto;
  //margin: 0.5em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px, inset rgba(0, 0, 0, 0.35) 0px 0px 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-top: 15px;
}

.zenMilestoneCard {
  background-color: $ming-fade;
}

.fullMing{
  background-color: $ming-green;
}

.slick-current {
  .zenMilestoneCard {
    background-color: $ming-green;
  }
}

.zenPowerCard {
  background-color: $budd-fade;
}

.slick-current {
  .zenPowerCard {
    background-color: $budd_orange;
  }
}

.zenSubscriptionCard {
  background-color: $blue-fade;
}

.activeSubscriptionCard {
  border-style: solid;
  border-color: $budd_orange;
  border-width: 2px;
}

.slick-current {
  .zenSubscriptionCard {
    background-color: $toms_blue;
  }
}

.cardFocusedSummary {
  width: 250px;
  min-width: 250px;
  height: 400px;
  padding-top: 15px;
  margin: auto;
  border-radius: 15px;
  ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px, inset rgba(0, 0, 0, 0.35) 0px 0px 10px;
}

.zenCardPreviewLarge {
  width: 275px;
  height: 350px;
  border-radius: 25px;
  ;
}

.newZenCard {
  box-shadow: $bright-ornge 0px 0px 25px, inset $bright-ornge 0px 0px 15px;
}

.zen-icon-unlock-holder {
  position: fixed;
  padding-top: 75px;
  top: 150px;
  background-color: $navajoWhite;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.basic-tier-tag {
  background-color: $budd_orange;
  height: fit-content;
  border-radius: 5px;
  padding: 5px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 900;
}

.zenCardPreviewTitle {
  font-family: Okashi;
  font-size: 18px;
  color: $navajoWhite;
  margin-bottom: 0;

}


.zenCardSummaryTitle {
  font-family: Okashi;
  font-size: 18px;
  color: $bright-ornge;
  margin-bottom: 0;
  margin-top: 5px;
}

.zen-icon-preview-description {
  height: 65px;
  overflow: hidden;
  color: $off-white;
  font-size: 15px;
  text-align: center;
  margin: auto;
  margin-top: 0;
  padding-top: 0;
  width: 90%;
}

.zen-card-summary-description {
  color: $off-white;
  font-size: 15px;
  text-align: center;
  margin: auto;
  margin-top: 0;
  padding-top: 0;
  width: 90%;
}

.zen-icon-preview-powers {
  height: 65px;
  overflow: hidden;
  color: $bright-ornge;
  text-align: center;
  margin: auto;
  margin-top: 0;
  padding-top: 0;
  width: 90%;
  font-weight: 900;
  font-size: 12px;
}

.zen-icon-prv-powers-header {
  font-size: 15px;
  line-height: 15px;
  color: $off-white;
}

.zenCardPreviewPhoto {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  background-size: cover;
  display: block;
  margin: auto;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}

.zenCardSummaryPhoto {
  width: 175px;
  height: 175px;
  border-radius: 15px;
  background-size: cover;
  display: block;
  margin: auto;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;

}

.zenCardPreviewPhotoLarge {
  width: 175px;
  height: 175px;
  border-radius: 25px;

}

.zenCardPreviewLockHolder {
  height: 100px;
  margin-bottom: 10px;
}

.zenCardPreviewLock {
  position: relative;
  opacity: 0.8;
  height: 120px;
  width: 120px;
  margin: auto;
  margin-top: -110px;
  background-image: url("../../static/photos/languageIcons/lockINspiration.png");
  background-size: cover;
  text-align: center;
  color: $off-white;
  font-size: 22px;
  font-weight: 900;
  z-index: 51;
}

.zen-icons-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  margin: auto;
  min-height: 90vh;
  height: fit-content;
  padding-bottom: 250px;
  padding-top: 0;
  margin-top: 15px;
}

.zen-pop-up-holder-alt {
  position: fixed;
  width: 75vw;
  height: fit-content;
  min-height: 250px;
  background-color: $navajo_white;
}


.zenverse-back-icon {
  top: 115px;
}

.zen-c-results-header {
  text-align: center;
  margin-bottom: 25px;
  font-size: 150%;
  margin-top: -15px;
  color: $ming-green !important;
}

.inactive-item {
  opacity: 0.5;
}

.MyChosenZenGoals {
  margin-bottom: 0;
}

.zencyc-contents-text-item-header {
  font-size: 90%;
  text-align: center;
  padding-top: 0px;
}

.box {
  position: relative;
}

.infomatic-linked-content {
  padding-top: 25px;
}

.zencyclopaedia-infomatic-page {
  padding: 25px;
  padding-top: 0;
}

.infomatic-description {
  text-align: justify;
}

.infomatic-header {
  font-family: 'Okashi';
  text-align: center;
  margin-top: 11px;
  margin-bottom: 11px;
}

.zencyclopaedia-main-menu-item-holder {
  width: 80vw;
  max-width: 500px;
  border-radius: 7.5px;
  background-color: $off-white;
  overflow: hidden;
  max-height: 60px;
  height: 60px;
  border-style: solid;
  border-width: 0;
  border-left-width: 15px;
  border-color: $navajo_white;
  margin: auto;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 8px;
}

.zen-create-option{
  h2{
    text-align: center;
    font-size: 100%;
    margin:auto;
    margin-top: 0;
    margin-bottom: 4px;
    color:$budd_orange;
    font-weight: 900;
  }
  h3{
    text-align: center;
    font-size: 90%;
    margin:auto;
    margin-top: 0;
    margin-bottom: 4px;
  }
}

.zencyc-main-menu-item-image {
  height: 60px;
  height: 60px;
}

.zencyclopaedia-content-list-item {
  width: 300px;
  margin: auto;
  padding: 15px;
  min-height: 100px;
  background-color: $off-white;
  border-color: $sage;
  border-radius: 10px;
  border-width: 0;
  border-left-width: 20px;
  display: flex;
  flex-direction: row;
  border-style: solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 8px;
  margin-bottom: 35px;
}

.selected-list-prievew{
  border-color: $ming-green;
  border-width: 3px;
  border-style: solid;
  border-left-width: 20px;
}

.uncompletedPractice{
  border-left-color: $budd_orange;
}
.practitioner-preview-item{
  
  width: 300px;
  margin: auto;
  padding: 15px;
  min-height: 100px;
  background-color: $sage-fade;
  border-color: $sage;
  border-radius: 10px;
  border-width: 0;
  border-left-width: 20px;
  border-style: solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 8px;
  margin-bottom: 35px;

}

.zen-portal-ad-holder{
  margin-top:105px;

}
.practice-create-confirm{
  width:335px;
  margin:auto;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
  height: fit-content !important;
  min-height:fit-content !important;
}

.practice-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}


.path-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $off-white;
  border-color: $ming-green;
  height: 90px !important;

}

.zencyclopaedia-contents-item-holder-large {
  width: 90vw;
  min-width: 90vw;
}

.see-more-med {
  width: 39vw;
  margin: auto;
  margin-left: 2vw;
  margin-right: 2vw;
  display: block;
  background-color: $navajo_fade;
  border-style: solid;
  border-width: 0;
  border-left-width: 15px;
  border-color: $navajo_white;
  border-radius: 15px;
  margin-top: 15px;
  overflow: hidden;
  height: 175px;
}

.zencyclopaedia-contents-item-medium {

  width: 115px;
  margin: auto;
  display: block;
  background-color: $sage-fade;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 15px;
  border-color: $sage;
  border-radius: 7.5px;
  margin-top: 15px;
  overflow: hidden;
  height: 115px;
  max-width: 200px;

}

.zencyclopaedia-contents-item-large {
  width: 39vw;
  margin: auto;
  margin-left: 2vw;
  margin-right: 2vw;
  display: block;
  background-color: $sage-fade;
  border-style: solid;
  border-width: 0;
  border-left-width: 15px;
  border-color: $sage;
  border-radius: 15px;
  margin-top: 15px;
  overflow: hidden;

}

.input {
  padding: 10px;
  width: 60px;
  height: 60px;
  background: none;
  // border: 4px solid $ming-green;
  border: none;
  border-radius: 60px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  color: $navajoWhite;
  transition: .5s;
  font-size: 20px;
  margin: auto;
  z-index: 9;
  top: 0;
  margin-top: 0;
}

.zen-search-active-components {
  box-shadow: $ming-fade 0px 0px 5px;
  border: 2px solid $ming-green;
  border-radius: 60px;
  width:fit-content;
}

.box {
  z-index: 9;
}

.zen-search-bar-filters {
  display: none;
  position: relative;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}



.box:hover .zen-search-active-components {
  margin-top: 0px;
  //background: #3b3640;
  background-color: $off-white;
  border-radius: 10px;
  height: 125px;
  opacity: 1;
}

.box:hover input {
  color: $primary_black;
  width: 350px;
  height: 70px;
  border-radius: 10px;
}

.zen-search-input::placeholder {
  opacity: 0;
}


.zen-search-input {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.box:hover .zen-search-input::placeholder {
  opacity: 1;
}



.box:hover .zen-search-input {
  display: block;
  position: relative;
  line-height: 60px;
  max-height: 75px;
  margin-top: -135px;
  font-weight: 900;
  opacity: 1;
}

.box:hover .zen-search-bar-filters {
  display: block;
  margin-top: 60px;
  left: 0;
  opacity: 1;
}

.box .zencyc-search-go {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  transition: .2s;
}


.box:hover .zencyc-search-go {
  display: none;
  opacity: 0;
  z-index: -1;
}

.portal-finish-arrow {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  width: 50px;
  border-radius: 50%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 25px;
  margin-left: 27.5px;
  transform: rotate(180deg);
}

.zenscape-back-icon {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-top: 37.5px;
  margin-left: 40px;
  background-size: contain;
  background-repeat: no-repeat;

  rotate: 180deg;
}

// .portal-finish-icon .zenscape-back-icon{
//   margin-top:0px;
//   margin-left:27.5px;
// }


.explore-back-icon {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  position: fixed;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  rotate: 180deg;
  z-index: 888;
  top: 105px;
  left: 10px;
}

.explore-back-icon-cover {
  width: 100vw;
  height: 50px;
  z-index: 999;
  top: 105px;
  left: 10px;
  display: none;

}

.box:hover .explore-back-icon-cover {
  display: block;
}

.zenc-mag {
  width: 30px;
  height: 30px;
  background-image: url("../../static/photos/languageIcons/searchIcon.png");
  background-size: cover;
  margin-left: 30px;
  -webkit-appearance: none;
}

.zencyc-search-go {
  width: 30px;
  height: 30px;
  background-image: url("../../static/photos/languageIcons/searchIcon.png");
  background-size: cover;
  margin-left: 30px;
  -webkit-appearance: none;
}

.sage {
  background-color: $sage;

}

.zencyc-search-bar-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  align-content: center;
  width: 100%;
  top: 100px;
}

::-webkit-scrollbar {
  width: 1px;
}

.zencyclopaedia-browser {
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  background-color: $navajoWhite;
  border-color: $budd_orange;
  overflow: hidden;
  height: 100vh;
  top: 40px;
  padding-top: 55px;
  padding-bottom: 150px;
  position: fixed;
  margin-top: 0;
}

.information-browser {
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  background-color: $navajoWhite;
  border-color: $budd_orange;
  overflow: hidden;
  height: 100vh;
  top: 30px;
  padding-top: 25px;
  padding-bottom: 150px;
  position: fixed;
  margin-top: 0;

}

.zencyclopaedia-infomatic {
  background-color: $navajo_white;
  height: 100%;
  min-height: 100%;
  position: fixed;
  padding-top: 150px;
  width: 100%;
}

.zencyc-infomatic-image {

  display: block;
  margin: auto;
  width: 85%;
  max-width:200px;
  object-fit: contain;
  background-size: contain;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}

.zencyc-search {

  margin: auto;
  text-align: center;
  font-size: 100%;
}

.zencyc-footer {
  position: fixed;
  bottom: 0;
  background-color: $budd_orange;
  min-width: 100%;
  height: 70px;
}


.zencyc-search-bar {
  float: left;
  display: block;
  height: 40px;
  width: 70%;
  border-radius: 15px;
  border-style: solid;
  border-color: $primary_black;
  border-width: 2px;
  margin: 15px;
  background-color: $navajoWhite;
  color: $primary_black;
  padding-left: 10px;
  font-size: 110%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

.zencyc-search-bar::placeholder {
  color: $primary_black;

}

.zencyc-search-filters {
  width: 30px;
  height: 35px;
  margin-left: 20px;
  background-image: url("../../static/photos/zencyc_preview_rev_1.png");
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.zencyc-fav {
  margin-right: 50px;
}

.zencyc-controls {
  margin: auto;
  width: 100%;
  margin-top: 10px;
  font-size: 200%;
}


.zencyclopaedia-contents-group-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.zencyclopaedia-contents-group-items-med {
  max-width: 300px;
}

.zencyclopaedia-contents-group {
  width: 100%;
  margin-bottom: 15px;
}

.preview-items-container {
  padding-bottom: 350px;
}

.zencyclopaedia-contents-item-holder {
  margin: auto;
  display: inline-block;
}


.zencyclopaedia-contents-item {
  display: inline-block;
  position: relative;
  width: 125px;
  border-radius: 15px;
  box-shadow: $primary_black 0px 0px 5px;
  //padding-bottom: 15px;
  max-height: 140px;
  height: 140px;
  margin-bottom: 20px;
  margin: 15px;
  margin-bottom: 0;
  overflow: hidden;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 15px;
}
.content-rec-slider-item{
  max-height: 325px;
  display: block !important;
  margin:auto;

  h1{
    color:$budd_orange;
    font-size: 100%;
    margin:auto;
    text-align: center;
    margin-top:5px;
    margin-bottom:5px;
  }
  h4{
    max-height:170px;
    font-size: 90%;
    overflow-y:scroll;
  }
}

.med-slider-item{
  background-color: $sage-fade;
}

.path-slider-item{
  background-color: $ming-fade;

}

.zenscape-slider-item {
  width: 245px;
  max-width: 245px;
  min-width: 245px;
  border-radius: 15px;
  height: 350px;
  box-shadow: $primary_black 0px 0px 5px;
  //padding-bottom: 15px;
  margin:auto;
  margin-top: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  border-width: 2px;
  box-shadow: $primary_black 0px 0px 5px, $sage 0px 0px 25px inset;
  background-color: $sage-fade;
  color: $navajoWhite;
  border-style: solid;
  border-color: $sage;
}

.nuggetSliderPreview{
  width: 245px;
  max-width: 245px;
  min-width: 245px;
  border-radius: 15px;
  height: 325px;
  box-shadow: $primary_black 0px 0px 5px;
  //padding-bottom: 15px;
  display: block;
  margin:auto;
  margin-top: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  border-width: 2px;
  box-shadow: $primary_black 0px 0px 5px, $toms_blue 0px 0px 25px inset;
  background-color: $blue-fade;
  color: $navajoWhite;
  border-style: solid;
  border-color: $toms_blue;

}

.showAllSliderPreview{
  width:300px;
  height:450px;
  margin-top:30px;
  min-width: 300px;
  position:fixed;
}

.sliderFocusedItemHolder{
  position:fixed;
  background-color: $navajoWhite;
  width:100vw;
  height:100vh;
  top:0;
  padding-top:150px;
  margin-top:0;
}

.practitionerSliderPreview{
  width: 245px;
  max-width: 245px;
  min-width: 245px;
  border-radius: 15px;
  height: 300px;
  box-shadow: $primary_black 0px 0px 5px;
  //padding-bottom: 15px;
  margin:auto;
  margin-bottom: 25px;
  overflow: hidden;
  border-width: 2px;
  box-shadow: $primary_black 0px 0px 5px, $ming-green 0px 0px 25px inset;
  background-color: $ming-fade;
  color: $navajoWhite;
  border-style: solid;
  border-color: $ming-green;
}

.communityPractitionerItem{
  background-color: $budd-fade;
  border-color: $budd_orange;
  box-shadow: $primary_black 0px 0px 5px, $budd_orange 0px 0px 25px inset;
}

.practitionerSliderPreview{
  h4{
    color:$navajo_white;
    text-align: center;
    margin:auto;
    width:fit-content;
  }
  p{
    color:$primary_black;
    text-align: justify;
    margin:auto;
    width: fit-content;
    padding-left:5%;
  }
}

.communityLabel{
  color:$off-white !important;
  text-align: center;
  font-size: 85%;
  margin-top: 0;
}

.practitioner-content-slider-item{
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  border-radius: 15px;
  height: 155px;
  box-shadow: $primary_black 0px 0px 5px;
  //padding-bottom: 15px;
  margin:auto;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  border-width: 2px;
  box-shadow: $primary_black 0px 0px 5px, $toms_blue 0px 0px 25px inset;
  background-color: $blue-fade;
  border-style: solid;
  border-color: $toms_blue;
}

.practitioner-content-slider-item{
  h1{
    margin-top:5px;
    color:$navajo_white;
    font-size: 130%;
    text-align: center;
    margin-bottom: 0;
  }
  h4{
    margin-top:5px;
    color: $off-white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.explore-slider-item{
  background-color: $blue-fade;
  box-shadow: $primary_black 0px 0px 5px, $toms_blue 0px 0px 25px inset;
  border-color: $toms_blue;
  height: 300px;
  max-height: 300px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.zenscape-slider-item-alt{
  background-color: $ming-fade;
  box-shadow: $primary_black 0px 0px 5px, $ming-green 0px 0px 25px inset;
  border-color: $ming-green;
}

.meditation-slider-item{
  background-color: $budd-fade;
  box-shadow: $primary_black 0px 0px 5px, $budd_orange 0px 0px 25px inset;
  border-color: $budd_orange;
  height: 335px !important;
  max-height: 335px !important;
  min-height: 335px !important;
  margin-bottom:25px;

}

.zenscape-slider-item-alt.meditation-slider-item{
  background-color: $navajo_fade;
  box-shadow: $primary_black 0px 0px 5px, $navajo_white 0px 0px 25px inset;
  border-color: $navajo_white;
  height: 335px;
}

.article-slider-item{
  background-color: $navajo_fade;
  box-shadow: $primary_black 0px 0px 5px, $navajo_white 0px 0px 25px inset;
  border-color: $navajo_white;
  height: 335px;
}
.article-slider-item{
  .zencyc-contents-item-header-bottom{
    color:$toms_blue;
  }
}

.meditation-slider-item{
  .zencyc-contents-item-header-bottom{
    color:$toms_blue;
  }
}

.zenscape-contents-item {
  background-color: $blue_fade_hex !important;
  color: $navajoWhite;
  border-style: solid;
  border-color: $toms_blue !important;
}

.zenc-article-contents-item {
  background-color: $ming-green !important;
  color: $navajo_white;
  border-color: $ming-fade-hex !important;
}

.zen-shop-item {
  background-color: $ming-fade;
  position: relative;
  width: 135px;
  max-height: 160px;
  height: 160px;
  border-radius: 10px;
  box-shadow: $primary_black 0px 0px 5px, inset $primary_black 0px 0px 5px;
  padding-bottom: 15px;
  border-style: solid;
  border-color: $ming-green;
  border-width: 2px;
  margin-bottom: 20px;
  margin: auto;
  overflow: hidden;
}

.zen-shop-item-holder{
  padding:10px;
  margin:auto;
}

.out-of-stock-item{
  opacity:0.6;
}

.zencyclopaedia-contents-item-alt {
  display: inline-block;
  border-radius: 6px;
  box-shadow: $sage 0px 0px 5px;
  border-color: $sage;
  border-bottom-width: 6px;
  margin: auto;
  margin-bottom: 20px;
  margin: 15px;
}

.large-contents-preview {

  height: 130px;
  max-height: 130px;
  padding-bottom: 20px;
}

.zencyclopaedia-contents-text-item {
  width: 80%;
  min-width: 90px;
  max-width: 100px;
  padding-bottom: 10px;
  margin: auto;
  border-radius: 5px;
  max-height: 40px;
  height: fit-content;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 8px;
  margin: 10px;
  margin-bottom: 10px;
}

.zencyc-contents-item-image {
  width: 90%;
  max-height: 100px;
  display: block;
  margin: auto;
  bottom: 0;
}
.explore-slider-description{
  padding-left: 25px;
  padding-right: 25px;
  color:$navajoWhite;
  text-align: center;
  max-height:125px !important;
}

.zenscape-slider-item {
  .explore-slider-description{
    color:$primary_black;
    padding-left: 10px;
    padding-right: 10px;
    padding-top:0;
    margin-top:0;
  }
  .explore-slider-title{
    color:$ming-green;
    margin-bottom:4px;
    margin-top:5px;
    padding-bottom: 0;
    height:fit-content;
  }
}

.explore-slider-description-intro{
  color:$navajoWhite !important;
}

.explore-slider-title{
  color:$navajo_white;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  margin-top:25px;
  font-weight:700;
}

.explore-slider-item{
  .explore-slider-title{
    color:$navajo_white;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin-top:25px;
    font-weight:700;
    margin-bottom: 15px;
  }
}

.explore-slider-intro-title{
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  margin-top:25px;
  font-weight:700;
  margin-bottom: 15px;
  color:$primary_black !important;
  font-size: 115% !important;
}

.explore-slider-image {
  width: 125px;
  max-height: 90px;
  object-fit: cover;
  display: block;
  margin: auto;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px, rgba(0, 0, 0, 0.35) 0px 0px 15px;
  border-radius:15px;
  margin-top:20px;
}

.med-slider-preview-image{
  width: 115px;
  max-height: 115px;
  object-fit: cover;
  display: block;
  margin: auto;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px, rgba(0, 0, 0, 0.35) 0px 0px 15px;
  border-radius:15px;
  margin-top:15px;
}

.article-slider-image{
  max-height:145px;
}

.zen-slider-image{
  width: 175px;
  max-height: 175px;
  object-fit: cover;
  display: block;
  margin: auto;
  bottom: 0;
  box-shadow: inset $primary_black 0px 0px 55px !important;
  border-radius:15px;
  margin-top:15px;
  border-width: 3px;
  border-style: solid;
  border-color: $sage;
}

.zen-slider-image{
  img{
    box-shadow: inset $primary_black 0px 0px 55px !important;
  }
}


.t-shirt-pic-portrait {
  background-image: url("../../static/photos/shop/t-shirt-portrait.png");
  width: 200px;
  height: 255px;
  background-size: cover;
  margin: auto;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  margin-top: 0;
}

.shop-item-image {
  width: 100%;
  height: 125px;
  max-height: 125px;
  display: block;
  object-fit: fill;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px inset, rgba(0, 0, 0, 0.35) 0px 0px 25px;
}

.shop-item-oos-image{
  position:absolute;
  width: 100%;
  height: 125px;
  display: block;
  opacity:0.5 !important;
  top:0;
  background-color: $off-white;
}

.header-back-arrow {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  ;
  margin: auto;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  float: left;
  margin-top: 5px;
  margin-left: 7px;
  transform: rotate(180deg);

}

.shop-back-arrow{
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  position:fixed;
  top:125px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  margin: auto;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 7px;
  transform: rotate(180deg);
}

.t-shirt-pic-sq {
  background-image: url("../../static/photos/shop/vista-shirt.png");
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: auto;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}


.t-shirt-checkout-pic {
  background-image: url("../../static/photos/shop/vista-shirt.png");
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: auto;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
}
.mindfulStickers{
  background-image: url("../../static/photos/shop/stickers.png");
}

.blueT {
  background-image: url("../../static/photos/shop/blueTShirt.png");
}

.greyT {
  background-image: url("../../static/photos/shop/greyTShirt.png");
}

.whiteT{
  background-image: url("../../static/photos/shop/whiteT.png");

}

.orangeT {
  background-image: url("../../static/photos/shop/orangeTShirt.png");
}

.eco-friendly-pledge {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  padding-bottom: 7px;
  ;
}

.eco-friendly-pledge-text h3 {
  font-size: 65%;
  margin: 0;
  text-align: center;
}

.buy-now-button {
  margin-top: 15px;
}

.eco-friendly-pledge-text h1 {
  font-size: 95%;
  margin: 0;
  text-align: center;
}

.eco-friendly-pic {

  background-image: url("../../static/photos/shop/eco-pledge.png");
  width: 100px;
  min-height: 75px;
  max-height: 75px;
  margin: auto;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 15px;
}

.zencyc-large-contents-item-image {
  height: fit-content;
  min-height: 100px;
  max-height: 100px;
  max-width: 100px;
  width: 100%;
  display: block;
  margin: auto;
  object-fit: contain;
}


.zencyc-contents-group-header {
  font-size: 190%;
  padding-left: 10px;
  width: 80%;
  margin-left: 10%;
}

.zencyc-contents-item-header {
  text-align: center;
  font-size: 75%;
  height: 30px;
  max-height: 30px;
  max-width: 80%;
  margin: auto;
  margin-top: 10px;
  line-height: 90%;
  font-weight: 300;
}


.zen-shop-item {

  h1{
    margin-top: 7px;
    padding-top: 0;
  }
  }

  
.zencyclopaedia-results {
  padding-bottom: 450px;
  padding-top: 22px;
  height: 90vh;
  overflow-y: scroll;
}

.zencyc-contents-item-header-large {
  text-align: center;
  font-size: 130%;
  font-weight: 900;
  height: 30px;
  max-height: 30px;
  width: 85%;
  max-width: 85%;
  margin: auto;
  margin-top: 15px;
  line-height: 100%;
}

.zencyc-large-contents-item-header {
  text-align: center;
  font-size: 125%;
  font-weight: 900;
  height: 15px;
  max-height: 15px;
  margin: auto;
  margin-top: 10px;
  line-height: 100%;
  width: 70%;
}

.zencyc-contents-item-header-bottom {
  text-align: center;
  font-size: 90%;
  height: 30px;
  max-height: 30px;
  max-width: 80%;
  margin: auto;
  margin-top: 15px;
  line-height: 90%;
}

.zencyc-contents-item-header-trans {
  font-weight: 300;
  font-size: 70%;
}

.okashi {
  font-family: Okashi;
}

.zenK {
  font-family: ZenKurenaido;
}

.ZenCGradient1 {
  background-color: $ming-fade;
  border-color: $ming-green;
}

.ZenCGradient3 {
  // background: $navajo_white;
  // background: radial-gradient(circle, $navajo_white 0%, #ffd079 50%, $bright-ornge 100%);
  background-color: $gold-fade;
  border-color: $bright-ornge;
  border-style: solid;
}

.ZenCGradient2 {
  // background: $navajo_white;
  // background: radial-gradient(circle, $bright-ornge 0%, #E88E40 50%, $budd_orange 100%);
  background-color: $budd-fade;
  border-color: $budd_orange;
  border-style: solid;
}

.ZenCGradient4 {
  border-color: $navajo_white;
  background-color: $navajo_fade;
}

.ZenCGradient5 {
  background: $sage-fade;
  border-color: $sage;
}

.ZenCGradient6 {
  background: $blue_fade;
  border-color: $toms_blue;
}

@media only screen and (min-width:410px) {
.zen-shop-item {
  background-color: $ming-fade;
  position: relative;
  width: 150px;
  max-height: 170px;
  height: 170px;
}
.zen-shop-item-holder{
  padding:15px;
}
}

@media only screen and (min-width:768px) {


  .zen-icons-container {
    padding-top: 175px;
  }

  .tShirt-LargeP {
    width: 175px;
    height: 175px;
  }

  .zencyc-contents-item-image {
    max-height: 300px;
  }

  .zencyclopaedia-contents-item-holder-large {
    width: 30vw;
    min-width: 30vw;
  }

  .zencyclopaedia-contents-item {
    height: 180px;
    max-height: 180px;
  }

  .zencyc-infomatic-image {
    margin-left: 50px;
  }

  .zencyc-controls {
    width: 600px;
  }


  .zencyc-contents-item-header {
    font-size: 200%;
  }

  .zencyclopaedia-results {
    width: 100%;
  }

  .zencyc-search {
    font-size: 150%;
  }

  .zencyc-search-bar {
    height: 40px;
    width: 600px;
  }

  .zencyc-search-filters {
    width: 35px;
    height: 35px;
    margin-left: 50px;
  }
}

@media only screen and (min-width:600px) {
  .zencyclopaedia-contents-item {
    height: 160px;
    max-height: 160px;
  }
}

@media only screen and (min-width:480px) {

  .zencyc-contents-item-header {
    font-size: 130%;
  }

  .zencyclopaedia-results {
    min-width: 480px;
    width: 100%;
  }

}

@media only screen and (min-width:414px) {
  .zencyc-search-bar-container {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:400px) {

  .zencyclopaedia-contents-text-item {
    margin: 8px;
    margin-bottom:0;
    margin-top:0;
    width:75px;
    //height:25px;
    padding-top:2px;
  }

  .zencyc-contents-item-header {
    font-size: 90%;
  }

  .zencyclopaedia-contents-item {
    height: 145px;
    max-height: 145px;
    width: 150px;
  }
}

@media only screen and (min-width:1024px) {

  .zen-portal-ad-holder{
  }

  .practice-create-confirm{
    width:425px;
    height: fit-content !important;
    max-height: 350px !important;
    min-height:fit-content !important;
  }
  
  .zencyclopaedia-results {
    padding-top:0;
  }
  .zencyclopaedia-contents-text-item {
    margin-top:0;
    width:75px;
    //height:20px;
    padding-top:0px;
  }
  .zencyclopaedia-contents-item-holder {
    margin-top:0;
    margin-bottom:0;
  }
  
  .zencyc-contents-group-header {
    font-size: 450%;
    padding-left: 10px;
    border-style: solid;
    border-color: $primary_black;
    border-width: 0 0 2px 0;
  }
  .cardFocusedSummary {
    width: 350px;
    min-width: 350px;
    height: 350px;
    padding-top: 15px;
    margin: auto;
    border-radius: 15px;
    ;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px, inset rgba(0, 0, 0, 0.35) 0px 0px 10px;
  }
  .zencyc-search-bar-container {
    margin-top: 15px;
    margin-bottom:0px;
  }
  .zencyclopaedia-browser {
    top: 75px;
    padding-top: 20px;
  }

.shop-back-arrow {
  top:200px;
  left:30px;
}
  .zencyc-search {
    font-size: 200%;
  }

  .t-shirt-shop-holder{
    max-width:500px;
  }
  
  .zencyc-contents-item-header-bottom {
      font-size: 125%;
  }
  .zencyclopaedia-contents-item {
    height: 250px;
    max-height: 250px;
    width: 280px;
    max-width: 280px;
  }

  .zen-shop-item{
    width: 240px;
    max-height: 290px;
    height: 290px;
    border-radius: 25px;
    box-shadow: $toms_blue 0px 0px 5px;
    padding-bottom: 15px;
    border-style: solid;
    border-color: $ming-green;
    border-width: 2px;
    margin-bottom: 20px;
    margin: auto;
    overflow: hidden;
    }

    .zen-shop-item-holder{
      padding:10px;
    }
    
    .shop-item-image {
      width: 100%;
      height:210px;
      max-height: 220px;
      display: block;
      object-fit: fill;
    }
  
.article-slider-item{
  margin-top: 15px;
  margin-bottom: 10px;
}

}

@font-face {
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

@font-face {
  font-family: 'Okashi';
  src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

}

@font-face {
  font-family: 'NashiMurah';
  src: local('NashiMurah'), url(../../static/fonts/Zen_Kurenaido/NashiMurah.otf) format('opentype');

}


@media only screen and (max-width:376px) {


.zenscape-slider-item {
  width:230px;
  min-width: 230px;
  max-width: 230px;
}
  .zenscape-back-icon {
    width: 50px;
    height: 50px;
    margin-top: 25px;
    margin-left: 27.5px;
  }


  .box:hover input {
    width: 300px;
    color: $bright-ornge;
  }

  // .zenCardPreview{
  //   width:130px;
  //   height:175px;
  // }
}