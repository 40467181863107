$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen: #7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP: #BEB7DF;
$medPurple: #8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite: #FCEBC9;
$off-black: #282c33;
$totem: #9e2e0b;
$navajoLow: #f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white: #f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);


.zenscape-yt-container {
  position: absolute;
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: 98 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  padding-bottom: 100px;

}

.zenscape-yt-video-theme-container {
  height: 100%;
}

.practice-portal-container {
  width: 100%;
  background-color: $navajoWhite;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  display: block;
  top: 0;
}

.primary-gong-options {
  position: absolute;
  z-index: 9999999999999999 !important;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100vw;
  //margin-left:-65vw;
}
.movePracticeIcon{
  width:25px;
  height:25px;
  border-radius: 50%;
  background-size: contain;
  background-image: url('../../static/photos/languageIcons/simpleArrow.png');
}

.movePracticeUp{
  transform: rotate(270deg);
}

.movePracticeDown{
  transform: rotate(90deg);
}

.gong-options-summary {
  height: fit-content;
  position: absolute;
  z-index: 9999999999999999 !important;
  width: 100vw;
}



.primary-gong-options::-webkit-scrollbar {
  display: none;
}

// Youtube zenscape themes:
.magic-mountain {
  background-size: contain;
  background-image: url('../../static/photos/zenscapes/magicMountain.png');
}

.secret-dojo {
  background-size: cover;
  background-image: url('../../static/photos/zenscapes/SecretDojo.png');

}

.strings-vid {
  background-size: cover;
  background-image: url('../../static/photos/zenscapes/stringscape.png');

}

.path-summary {
  width: 100%;
  height: 100%;
  background-color: $navajoWhite;
  padding-top: 155px;
  position: fixed;
  top: 0;
}

.practice-title-row {
  padding-top: 15px;
  max-width: 100vw;
  padding-bottom: 15px;
}

.PractitionerName {
  margin-top: 0;
  font-size: 80%;

}

.pathIconText {
  margin-top: 0;
}

.course-tag {
  margin-top: 15px;
  margin-left: 15px;
  float: left;
  background-color: $dGreen;
  color: $ming-green;
  border-radius: 8px;
  padding: 8px;
  padding-top: 4px;
  height: 20px;
  line-height: 20px;
  font-weight: 900;
  text-align: center;
}


.path-image {
  display: block;
  width: 80%;
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}

.path-summary-image {
  width: 130px;
  height: 130px;
  border-radius: 30px;
  border-width: 3px;
  border-style: solid;
  border-color: $toms_blue;
  display: block;
  margin: auto;
}

.practice-preview-title {
  font-size: 70%;
  width: 100%;
  margin: auto;
  min-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: #28666E;
  margin-top: 15px;
}

.practitioner-preview-title{
  font-size: 125%;
  margin: auto;
  text-align: center;
  color: #28666E;
  margin-top: 15px;
  margin-bottom:25px;
}

.path-preview-title {
  font-size: 80%;
  width: 100%;
  margin: auto;
  min-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  color: $primary_black;
  margin-top: 15px;
}


.content-preview-image {
  display: block;
  width: 100px;
  height: fit-content;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;

}

.article-preview-image {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;

}



.practitioner-preview-image{
  object-fit: contain;
  margin-top: 0;
  height:auto !important;
}

.pract-preview-image-fitter{
  width:100px;
  height:100px;
  max-height: 110px;
  overflow: hidden !important;
  border-radius: 15px !important;
  margin:auto;
  margin-top: 15px;
  
}

.content-preview-image-sSmaller{
  width: 80px;
  height: 80px;
}

.path-prev-image {
  width: 75px;
  height: 75px;
  position: relative;
  margin-left: -5px;
  margin-top: 10px;
}


.practice-preview-info {
  width: 200px;
}

.zen-c-logo {
  min-width: 52%;
  width: 52%;
  height: 90vh;
  max-height: 90vh;
  background-size: contain;
  background-image: url('../../static/photos/zencyclogo.png');
  background-repeat: no-repeat;
  display: block;
  margin-left: 24%;
}

.MyZenStats {
  position: relative;
  margin-left: 15px;
}

.myzenDetails-username {
  text-align: center;
  font-size: 100%;
  margin-top: 10px;
  font-weight: 900;
  color: $ming-green;
  margin-bottom: 20px;
}


.myzenDetails-bio {
  text-align: center;
  font-size: 100%;
  margin-top: 10px;
  font-weight: 900;
  color: $toms_blue;
  margin-bottom: 20px;
}

.myzenDetails-tier {
  font-size: 90%;
  font-weight: 900;
  text-align: center;
  margin-bottom: 15px;
}

.myzenDetails-streak {

  font-size: 90%;
  font-weight: 300;
}

.myzenDetails-hours {
  font-size: 90%;
  font-weight: 300;
}

.practice-portal-timer-holder {
  position: fixed;
  top: 0;
  z-index: 9;
  display: block;
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.practice-portal {
  width: 100%;
  margin: auto;
  margin-top: 0;
  padding-top: 200px;
}

.practice-portal-image {
  width: 100%;
  border-radius: 50px;
  object-fit: cover;
  border-radius: 50px;
  overflow: hidden;
  max-height: 300px;
  max-width: 350px;
  display: block;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
}

.practice-image {
  width: 100%;
  border-radius: 50px;
  object-fit: cover;
  border-radius: 50px;
  overflow: hidden;
  max-height: 300px;
  max-width: 150px;
  display: block;
  margin: auto;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
}

.zenscape-standard-video-holder {

  width: 95vw;
  margin: auto;

}


.indie-image {
  width: 100vw;
  height: 75px;
  background-size: cover;
  background-image: url("../../static/blueRoom.png");
  border-width: 0;
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-style: solid;
  border-color: $totem;
  margin: 0;
  box-shadow: $totem 0px 0px 200px inset;
}

.indie-image-active {
  width: 100vw;
  height: 300px;
  background-size: cover;
  background-image: url("../../static/blueRoom.png");
  border-width: 0;
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-style: solid;
  border-color: $totem;
  margin: 0;
  box-shadow: $sage 0px 0px 75px inset;
  border-color: $sage;
}

.chooseRoomPrompt {
  opacity: 1;
  color: $navajo_white;
  width: 100%;
  text-align: center;
}

.indie-image-active .chooseRoomPrompt {
  opacity: 0;
}

.indie-image-active .room-on-air {
  float: right;
  background-color: $sage;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px;
  width: 15px;
  box-shadow: $sage 0px 0px 5px inset;
  height: 15px;
}

.indie-image2 {
  background-image: url("../../static/violetRoom.png");
}

.indie-image3 {
  background-image: url("../../static/zenden.png");
}

.circle-zenscape-holder-inner {
  width: 550px;
  height: 500px;
  margin-top: -30px;
  margin-left: -75px;
}

.circle-zenscape-portal-holder {
  border-radius: 150px;
  overflow: hidden !important;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 75px;
  background-color: $primary_black;
}

.SoundRoomPortal {
  width: 100vw;
  background-image: url("../../static/blueRoom.png");
  max-height: 100vh;
  background-size: cover;
}

.practice-video-holder {
  width: 100%;
  height: fit-content;
  margin: auto;
  margin-top: 0px;
  min-height: 700px;

}

.zenscape-yt-video-holder {
  min-width: 300px;
  width: 100vw;
  min-height: 250px;
  position: absolute;
  display: block;
}

.path-syllabus {
  display: block;
  max-height: 90vh;
  padding-top: 5px;
  padding-bottom: 150px;
}

.mobile-path-syllabus {
  width: 95%;
  padding-top: 50px;
}



.volume-controls {
  width: 40%;
  top: 30px;
  right: 25px;
  position: absolute;
}

.practice-portal-zenscape-holder-large {
  width: 100%;
  height: 290px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 5px;
}

.circle-practice-portal-large {
  position: relative;
  margin-left: -100px;
  margin-top: -10px;
}

.circle-practice-portal-holder {
  width: 450px;
  height: 450px;
  margin-top: 50px;
  margin-left: 450px;
  border-radius: 225px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;

  border-color: $primary_black;
  border-style: solid;
  border-width: 5px;
}

.circle-practice-portal {
  position: relative;
  margin-left: -175px;
}

.enzo-timer-holder {
  margin: auto;
  min-height: 200px;
}

.enso-timer {
  width: 140px;
  height: 140px;
  background-image: url("../../static/photos/timers/buddEnso.png");
  background-size: cover;
  margin: auto;
  margin-top: 25px;
  text-align: center;
  overflow: hidden;

}

.pli-play-button {
  width: 30px;
  background-image: url("../../static/photos/MingPlay.png");
  background-size: contain;
  height: 30px;
  margin-top: 4px;
  float: right;
  margin-right: 30px;
  margin-left: 30px;
}

.pli-play-button:hover {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-right: 25px;
  cursor: pointer;
}

.audio-play-icon {
  color: $budd_orange;
  font-size: 350%;
  padding-top: 35px;
  text-align: center;
  margin-left: 19px;
  margin-top: 5px;
}

.audio-pause-icon {
  color: $budd_orange;
  font-size: 350%;
  padding-top: 35px;
  text-align: center;
  margin-left: 15px;
  margin-top: 5px;
}

.audio-time-rem {
  padding-top: 65px;
  color: $budd_orange;
  margin-left: 10px;
}

.snake-player-container {
  width: 260px;
  height: 260px;
  border-radius: 130px;
  background-image: url("../../static/photos/timers/ZenTimer.png");
  background-size: cover;
  text-align: center;
  overflow: hidden;
}

.SnakeTimerIcon {
  color: $navajo_white;
  font-size: 500%;
  padding-top: 80px;
  margin-left: -10px;
}

.snake-time-rem {
  padding-top: 100px;
  color: $navajo_white;
}

::-webkit-scrollbar {
  width: 1px;
}

.path-icons {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  background-color: #7C9885;
  padding: 10px;
  padding-top: 0;
  border-width: 3px;
  border-style: solid;
  border-color: $toms_blue;
}

.med-icons {
  border-radius: 15px;
  width: 80%;
  max-width: 80%;
  margin: auto !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  background-color: #7C9885;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.practice-preview-icons {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
}

.preview-icon {
  font-size: 70%;
  width: 33%;
  text-align: center;
}


.creator-stamp-icons {
  height: 120px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  background-color: #7C9885;
  padding: 5px;

}

.path-icon {
  text-align: center;
  font-size: 90%;
  width: fit-content;
  margin: auto;
  padding-top: 7px;

}

.med-icon {
  text-align: center;
  margin: auto;
  margin-top: 7px;
  margin: auto;


}

.med-banner {
  width: 100%;
  height: 200px;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}

.med-banner-image {
  width: 100%;
  background-size: cover;
  max-height: 600px;
}

.path-weeks-icon {
  width: 140px;
  margin-right: 20px;
  text-align: center;
}

.path-overview {
  width: 100vw;
  background-color: $ming-green;
  display: flex;
  flex-direction: row;
  color: $navajo_white;
  padding-top: 5px;
  height: fit-content;
}

.path-description {
  text-align: center;
  padding: 20px;
  width: 90vw;
}

.practice-description {
  text-align: center;
  padding: 20px;
  width: 90vw;
}

.pathIconNumber {
  font-size: 300%;
  padding-top: 0px;
  height: 70px;
  margin-bottom: 0;
}

.iconNumber {
  font-size: 210%;
  margin-top: 5px;
  font-weight: 900;
  margin-bottom: 0;
}

.pathWeeks {
  font-size: 50%;
  margin-left: -10px;
}

.favourite-icons {

  position: absolute;
  right: 25px;
}

.saveForLaterIcon {
  color: #C1666B;
  float: left
}

.favouriteIcon {
  color: $budd_orange;
  float: left;
  margin-left: 20px;
}

.iconMetric {
  font-size: 80%;
}

.PractitionerPhotoIcon {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
  margin-left: 5px;
}
.image-nugget-image{
  
  display:block;
  width: 275px;
  height: 275px;
  border-radius: 15px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
  margin: auto;
  box-shadow: $primary_black 0px 0px 15px;
  margin-top: 0;
}
.meditation-icons-practitioner-image {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
  margin: auto;
  box-shadow: $primary_black 0px 0px 15px;
  margin-top: 15px;
  overflow: hidden;
}

.texNuggetImage{
  display:block;
  width: 70px;
  height: 70px;
  border-radius: 15px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
  margin: auto;
  box-shadow: $primary_black 0px 0px 15px;
  margin-top: 15px;
}
.texNuggetImageLarger{
  width: 110px;
  height: 110px;
  margin-top: 0px;
  margin-bottom: 5px;

}
.practitioner-name {
  font-size: 90%;
  color: $toms_blue;
  text-align: center;

}

.practitioner-preview-name {
  font-size: 60%;
  color: $toms_blue;
  text-align: center;

}

.med-syllabus {

  padding-right: 5%;
}

.practice-list-item {
  height: 60px;
  width: 80%;
  margin: auto;
  background-color: $off-white;
  border-radius: 10px;
  border-color: $navajo_white;
  border-style: solid;
  border-width: 2.5px;
  border-left-width: 12px;
  padding-top: 10px;
  margin-bottom: 15px;
  box-shadow: $primary_black 0px 0px 8px;
}


.practice-list-item-completed {
  border-color: $sage;
}

.practiceListItemDetails {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
  // float:left;
  font-size: 70%;
  width: 100%;
  margin-top: 7px;
}

.practiceListItemPhoto {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-color: $dGreen;
  border-style: solid;
  border-width: 2px;
  display: block;
  float: left;
  box-shadow: $dGreen 0px 0px 10px;
  margin-right: 5px;
  float: right;
}

.practice-list-item-number {
  float: left;
  color: $primary_black;
  margin-left: 10px;
  text-align: center;
}

.practice-list-item-right {
  display: flex;
  flex-direction: row;
  position: absolute;
  color: $primary_black;
  text-align: center;
  margin-right: 15px;
}

.practice-list-item-title {
  color: $primary_black;
  text-align: center;
  width: fit-content;
  margin: auto;
  font-size: 125%;
  text-overflow: ellipsis;
  font-weight: 900;
}

.path-list-item {
  width: 90%;
  height: 110px;
  display: flex;
  flex-direction: row;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px 0 0 0;
  margin-left: 5%;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
}

.path-list-item-title {
  color: $primary_black;
  text-align: center;
  height: 40px;
  margin-top: 5px;
}

.path-creator {
  text-align: center;
}

.path-summary-title {
  margin: auto;
  width: fit-content;
  margin-bottom: 5px;
  margin-top: 25px;
  font-size: 200%;
  color: $ming-green;
  text-align: center;

}

.path-title {
  width: 70%;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 5px;
  font-size: 200%;
  color: $bright-ornge;
  text-align: center;

}


.path-tagline {
  margin-top: 5px;
  height: 50px;
}

.path-list-item-tagline {

  color: $toms_blue;
  text-align: center;
  font-size: 120%;
  height: 30px;

}

.practice-overview-image {
  display: block;
  margin: auto;
  width: 90%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  min-height: 200px;
  object-fit: cover;
  max-width: 330px;
  margin-bottom: 15px;
  max-height: 750px;
}

.image-dimensions-image{
  width:250px;
  height:fit-content;
  object-fit:contain;
}

.path-image-holder-mobile {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;

}

.path-overview-syllabus-toggle {
  display: flex;
  flex-direction: row;
  color: $bright-ornge;
  padding-top: 10px;

}

.path-overview-toggle {
  width: 50%;
  text-align: center;

}

.path-syllabus-toggle {
  width: 50%;
  text-align: center;

}

.lecture-preview {
  background-color: $sage;
  min-height: 590px;
  padding-top: 30px;
}

.practice-summary {
  background-color: $navajoWhite;
  height: fit-content;
  padding-top: 125px;
  padding-bottom: 125px;

}

.portal-exit-icon {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  position: fixed;
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  rotate: 180deg;
  z-index: 999999999999999999999999999999;
  top: 15px;
  left: 15px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px inset, rgba(0, 0, 0, 0.35) 0px 0px 5px;
}

.portal-finish-icon {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  position: fixed;
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5549;
  top: 15px;
  right: 15px;
  border-radius: 50%;
}

.med-preview {
  background-color: $navajo_white;
}

.meditation-details-section {
  width: 800px;
  margin: auto;
  padding-top: 60px;
}

//make  'mobile' class over duplication
.path-prev-show-sessions-mobile {
  margin-top: 20px;
  margin-left: 35px;
  font-size: 100%;

}

.path-prev-show-sessions {
  float: right;
  margin-top: 20px;
  margin-right: 35px;
  font-size: 150%;
  display: none;
}

.path-details-section {
  min-width: 100%;
  min-width: 100vw;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 50px;
}

.path-summary-details-section {
  min-width: 100%;
  min-width: 100vw;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
}

.content-preview-image-holder {
  width: 30%;
  min-width: 30%;
  height: 100%;
  margin-left: 5%;
}

.practice-details-section {
  min-width: 100%;
  min-width: 100vw;
}


.practice-image-holder {
  min-width: 500px;
  padding-top: 25px;
  display: none;
  //height:500px;
  max-height: 450px;
}


.path-list-item-stats {
  color: $primary_black;
  text-align: center;
  font-size: 90%;
  width: 50%;
}

.path-list-item-details {
  width: 85%;
}

.path-list-item-image {
  width: 120px;
  background-size: cover;
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}



.meditation-preview-header {
  font-size: 250%;
  margin-top: -15px;
}

.start-meditation-text {
  text-align: center;
  margin: 50px;
  color: $navajo_white;
}

.practice-header-info {
  height: 35px;
  color: $ming-green;
}

.practice-image-holder-mobile {
  width: fit-content;
  margin: auto;
}

.practice-title {
  font-size: 150%;
  position: relative;
  color: $primary_black;
  text-align: center;
  margin: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  max-width: 280px;
}


.med-details-section {
  width: 60%;

}


.med-image-holder {
  width: 40%;
}

.med-image {
  width: 100%;
}

.med-icons-row {
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px 0 2px 0;
  margin: 20px;
  margin-left: 2%;
  margin-right: 2%;
}


.info-icon {
  margin: auto;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin-top: 35px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 3px;
}

.info-icon-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}


.practice-portal-content-container {
  width: 100vw;
  //position:fixed;
  //top:0;
  //background-color: $navajoWhite;
}

@media only screen and (min-width:480px) {


  .practice-title-row {
    display: flex;
    flex-direction: row;
  }

  .practice-title {
    font-size: 200%;
    position: relative;
    color: $primary_black;
    text-align: center;
    margin: auto;
    margin-left: 10px;
    width: 45vw;
    margin-top: 10vw;
    max-width: 350px;
  }

  .practice-image {
    max-width: 350px;
    width: 600px;
    max-height: 300px;
  }


  .practice-overview-image {
    max-width: 300px;
  }


  .volume-controls {
    float: right;
    width: 40%;
    margin-top: 30px;
    margin-right: 10%;
  }

  .path-icon {
    width: 150px;
    margin-right: 30px;
  }

  .path-weeks-icon {
    margin-right: 25px;
  }

  .iconNumber {
    font-size: 200%;
  }

  .path-title {
    margin-top: 100px;
    font-size: 350%;
  }

}

@media (max-width:376px) {

  .path-image {
    max-width: 275px;
  }

  .indie-image-active {
    height: 275px;
  }

  .indie-image {
    height: 58px;
  }

  .practice-overview-image {

    max-width: 345px;
    max-height: 150px;
  }

  .enso-timer {

    width: 100px;
    height: 100px;
  }

  .audio-play-icon {
    font-size: 260%;
    padding-top: 24px;
    margin-left: 18px;
    margin-top: 5px;
  }

  .audio-pause-icon {
    font-size: 260%;
    padding-top: 24px;
    margin-left: 14px;
    margin-top: 5px;
  }

  .PractitionerPhotoIcon {
    width: 70px;
    height: 70px;
    border-radius: 15px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 2px;
    margin-left: 5px;
  }

  .PractitionerName {}



  .enzo-timer-holder {
    margin: auto;
    min-height: 156px;
  }
}


@media (max-width:322px) {

  .path-image {
    max-width: 255px;
  }

  .practice-overview-image {

    max-width: 275px;
  }


}

@media only screen and (min-width:580px) {

  .practice-title {
    font-size: 270%;
  }
}


@media only screen and (min-width:768px) {

  .practice-title {
    max-width: none;
    font-size: 350%;
    color: $primary_black;
    margin-top: 10vw;
    margin-bottom: 20px;
    width: 100%;
    min-width: 45vw;
  }

  .practice-image {
    max-width: 700px;
    width: 700px;
    max-height: 380px;
  }

  .practice-portal-content-container {
    //margin-left:200px;
  }

  .volume-controls {
    margin-right: 130px;
  }

  .path-icon {
    width: 150px;
    margin-right: 30px;
  }

  .iconNumber {
    font-size: 250%;
  }

  .practice-list-item-number {
    margin-left: 30px;
  }


  .practice-overview-image {
    max-width: 450px;
    max-height: 340px;
  }

}

@media only screen and (min-width:1024px) {

  .practice-portal-timer-holder {
    margin: auto;
    width: 50vw;
    left:25vw;
  }
  .practice-video-holder {
    width: 80%;
    max-width: 900px;
    height: fit-content;
    margin: auto;
    margin-top: 0px;
    min-height: 700px;
  }
  
  .practice-title-row {
    max-width: 50vw;
  }

  .practice-summary-title-row{
    margin-top: -100px;
    padding-top: 0;
    height: fit-content;
  }

  .practice-summary-title {
    font-size: 105% !important;
    height: fit-content;
  }

  .practice-summary {
    padding-top: 105px;
  }

  .practice-title {
    max-width: none;
    font-size: 300%;
    color: $primary_black;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    min-width: 45vw;
  }

  .practice-description {
    text-align: center;
    padding: 20px;
    width: 45vw;
  }

  .practice-image-holder-mobile {
    display: none;
    width: 0;
  }

  .practice-image-holder {
    width: 45%;
    padding-top: 50px;
    display: block;
  }

  .practice-details-section {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    margin: auto;
    margin-top: 50px;
  }

  .path-icon {
    width: 150px;
    margin-right: 30px;
  }

  .iconNumber {
    font-size: 300%;
    margin-top: 0px;
  }

  // .enso-timer{
  //   width:260px;
  // height:260px;
  // border-radius: 130px;
  //   float: none;
  //   margin: auto;
  //   margin-top: 0px;
  //   display: block;
  //   margin-bottom: 20px;
  // }
  // .enso-timer{
  //   width:260px;
  // height:260px;
  // border-radius: 130px;
  //   float: none;
  //   margin-top: 0px;
  //   margin-left: 0px;
  //   display: block;
  //   margin-bottom: 20px;
  //   margin-right: 50px;
  // // }
  // .enzo-timer-holder{
  //   min-height: 200px;
  //   margin-bottom: 20px;
  //   float: none;
  //   display: block;

  // }

  .volume-controls {
    float: none;
    margin-top: 300px;
    display: block;
    width: 60%;
    margin-right: 50px;
  }



  .practice-overview-image {
    background-size: cover;
    width: 90%;
    max-width: 290px;
    margin: auto;
    margin-bottom: 25px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px
  }

}

@media only screen and (min-width:1200px) {



  .portal-exit-icon {
    left: 250px;
  }




  .audio-play-icon {
    font-size: 320%;
    padding-top: 37px;
    margin-left: 22px;
    margin-top: 5px;
  }

  .audio-pause-icon {
    font-size: 320%;
    padding-top: 37px;
    margin-left: 15px;
    margin-top: 5px;
  }
}


.path-image-holder {
  margin-top: 25px;
  width: 300px;
  min-width: 200px;
}

@media only screen and (min-width:1300px) {

  .path-details-section {
    padding-left: 5%;
    width: 50%;
  }

  .path-image-holder {
    width: 45%;
    padding-top: 25px;
    display: block;
  }

  .path-image {
    background-size: cover;
    width: 90%;
    margin: 5%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  }

  .path-image-holder-mobile {
    display: none;
  }

  .practiceListItemDetails {
    font-size: 100%;
  }

  .path-icons {
    display: flex;
    flex-direction: row;

  }

  .audio-time-rem {
    padding-top: 100px;
    margin-left: 10px;
  }

  .iconNumber {
    font-size: 400%;
    margin-top: -5px;
  }

  .PractitionerPhotoIcon {
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 2px;
    margin-left: 12px;
    margin-top: 15px;
  }



  .mobile-path-syllabus {
    display: none;
  }

  .path-prev-show-sessions {
    display: block;
  }

  .practice-image {
    max-height: 600px;
    width: 700px;
  }

  .practice-portal-zenscape-holder-large {
    width: 500px;
    height: 450px;
    margin-left: 300px;
    border-radius: 25%;
    margin-top: 100px;
  }

  .circle-practice-portal-large {
    position: relative;
    margin-left: -170px;
  }
}