$bright-ornge: #FFBC42;
$primary_black: #272727;
$navajo_white: #FEDC97;
$off-black:#282c33;
$budd_orange: #D1603D;
$sage: #B5B682;
$ming-green: #28666E;
$toms_blue: #033f63;
$navajoWhite:#FCEBC9;
$ming-fade: rgba(40, 102, 110, 0.7);
$budd-fade: rgba(209, 96, 61, 0.7);
$budd-dark-fade: rgba(209, 96, 61, 0.9);
$blue_fade: rgba(3, 63, 99, 0.7);
$sage-fade: rgba(181, 182, 130, 0.7);
$doubdGreen: #707053;

.ZenStream{
  position: fixed;
  top: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.reel-practitioner{
  border-radius: 5px;
  width:90%;
  background-color: $navajo_white;
  margin-top:15px;
  border-style: solid;
  border-color: $primary_black;
}
.reel-stream-reel-practitioner{
  display: block;
  width:100%;
  //background-color: $ming-green;
  height:65px;
}
.zen-stream-ad{
}
.stream-reel-image{
  width:100px;
  height:150px;
  margin:auto;
  display: block;
  border-style: solid;
  border-color: $primary_black;
}
.reel-stream-reel-image{
  width:100%;
  height: 100%;
  min-height:282px;
  margin:auto;
  display: block;
}

.stream-reel-image-holder{
  width:160px;
  padding:25px;
}


.reel-stream-reel-image-holder{
  width:100%;
  min-height:347px;
}
.zen-stream-ad-photo{
  object-fit: contain; 
  width:100%;
  height:100%;
  align-items: center;
}



.shadow-photo-holder{
  padding-right: 20px;
}
.shadow-photo{
  object-fit:fill; 
  box-shadow: $off-black 10px 10px 50px inset, $off-black 0px 0px 5px;
  height:80%;
  max-height: 80%;
  margin-top: 10%;
  // border-style: solid;
  // border-width: 2px;
 //border-color: $off-black;
  border-radius: 25px;
}
.zen-stream-ad-bsc{
  display:flex;
  flex-direction: row;
  margin: auto;
  min-height:150px;
}

.zen-stream-ad-to{
  text-align: center;
  font-size: 90%;
}

.zen-stream-ad-text{
  width:65%;
  max-width: 65vw;
  font-size: 75%;
  padding-top:25px;
  text-align: center;
}

.zen-stream-ad-photo-holder{
  width:35%;
  max-width: 35vw;
}

.zen-stream-ad-photo-holder{
  width:290px;
}

.stream-items{
  padding-bottom:90px;
  background-color: $navajoWhite;
}

.swapStreamsBanner{
    position:fixed;
    top:0;
    padding-top:100px;
    background-color: $budd-dark-fade;
    width:100vw;
    height:150px;
    box-shadow: $primary_black 0px 5px 15px inset;
    border-color:$primary_black;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 2px;
    display:flex;
    flex-direction:row;
    z-index: 9999999;
}

.navajo{
  background-color:$navajo_white;
}

.sage{
  background-color:$sage;
}

.stream-path-details{
  padding-bottom:15px;
}

.tomsBlue{
  background-color:$toms_blue;

}

.infomatic-stream-item{
  background-color: $ming-green;
  margin-top: 0;
  padding-top: 0;
  display:block;
  position:relative;
  margin-top:-25px;

}

.info-stream-item-image{
  display: block;
  margin: auto;
  width:75%;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 15px;;
  max-height: 400px;
}
.info-stream-item-title{
  margin-bottom: 25px;
  text-align: center;
  font-size: 120%;
}
.info-stream-item-header{
  margin-left: 15px;
  padding-top: 7px;
  margin-bottom: 10px;
}
.FullyFocusedMedStartArrow{
  background-image: url("../../static/photos/MingArrow.png");
  width:100px;
  height:75px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 25px;
  margin-top: 25px;
}

.FullyFocusedMedStartMobile{
  background-image: url("../../static/photos/MingArrow.png");
  width:100px;
  height:100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 15px;
}
.walkthrough-navajo-next{
  background-image: url("../../static/photos/languageIcons/goIcon.png");
  width:20vw;
  height:20vw;
  margin:auto;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius:50%;
  margin-top:15px;
}
// .portal-exit-icon{
//   background-image: url("../../static/photos/languageIcons/simpleArrow.png");
//   position: fixed;
//   width:50px;
//   height:50px;
//   margin:auto;
//   background-size: contain;
//   background-repeat: no-repeat;
//   rotate: 180deg;
//   z-index: 5549;
// }


.portal-finish-icon{
  width:140px;
  height:140px;
  background-image: url("../../static/photos/timers/buddEnso.png");
  background-size: cover;
  margin: auto;
  margin-top: 25px;
  text-align: center;
  overflow: hidden;
  transform: rotate(180deg);
  
}

.zen-portal-finish-icon{
  width:140px;
  height:140px;
  background-image: url("../../static/photos/timers/buddEnso.png");
  background-size: cover;
  margin: auto;
  margin-top: 25px;
  text-align: center;
  overflow: hidden;
  transform: rotate(180deg);
  
}

.demo-finish-icon{
  position:fixed;
  top:0;
  right:25px;
  width:140px;
  height:140px;
  background-size: cover;
  margin: auto;
  text-align: center;
  overflow: hidden;
  transform: rotate(180deg); 
  z-index: 555;
}

.FinishStartQuiz{
  border-radius: 4px;
  width: 100px;
    margin: auto;
    margin-top: 25px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 900;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 8px;
  background-color: $ming-fade;
  border-color: $ming-green;
}
.PortalMedStartMobile{
  background-image: url("../../static/photos/NavajoWArrow.png");
  width:100px;
  height:100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 15px;
}
.ConfirmGoalsArrow{
  background-image: url("../../static/photos/NavajoWArrow.png");
  width:20vw;
  height:20vw;
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 15px;
  left: 40vw;
}
.FullyFocusedMedStartArrow:hover{
  width:120px;
  height: 120px;;
  margin-bottom: 15px;
  margin-top: 5px;
}
.FocusedMedStartArrow{
  background-image: url("../../static/photos/MingArrow.png");
  width:250px;
  height:75px;
  background-size: contain;
  background-repeat: no-repeat;
}


.FocusedMedStartArrow:hover{  
  background-image: url("../../static/photos/MingArrow.png");
}

.FocusedMeditationStreamItem {
    margin-top:0;
    background-color: $ming-green;
}
.FocusedMedStart{
  margin: auto;
  width: 100px;
  margin-bottom: 30px;
  margin-top: 5px;
}

.FullyFocusedMedStart{
  position: absolute;
  right:10px;
  margin-top:75px;
}

.focused-preview-overlay-text{
  display: none;

}

.PracticePreviewStart{
  width:100%;
  display: block;
}


.MeditationPortalStreamItem {
  background-color:$navajoWhite;
  margin-top:0;
  padding-top:10px;
  min-height: 550px;
  padding-bottom:10px;
}
.zen-skills-score-update{
  width:90%;
  margin:auto;
}
.zen-skills-score-update-value{
  width:33%;
  text-align: center;
  font-size: 75%;
}

.zen-skill-update-period-value{
  font-size: 100%;
  color: $navajo_white;
}

.zen-skill-update-period{
  margin-left:20px;
  color: $navajo_white;
  font-size: 60%;
  margin-bottom: 25px;
}

.zen-skills-stream-update{
  background-color: $off-black;
  padding-bottom: 25px;
  padding-top: 60px;
}

.zen-nugget-stream-item{
  box-shadow:  15px 15px 15px;
  background-color: $toms_blue;
  padding-top: 20px;
  padding-bottom: 20px;
  margin:0;
}


.zen-skills-map-stream-holder{
  width:95%;
  margin:auto;
  margin-bottom: 30px;
}
.MyZenSkillMap{

  width:90%;
  border-radius: 20px;
  height: 180px;
  background-size:contain;
  margin-left: 5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;
  min-width: 350px;
  min-height: 200px;;
}
.ZenSkillMap{
  width:90%;
  border-radius: 20px;
  background-size:contain;
  margin:auto;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;

}
.stream-buddha-bot{
  position:fixed;
  right: 40px;
  top: 100px;
  z-index: 999;
  display: none;
}

.stream-item {
  width:100%;
  border-bottom-style: solid;
  border-bottom-color: $primary_black;
  border-bottom-width: 5px;
  box-shadow: inset $off-black 0px 0px 5px;
  //margin:0 !important;
}

.reel-list-item{
  border-bottom-style: solid;
  border-bottom-color: $primary_black;
  border-bottom-width: 5px;
  box-shadow: inset $off-black 0px 0px 5px;
}

.reel-main-stream-item{
  background-color: $budd_orange;
  color:$primary_black;
}

.reel-item{
  width:100%;
  max-width:300px;
  height: fit-content;
  margin-top: 0;
  box-shadow: inset $off-black 0px 0px 5px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 3px;
  // border-top-width: 4px;
  // border-left-width: 2px;
  // border-right-width: 2px;
}

.reel-stream-reel-practitioner{
  border-color: $primary_black;
  border-style: solid;
  border-width: 1px;
  border-bottom-width: 3px;
}
.reel-stream-title{
  font-size: 75%;
  max-width:250px;
  max-height:30px;
  overflow: hidden;
  margin:auto;
  text-align: center;
  padding:3px;
  color:$navajo_white;
}


.blueReel{
  border-right-width: 3px;
  background-color: $blue-fade;
  border-color: $toms_blue;

  
.reel-stream-reel-practitioner{
  border-color: $toms_blue;
}
}

.sageReel{
  background-color: $sage-fade;
  border-color: $doubdGreen;
  border-left-width: 3px;
  .reel-stream-reel-practitioner{
    border-color: $doubdGreen;
  }

  .reel-stream-title{
    color: $off-black;
  }
}
.mingReel{
  border-left-width: 3px;
  background-color: $ming-fade;
  border-color: $ming-green;
  .reel-stream-reel-practitioner{
    border-color: $ming-green;
  }
}

.buddReel{
  background-color: $budd-fade;
  border-color: $budd_orange;
  border-right-width: 3px;
  .reel-stream-reel-practitioner{
    border-color: $budd_orange;
  }
}
.list-item{
  display:block;
  max-width: 90%;
  border-radius: 12px;
  margin:auto;
  border-bottom-style: solid;
  border-bottom-color: $primary_black;
  border-bottom-width: 5px;
  box-shadow: inset $off-black 0px 0px 5px;
  margin-bottom: 15px;
}



.FocusedMeditationStreamItemImageHolder {
    width : 100%;
    margin:auto;
    max-height:600px;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.FullyFocusedMeditationStreamItem{
  min-height: 450px;
  padding-bottom: 5px;
  background-color: #28666E;
  overflow: hidden;
}

.stream-reel-practitioner-image{
  width:30px;
  height:30px;
  border-radius: 4px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
  margin-top:5px;
}

.zen-reel{
  width:100%;
}
.zen-reel-preview{
  width:100%;
  min-width:100%;
  color: $primary_black;
  margin: 0 !important;
  display:flex;
  flex-direction: row;
  text-align: center;

  h3{
    font-family: Okashi;
    margin-bottom: 0;
  }

  h1{
    margin-top:0;
    font-size: 140%;
    margin-bottom:0;
    padding-left:10px;
    padding-right:10px;
  }
}

.FullyFocusedMeditationStreamItemImageHolder {
  width : 45%;
  min-width: 350px;
  min-height: fit-content;
  max-height:400px;
  overflow: hidden;
  margin: auto;
}
.PracticePortalStreamItemImageHolder {
  width : 300px;
  height: 300px;
  border-radius: 150px;
  margin:auto;
  margin-top:10px;
  overflow: hidden;
}

.FocusedMeditationStreamItemImage{
  width:85%;
  margin: auto;
  margin-top: 30px;
  display:block;
  background-size: contain;
}
.MeditationPortalStreamItemImage{
  width:100%;
  height:100%;
  object-fit: cover;
}

.focused-med-title{  
  font-size: 220%;
  margin:auto;
  color: BLACK;
  padding:15px;
  padding-bottom: 0;
  padding-top:5px;
  text-align: center;
  }

  .focused-med-title-large{
    font-size: 300%;

  }

 
  .focused-med-subheader {  
  font-size: 150%;
  text-align:center;
  margin:auto;
  padding-bottom:20px;
  max-width:380px;
  color: $primary_black;
  }

  .fully-focused-med-title{  
    font-size: 150%;
    margin:auto;
    color: $bright-ornge;
    padding:15px;
    padding-bottom: 0;
    padding-top:5px;
    text-align: center;
    }
  
   
    .fully-focused-med-subheader {  
    font-size: 110%;
    margin:auto;
    padding-bottom:20px;
    color: $navajo_white;
    text-align: center;
    }
::-webkit-scrollbar {
    width: 1px;
  }

  .zen-nugget{
    width:100%;
    min-width:100%;
    color: $bright-ornge;
    margin: 0 !important;
  }




  .focused-preview-overlay-text-mobile{
    display: block;
  }
  @media (max-width:376px) {

    .PracticePortalStreamItemImageHolder {
  width : 250px;
  height: 250px;
    }

.focused-med-title{  
  font-size: 160%;
}
.focused-med-title-large{
  font-size: 210%;
}
.focused-med-subheader {  
  font-size: 110%;
  padding-bottom:20px;
  max-width:350px;
  color: $primary_black;
  }

.PortalMedStartMobile{
  height: 75px;
  width: 75px;
}
.FullyFocusedMeditationStreamItemImageHolder {
  min-width: 360px;
}

.portal-finish-icon{
  margin-top:20px;
  width:100px;
  height:100px;
}


  }

  @media (max-width:322px) {

    .PracticePortalStreamItemImageHolder {
  width : 200px;
  height: 200px;
    }

.focused-med-title{  
  font-size: 150%;
}


.focused-med-title-large{
  font-size: 190%;
}
.focused-med-subheader {  
  font-size: 95%;
  padding-bottom:20px;
  max-width:290px;
  color: $primary_black;
  }
  .FullyFocusedMeditationStreamItemImageHolder {
    min-width: 310px;
  }


  }


  @media (min-width:414px) {
  .reel-stream-reel-image-holder{
    width:100%;
    height:373px;
    min-height:373px;
  }

  .reel-stream-reel-image{
    height:373px;
  }

 
  .reel-stream-reel-practitioner{
    // margin-top: 258px;
  }
}
  @media (min-width:480px) {
    .PracticePortalStreamItemImageHolder {
      width : 340px;
      height: 340px;
      border-radius: 170px;
      margin:auto;
      margin-top:20px;
      overflow: hidden;
    }

    .stream-buddha-bot{
      display: block;
    }

  }

  @media only screen and (min-width:768px) {
    .focused-preview-overlay-text-mobile{
      display: none;
    }
    .FullyFocusedMeditationStreamItemImageHolder {
      margin-left: 0;
    }
    .focused-preview-overlay-text{
      display: block;
      position: absolute;
      margin-top:10px;
      right: 0;
      width: 45%;
      padding: 5%;
    
    }
    .strem-item{
    .zen-nugget{
      width:500px;
    }
  }
    .zen-skills-stream-update{
      height: 650px;
    }
    .PracticePortalStreamItemImageHolder {
      width : 400px;
      height: 400px;
      border-radius: 200px;
      margin:auto;
      margin-top:20px;
      overflow: hidden;
    }

    .focused-med-title{  
      font-size: 300%;
      text-align:center;
      margin:auto;
      color: BLACK;
      padding-top:25px;
      }

      .focused-med-title-large{  
        font-size: 450%;
        }

      

.focused-preview-overlay-text{
  margin-top:200px;
}

.fully-focused-med-title{  
  font-size: 300%;
  text-align:center;
  padding-top:0px;
  }

  .focused-med-subheader {  
    font-size: 180%;
  }

  .fully-focused-med-subheader {  
  font-size: 180%;
  text-align:center;
  margin:auto;

  padding-bottom:20px;
  width:500px;
  }


.FullyFocusedMedStart{
  position: absolute;
  right:100px;
  margin-top:30px;
}

.FullyFocusedMedStartArrow{
  width:250px;
  height:75px;
}

.focused-preview-overlay-text{
  position: absolute;
  margin-top:10px;
}

.zen-skills-map-stream-holder{
  width:550px;
  margin:auto;
  margin-bottom: 40px;
}

.zen-skills-score-update-value{
  font-size: 80%;
    }


.ZenSkillMap{
  height: 300px;
}

.zen-skill-update-period{
  font-size: 65%;
}

  }

  @media only screen and (min-width:1024px) {


    .list-item{
      max-width: 450px;
    }

.info-stream-item-image{
  max-height: 250px;
}
    .zen-stream-ad-text{
      font-size: 160%;
      width:50%;
      max-width: 60vw;
      padding-top:100px;
    }
    
    .zen-stream-ad-photo-holder{
      width:50%;
      max-width: 40vw;
      max-width:300px;
      max-height: 300px;
    }    

    .zen-skill-update-period{
      font-size: 75%;
    }
    .PracticePortalStreamItemImageHolder {
      width : 300px;
      height: 300px;
      border-radius: 150px;
      margin:auto;
      margin-top:20px;
      overflow: hidden;
    }

    .zen-skills-score-update-value{
  font-size: 100%;
    }

    .strem-item{
    .zen-nugget{
      width:850px;
    }
  }
  }


  
  @media only screen and (min-width:1200px) {

    .walkthrough-navajo-next{
    width:80px;
    height:80px;
    }
  }






  

  