:root {
  --skin: #f2b383;
  --hair: #d1603d;
  --border: #033f63;
  --tshirt1: #28666e;
  --tshirt2: #b5b682;
  --shadow: #483b5555;
  --bg: #fcebc9;
}
$interval: 4s;
@font-face {
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

@font-face {
  font-family: 'Okashi';
  src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

}
.nordii *,
.nordii *:after,
.nordii *:before {
  box-sizing: border-box;
}
.nordii *:after,
.nordii *:before {
  content: "";
}
.yogi-lady *,
.yogi-lady *:after,
.yogi-lady *:before {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.nordii-holder{
margin-top:0px;
width:100vw;
overflow: hidden;
height:fit-content;
padding-top:90px;
}
.nordii {
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 320px;
  background: var(--bg);
  color: var(--border);
  font-family: Okashi;
  font-weight: 700;
  margin: auto;
}
.body {
  background: var(--skin);
}
.yogi-lady {
}
.illustration {
  position: relative;
  height: 350px;
  z-index: 1;
  margin: auto;
  top: -50px;
}

.illustration:before {
  position: absolute;
  left: 80px;
  top: 80px;
  height: 2px;
  width: 16px;
  border-radius: 2px;
  opacity: 0.15;
  box-shadow: -40px 80px 0, 100px -40px 0, 11px 24px 0, 120px 160px 0,
    -60px -100px 0, -100px 40px 0, 180px 68px 0, 240px -120px 0, -40px 80px 0,
    100px -40px 0, 110px 48px 0, 240px 96px 0, -60px -100px 0, -100px -52px 0,
    -180px 68px 0, 240px -120px 0;
  animation: sth infinite linear 40s -2s;
  transform: translate3d(-400px, 0, 0);
  z-index: -1;
}

@keyframes sth {
  100% {
    width: 20px;
    transform: translate3d(400px, 0, 0);
  }
}

.hair {
  border: 2px solid;
  box-shadow: inset 0 20px 0 20px var(--shadow);
  background-color: var(--hair);
  background-image: linear-gradient(
      -20deg,
      transparent 88%,
      var(--border) 0%,
      var(--border) 88.5%,
      transparent 0
    ),
    linear-gradient(
      -18deg,
      transparent 90%,
      var(--border) 0%,
      var(--border) 90.5%,
      transparent 0
    ),
    linear-gradient(
      -24deg,
      transparent 78%,
      var(--border) 0%,
      var(--border) 78.5%,
      transparent 0
    ),
    linear-gradient(
      -27deg,
      transparent 75%,
      var(--border) 0%,
      var(--border) 75.5%,
      transparent 0
    ),
    linear-gradient(
      30deg,
      transparent 18%,
      var(--border) 0%,
      var(--border) 18.5%,
      transparent 0
    ),
    linear-gradient(
      28deg,
      transparent 19%,
      var(--border) 0%,
      var(--border) 19.5%,
      transparent 0
    );
  border-bottom: none;
  background-position: top left;
  bottom: 80px;
  height: 228px;
  width: 88px;
  border-radius: 40% 40% 0 0 / 21% 21% 0 0;
  z-index: 2;
}
.hair:after,
.hair:before {
  box-shadow: inset 0 8px 0 20px var(--shadow);
  background-color: inherit;
  background-image: linear-gradient(
    40deg,
    transparent 40%,
    var(--border) 0%,
    var(--border) 45%,
    transparent 0
  );
  border: 0.5px solid;
}
.hair:after {
  width: 50%;
  height: 8%;
  border-radius: 50% / 100%;
  top: -18px;
}
.hair:before {
  top: -30px;
  width: 30%;
  height: 6%;
  border-radius: 60% / 100%;
  background-image: linear-gradient(
    -45deg,
    transparent 50%,
    var(--border) 0%,
    var(--border) 58%,
    transparent 0
  );
}

.face {
  top: 72px;
  width: 56px;
  height: 34px;
  border-radius: 0 0 48px 48px / 0 0 60px 60px;
  border: 2px solid;
  border-top: none;
  z-index: 2;
}

.face-up {
  background: var(--border);
  height: 68px;
  width: 56px;
  top: 4px;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}
.face-up:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 64px solid var(--skin);
}
.nose {
  top: -14px;
  width: 15%;
  height: 6.5px;
  border-radius: 0 0 6px 6px;
  border: 2px solid var(--shadow);
  border-top-color: var(--skin);
}
.lips {
  bottom: 30%;
  text-align: center;
  padding: 3px;
  height: 3.2px;
  width: 20px;
}
.lips:before,
.lips:after {
  color: #a37a6d;
  background: #a37a6d99;
  border: 0.5px solid;
  top: 0;
  height: 100%;
}
.lips:before {
  right: auto;
  width: 51%;
  border-right: none;
  transform: skew(0deg, 12deg);
  border-radius: 40px 40px 0 40px;
}
.lips:after {
  left: auto;
  width: 50%;
  transform: skew(0deg, -12deg);
  border-left: none;
  border-radius: 10px 10px 10px 0;
}
.eyes {
  top: 54px;
  height: 16px;
  background: var(--shadow);
  border-top: 2px solid;
}
.eyes:before,
.eyes:after {
  background: currentcolor;
  border-radius: 50%;
  width: 11px;
  height: 11px;
}
.eyes:before {
  right: auto;
  left: calc(50% + 8.5px);
}
.eyes:after {
  left: auto;
  right: calc(50% + 8.5px);
}
.neck {
  box-shadow: inset 0 8px 0 var(--shadow);
  border: 2px solid;
  height: 28px;
  width: 22.7%;
  top: 100px;
  border-bottom-color: var(--skin);
  z-index: 1;
}

.neck:before {
  border: 8px solid;
  border-top: 0;
  height: 72px;
  width: 32px;
  left: -8px;
  top: 100%;
  border-radius: 0 0 16px 16px / 0 0 32px 32px;
}
.neck:after {
  width: 5px;
  height: 5px;
  background: currentcolor;
  border-radius: 50%;
  top: 22px;
  left: -32px;
  box-shadow: 0.5px 8px currentcolor, 0.5px 16px currentcolor,
    0.5px 24px currentcolor, 0.5px 32px currentcolor,
    0.5px 40px currentcolor, 1px 48px currentcolor,
    3px 56px currentcolor, 7px 64px currentcolor,
    17px 70px currentcolor, 31.5px 0 currentcolor, 31.5px 8px currentcolor,
    31.5px 16px currentcolor, 31.5px 6px currentcolor, 31.5px 32px currentcolor,
    31.5px 40px currentcolor, 30px 48px currentcolor,
    7.25px 56px currentcolor, 25px 64px currentcolor;
}

.arms {
  border: 2px solid;
  border-bottom: none;
  height: 104px;
  width: 104px;
  z-index: 1;
  bottom: 20%;
  border-radius: 10% 10% 0 0;
}
.arms:after {
  width: 2px;
  height: 100%;
  left: 4px;
  right: auto;
  background: var(--shadow);
}
.decoltee {
  border: 2px solid;
  height: 16%;
  width: 54%;
  top: 126px;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
}
.tshirt {
  background-image: linear-gradient(
    to bottom,
    var(--tshirt1),
    var(--tshirt1) 50%,
    var(--tshirt2) 50%,
    var(--tshirt2)
  );
  background-size: 100% 80px;
  border: 0.5px solid;
  border-bottom: none;
  height: 44%;
  width: 54%;
  bottom: 0;
  box-shadow: inset 2px 0 0 var(--shadow);
}
.left-hand,
.right-hand {
  width: 20px;
  bottom: 20%;
  border: 2px solid;
  border-bottom: none;
  right: auto;
  z-index: 2;
}
.left-hand {
  left: calc(50% - 82px);
  height: 10%;
  box-shadow: inset 0 4px 0 #483b5555;
}
.right-hand {
  left: calc(50% + 56px);
  height: 22%;
  border-radius: 30% 0 0 0 / 10% 0 0 0;
}
.left-hand:after,
.right-hand:after {
  left: -4px;
  right: -4px;
  background: currentcolor;
  top: 65%;
  height: 4px;
  border-radius: 10px;
  box-shadow: 0 -6px 0 currentcolor;
}
.left-hand:after {
  background: var(--tshirt1);
}
.left-hand-in,
.right-hand-in {
  background: inherit;
  border: 2px solid;
}
.left-hand-in {
  height: 62%;
  width: 34px;
  left: -8px;
  bottom: 90%;
  border-radius: 0 0 12px 12px;
  border-top: none;
  box-shadow: 0 -8px 0 var(--skin);
}
.finger-l {
  background: inherit;
  border: 2px solid;
  width: 10px;
}
.finger-l-1,
.finger-l-2,
.finger-l-3,
.finger-l-4 {
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
  right: auto;
}
.finger-l-1 {
  height: 20px;
  left: -2px;
  bottom: 100%;
}
.finger-l-2 {
  height: 24px;
  left: 6px;
  bottom: 110%;
  border-radius: 5px 5px 0 0;
}
.finger-l-3 {
  height: 34px;
  left: 14px;
  bottom: 110%;
  border-radius: 5px 5px 0 0;
}
.finger-l-4 {
  height: 11.6px;
  left: auto;
  right: -2px;
  bottom: 100%;
  border-radius: 5px 5px 0 0;
}
.finger-l-4:after {
  height: 16px;
  width: 10px;
  bottom: 100%;
  border-radius: 5px;
  left: -2px;
  background: inherit;
  border: 2px solid;
}
.right-hand-in {
  width: 18px;
  height: 50%;
  top: -2px;
  left: 60%;
  border-left: none;
  border-radius: 0 0 12px 0;
}
.right-hand-in:after {
  border-left: 2px solid;
  width: 0;
  height: 140%;
  top: 8px;
  left: 18px;
}
.right-hand-in:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: currentcolor;
  top: 7px;
  left: 15.5px; 
  z-index: 1;
  box-shadow: 0 4px 0 currentcolor, 0 12px 0 currentcolor,
    0 18px 0 currentcolor, 0 24px 0 currentcolor, 0 30px 0 currentcolor,
    0 36px 0 currentcolor, 0 42px 0 currentcolor, 0 52px 0 currentcolor;
}
.finger {
  position: relative;
  border: 2px solid;
  height: 10px;
  margin-top: -4px;
  border-radius: 5px;
  background: inherit;
  margin-left: 2px;
  box-shadow: -5px 5px 0 var(--shadow);
}
.finger-1 {
  border-left: none;
  border-radius: 0 5px 5px 0;
  margin-left: 5px;
  width: calc(100% + 2px);
  box-shadow: none;
}
.finger-2,
.finger-3 {
  width: calc(100% + 10px);
}
.finger-4 {
  width: calc(100% + 6px);
}

.breath {
  width: 200px;
  height: 200px;
  bottom: 76px;
  z-index: -1;
  box-shadow: inset 0 0 0 4px #483b5511;
  background: var(--bg);
  overflow: hidden;
  animation: bg 4 * $interval ease-in-out infinite;
  z-index: 1;
}
@keyframes bg {
  75%,
  100% {
    background: var(--bg);
  }
  25%,
  50% {
    background: #d2bda1;
  }
}
.inhale,
.exhale {
  width: 4px;
  height: 200px;
  background: var(--border);
  bottom: 0;
  z-index: 1;
}
.inhale {
  right: auto;
  transform: translateY(100%);
  animation: inhale 4 * $interval infinite linear;
}
.exhale {
  left: auto;
  transform: translateY(-100%);
  animation: exhale 4 * $interval 2 * $interval infinite linear;
}
.hold {
  width: 200px;
  height: 4px;
  background: var(--border);
  z-index: 1;
}
.hold1 {
  transform: translateX(-100%);
  animation: hold1 4 * $interval $interval infinite linear;
}
.hold2 {
  bottom: 0;
  transform: translateX(100%);
  animation: hold1 4 * $interval 3 * $interval infinite linear;
}

@keyframes inhale {
  25%,
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  51%,
  100% {
    opacity: 0;
  }
}

@keyframes exhale {
  25%,
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  51%,
  100% {
    opacity: 0;
  }
}

@keyframes hold1 {
  25% {
    transform: translateX(0);
    opacity: 1;
  }
  26%,
  100% {
    opacity: 0;
  }
}

.text {
  padding: 8px;
  animation: showhide 4 * $interval infinite;
  opacity: 0;
  font-size: 14px;
}
.hold1-text {
  animation-delay: $interval;
}
.hold2-text {
  animation-delay: 3 * $interval;
  right: 0;
  left: auto;
}
.exhale-text {
  right: 0;
  left: auto;
  animation-delay: 2 * $interval;
}
@keyframes showhide {
  1%,
  25% {
    opacity: 1;
  }
  28%,
  100% {
    opacity: 0;
  }
}

