$toms_blue: rgb(3, 63, 99);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$navajo_white: #FCEBC9;
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);

.bubble-breath-holder {
  width: fit-content;
  margin: auto;
  margin-top: 50px;
  color: $toms_blue;
  height: fit-content;
  min-height: 400px;
  // position: fixed;
  // z-index: 5389 !important;
}

.bubble-container {
  // position: fixed;
  // z-index: 5390 !important;
  top: 150px;
  //display: block;
  display: inline-block;
  width: 200px;
  margin:auto;
  min-height: 150px;
}

.bubble-breath-text {
  color: $bright-ornge;
  text-align: center;
  width: 200px;
  min-width: 200px;
  display: block;
  margin-top: 85px;
  // margin-left: 86px;
  font-weight: 900;
  font-size: 75%;
}

.bubble-breath-speed-opts {
  position: fixed !important;
  z-index: 5401 !important;
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100vw;
  left:0;
  top:450px;
  height:50px;
}

.bubble-breath {
  width: 200px;
  height: 200px;
  margin: auto;
  background: $blue-fade;
  border-radius: 100px;
  border-color: $toms_blue;
  border-width: 2px;
  border-style: solid;
  animation: breath 3s infinite alternate;
  box-shadow: $toms_blue 0px 0px 25px;
  border-radius: 50%;
  display: block;
  position: fixed;
  z-index: 5391 !important;
  top: 175px;
}

.bubble-breath-slow {
  animation: breath 5s infinite alternate;
}

.bubble-breath-fast {
  animation: breath 2s infinite alternate;
}

.breath-in-text::before {
  content: "BREATHE IN";
  z-index: 5392;
}



.breath-in-text-reg::before {
  content: "BREATHE IN";
  animation: BreathIn 6s infinite;
  z-index: 5392;
}

.breath-in-text-slow::before {
  content: "BREATHE IN";
  animation: BreathIn 10s infinite;
  z-index: 5392;
}

.breath-in-text-fast::before {
  content: "BREATHE IN";
  animation: BreathIn 4s infinite;
  z-index: 5392;
}






.bubble-breath-speed-text {
  position: fixed;
  z-index: 5404 !important;
  top: 150px;
  background-color: $navajo_white;
  display: block;
  animation: fadeOutAfterFirstRound 0.5s 1;
  -webkit-animation: fadeOutAfterFirstRound 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  height: 345px;
  min-height: 345px;
  border-radius: 35px;
  border-color: $ming-fade;
  font-size: 75%;
  font-weight: 900;
  margin: auto;
  padding: 15px;
  padding-right: 50px;
  padding-left: 50px;
  width: 100vw;
  left:0;
  text-align: center;
  color: $toms_blue;
}

@keyframes fadeOutAfterFirstRound {
  from {
    opacity: 1;
    z-index: 51;
  }

  to {
    opacity: 0;
    z-index: 0;
  }
}


@keyframes fadeInAfterFirstRound {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  99% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  100% {
    height: auto;
    width: auto;
    opacity: 1;
  }
}

@keyframes breath {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
    border-width: 10px;
  }
}

@keyframes BreathIn {

  0%,
  14% {
    opacity: 0;
    font-size: 100%;
  }

  15%,
  37% {
    opacity: 1;
    font-size: 175%;
    content: "BREATHE IN";
  }

  38%,
  67% {
    opacity: 1;
    content: "";
  }

  68%,
  100% {
    opacity: 1;
    content: "BREATHE OUT";
    font-size: 175%;
  }
}


@media only screen and (min-width:414px) {
  .bubble-breath-speed-opts{
    top:500px;
  }
}

@media only screen and (min-width:1024px) {
  .bubble-breath-speed-opts{
    width: 50vw;
    left:25vw;
  }

  .bubble-container {
    // width:33vw;
    // left:33vw;
  }
}


// @media only screen and (max-width:480px) {
//   .bubble-breath{
//     margin-left: 140px;
//   }
// }

// @media only screen and (max-width:414px) {
//   .bubble-breath{
//     margin-left: 107px;
//   }


// }



// @media only screen and (max-width:376px) {
//   .bubble-breath{
//     margin-left: 88px;
//   }
// }

// @media only screen and (max-width:322px) {
//   .bubble-breath{
//     margin-left: 61px;
//   }
// }



