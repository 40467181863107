$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen: #7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP: #BEB7DF;
$medPurple: #8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite: #FCEBC9;
$off-black: #282c33;
$totem: #9e2e0b;
$navajoLow: #f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white: #f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);

.checkout-lock-icon{
  width:15px;
  height: 15px;
  background-image: url("../../static/photos/languageIcons/lockINspiration.png");
  background-size: cover;
  position:relative;
  margin:auto;
  margin-top: 5px;
  margin-right: 5px;
}

.payment-successful-image{

}

.zenverseSliderHolder{
    width:100vw;
    max-width: 100vw;
}

.slick-dots li button:before {
    color: $ming-green !important;
    width:35px !important;
    height:35px !important;
    font-size: 15px !important;
    line-height: 25px !important;
    margin-top: 10px;
}
.slick-dots{
    height:50px;
    overflow:hidden;
}

.zen-bot-archive-chat{
    width:85vw;
    max-width: 500px;
    margin:auto;
    border-radius: 15px;
    background-color: $off-white;
    padding: 15px;
}
.zen-bot-chat-opener{
    text-align: center;
    color: $budd_orange;
    font-weight: 900;
    margin-bottom: 25px;
}

.zen-bot-chat-message{
    padding: 5px;
    width:65%;
    border-radius: 5px;
    margin-bottom: 10px;
    font-weight: 500;;

}
.zen-bot-chat-user{
    background-color: $ming-fade;

}
.zen-bot-chat-message-owner{
    font-weight: 900;
}
.zen-bot-chat-zenbot{
    background-color: $blue_fade;
    margin-left: 32%;
}
.gwtw-option-pair{
    background-color: $navajoWhite;
    height:25vh;
    margin-top: 0;
    margin-bottom: 0;
}

.gong-option{
    width:40vw;
    min-width:150px;    
    max-width:250px;    
    border-radius:35px;
    background-color: $ming-fade;
    overflow: hidden;
    box-shadow: $primary_black 0px 0px 5px;
    margin:auto;
    margin-top:45px;
    margin-bottom:20px;
}


.slick-current{
    .gong-option{
        background-color: $ming-green;
    }
}

  
// .primary-gong-option{
//     border-color: $sage;
//     background-color: $sage-fade;
// }

// .slick-current{
//     .primary-gong-option{
//         background-color: $sage;
//     }
// }

// .finish-gong-option{
//     background-color: $blue_fade;
// }

// .slick-current{
//     .finish-gong-option{
//         background-color: $toms_blue;
//     }
// }

.gong-label{
    border-radius:5px;
    border-style:solid;
    border-width:1px;
    border-color:$toms_blue;
    width:fit-content;
    margin:auto;
    padding:6px;
    color:$budd_orange;
    box-shadow: $primary_black 0px 0px 5px inset, $primary_black 0px 0px 5px;
    font-size:70%;
    color:$toms_blue;
    font-weight: 900;
}

.chooseGongLabel{
    background-color: $sage-fade;
}
.choosenGongLabel{
    background-color: $sage;
}


.previewGongLabel{
    background-color: $navajo_white;
}




.gong-option-image{
    width:100px;
    height:100px;
    margin:auto;
    display:block;
    border-radius: 35px;
    box-shadow: $primary_black 0px 0px 5px inset, $primary_black 0px 0px 5px;
    margin-top:15px;
    margin-bottom: 5px;
}

.chosen-gong-image{
    width:80px;
    height:80px;
    margin-left:25px;
}

.gwtw-option-header{
    background-color: $navajoWhite;
    height:20vh;
    margin-top: 0;
    margin-bottom: 0;
}
.gwtw-option{
    color: $toms_blue;
    margin: auto;
    text-align: center;
    font-family: Okashi;
    max-width: 125px;
  }

.checkout-stripe-logo{
  width:100px;
  height: 75px;
//   margin-left: 10px;
//   margin-right: 5px;
margin:auto;
  background-image: url("../../static/photos/stripe.png");
  background-size:contain;
  background-repeat: no-repeat;
  position:relative;
  margin-top: -20px;
}

::-webkit-scrollbar {
    width: 1px;
  }

  .zenergyPayValue{
    color:$toms_blue;
    font-size:150%;
    font-weight: 900;
    top: 2px;
    position: relative;
  }
.zenverseBrowser {
    position: fixed;
    top:0px;
    width:100%;
    height:100%;
    background-color: $navajoWhite;
    background-size: cover;
    display:flex; flex-direction:row;
    overflow-y: scroll;
    padding-top: 0px;
}

.zen-payment-form{
    background-color: $navajoLow;
    max-width:325px;
    margin:auto;
    margin-bottom: 35px;
    font-size: 150%;
    padding:15px;
    border-radius: 5px;
    border-color: $ming-fade;
    border-style: solid;
    border-width: 3;
    border-bottom-width: 7px;
    border-top-width: 7px;
}


.zenCardElementComplete{
    background-color: $sage-fade;
    border-color: $sage;
}

.zenCardElementEmpty{
    background-color: $navajo_deep_fade;

}
.zenCardElementInvalid{
    background-color: $budd-fade;
    border-color: $totem;
}

.demo-time-remaining-display{
    position:fixed;
    z-index:999999;
    top:7px;
    width:fit-content;
    right:21px;
    border-radius: 5px;
    color:$totem;
    display:flex;
    flex-direction: row;
    background-color: $navajoWhite;
    border-style:solid;
    border-width:1px;
    border-color:$off-black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px inset, rgba(0, 0, 0, 0.35) 0px 0px 5px;
    padding-left:5px;
    padding-right:5px;
    min-width:175px;
    margin:auto;
    font-size:90%;
    text-align:center !important;
}

.zen-portal-demo-browser{
    top:0;
    background-color: $navajoWhite;
    position:fixed;
    height:100vh;
    max-height: 100vh;
    min-height: 100vh;
    padding-top:100px;
    z-index: 9999999999999999999999;
}

.budd-backed{
    background-color: $budd_orange;
}

.zen-bot-home{
    width:100vw;
    height:100vh;
    background-color: $navajoWhite;
    padding-top:115px;
    position: fixed;
    top:0;
}

.buddha-bot-answer{
    background-color: $off-white;
    width:80%;
    box-shadow: $off-black 0px 0px 15px, inset $off-black 0px 0px 5px;
    margin:auto;
    max-width: 80vw;
    padding:15px;
    border-radius:15px;
    margin-bottom:25px;
    
}

.zenbot-sb-corner{
    width:32px;
    height:32px;
    transform: rotate(45deg);
    background-color: $off-white;
    float:left;
    margin-left:25%;
    // border-width: 0 4px 4px 0;
    // border-style: solid;
    // border-color: $off-black;
    box-shadow: $off-black 7px 7px 5px;
    margin-top: -4px;
}

.zenbot-text-input {
    background-color: #f2e7d2;
    border-style: solid;
    border-width: 0;
    border-left-width: 10px;
    border-radius: 5px;
    border-color: $budd-fade;
    display: block;
    margin: auto;
    text-align: center;
    margin-top: 15px;
    width: 80vw;
    height: 75px;
    line-height: 50px;
    font-size: 20px;
    max-width: 500px;
  }


.zenscape-container{
    // margin:auto;
    // display:block;
    // position:relative;
    // background-color: pink;
    // padding-top: 5px;
}

.zenscape-practice-portal-holder{
    margin-top:150px;
}

.zenscape-demo-portal-holder{
    margin-top:-125px;
}

.ppzc-controls-provided{
    padding-top:100px;
}

.zenverseHomeIcon{
    background-image: url('../../static/photos/languageIcons/home.png');
   background-size: cover;
    height: 150px;
    width: 150px;
    display: inline-block;
    
}

.zen-side-bar-message{
    width:fit-content;
    text-align: center;
    margin: auto;
    font-weight: 900;
    font-size: 125%;
    color:$primary_black;
    margin-top:25px;
}

.zen-side-bar-loader{
    display:inline-block;
    margin:auto;
    margin-top:25px;
    height:100px;
    width:75px;
}
.helper-icons{
    display: none;
}

.ohmContainer{
    width:100vw;
    top:0;
}
.zenscape-preview-overview{
    max-height: 100px;
}
.slider-holder{
    width: 85vw;
    margin:auto;
    height: 60px;
    margin-bottom: 25px;;
}

.ohm-track{
    background-color: $toms_blue;
    height: 4px;
    margin-top: 24px;
    border-radius: 2px;
    margin-left: 50px;
    margin-right: 50px;
}

.ohm-thumb:hover{
    box-shadow: $toms_blue 0px 0px 45px;
}
.purple-ohm-thumb{
    background-image:url("../../static/photos/PurpleOhm.png");
}

.orange-ohm-thumb{
    background-image:url("../../static/photos/OrangeOhm.png");
}

.blue-ohm-thumb{
    background-image:url("../../static/photos/BlueOhm.png");
}

.ascend-thumb{

    background-image:url("../../static/photos/ascend.png");
}

.kathakaku-thumb{
    background-image:url("../../static/photos/cybercitysqaure.png");
}
.chakra-thumb{

    background-image:url("../../static/photos/medHead.png");
}

.ohm-thumb{
    background-size: contain;
    border-radius: 50%;
    width:50px;
    height: 50px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;
}


.zenscape-stream-preview {
    background-color: $navajoWhite;
    background-size: cover;
    width:100%;
    padding-top:10px;
    max-width: 100%;
    padding-bottom: 20px;
    
}

.ohmBoard {
    position: fixed;
    width:100vw;
    min-width:100vw;
    height:100vh !important;
    min-height:100vh;
    max-height:100vh;
    margin-top:175px;
    top:0;
    display:block;
    z-index: 1;
}

.stopPortalCross{
    float:right;
    margin-top:-150px;
    margin-right:10px;
    width:60px;
    height:60px;
    background-size: cover;
    background-image: url("../../static/photos/languageIcons/deleteIcon.png");
}

.zenscape-jsx{
    display:block;
    min-width: 100vw;
    margin-top:0px;
    z-index:99999999999999 !important;
    padding-top: 25px;
    height:100vh;
    min-height: 300px;
}

.budd-backed{
    background-color: $budd_orange;
}
.NOJSX{
    position:fixed;
    min-width: 100vw;
    min-height: 100vh;

}
.OhmSidebarHolder{
    position:fixed;
    //z-index:99999999999999999999999999 !important;
    display:inline-block;
    overflow: visible;
    //bottom: 150px !important;
    padding-bottom:0px;
    width:100vw;
    height: 205px;
    bottom:0;
    min-width: 100vw;;
    background-color: $toms_blue;
}
.OhmSidebar{
    display:flex;
    flex-direction: row;
    padding-top:13px;
    background-color: $toms_blue;
    height:90px;
    width:100vw;

}


.zenscapeContainer {
    width: 100%; 
    padding-top:105px;
    position:fixed;
}

.zenverseSidebar {
    position: fixed;
background-color: $budd_orange;
box-shadow: black 0px 0px 25px;
max-height:110px;
min-height: 100px;
bottom: 0;
width: 100%;
max-width: 100vw;
padding-left:10px;
padding-right:10px;
z-index:101;
}

.zenverseSidebarBlendMode{
    box-shadow: none;
}

.ohm-home-image:hover{
    width:80px;
    height:80px;
    margin-top:5px;
    margin-left: -5px;
}

.ohm-home-image{
    display: block;
    margin: auto;
    background-color: $budd_orange;
    width:70px;
    height:70px;
    background-image: url('../../static/photos/ohmforhomeblue.png');
    background-size: cover;
    float: left;
    margin-top: 10px;

    
}

.zenverseCatagoriesHolder{
    width: 100%;
    padding-top:15px;
}

.zenverseFiltersHolder{
    margin: auto;
    width:100vw;
    max-width: 450px;
    padding-top:95px;
    height: fit-content;
    display: block;;
}

.portal-icon {
    background-color: $budd_orange;
    border-radius: 50%;
    width:80px;
    height:80px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
    display: inline-block;
    margin-right: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.practice-portal-icon{
    border-color: $navajo_fade;
    border-width: 4px;
}
.practice-portal-icon-playing{
    border-color: $sage;
    border-width: 4px;
}
.portal-icon:hover {
 
    border-color: $toms_blue;
    box-shadow: $toms_blue 0px 0px 25px;
    cursor:pointer;

}


.portal-icon-image {
    width:80px;
    height:80px;
    margin-top:0px;

}

.zen-sound-icon {
    border-radius: 50%;
    width:60px;
    height:60px;
    margin-bottom:35px;
    border-color: $ming-green;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    box-shadow: $ming-fade 0px 0px 25px;
    margin-left:8px;
    z-index:519;
    // border-radius: 50%;
    // width:90px;
    // height:90px;
    // margin-bottom:35px;
    // border-color: $primary_black;
    // border-style: solid;
    // border-width: 3px;
    // overflow: hidden;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;
    // margin-left:8px;
    // z-index:519;
}
.zen-sound-icon:hover {
    border-color: $toms_blue;
    box-shadow: $toms_blue 0px 0px 25px;
    cursor:pointer;

}


.zen-sound-icon-image {
    width:70px;
    height:70px;
    margin-left:-5px;
    margin-top:-5px;
    
}

.shop-icon {
    margin: auto;
    background-color: $budd_orange;
    border-radius: 50%;
    width:140px;
    height:140px;
    margin-bottom:35px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;

    margin-top:5px;
}

.shop-icon:hover {
 
    border-color: $toms_blue;
    box-shadow: $toms_blue 0px 0px 25px;
    cursor:pointer;

}


.shop-image {
    width:140px;
    height:140px;
    background-size: cover;
    
}

.guide-icon {
    overflow: hidden;
    display: inline-block;
    position: relative;
    margin-bottom: 25px;
    margin-left: 5px;
    margin-right: 5px;
    width:90px;
    height:90px;
}

.guide-icon:hover {
    width:100px;
    height:100px;
    margin-bottom:20px;
    margin-left:0px;
    margin-right:0px;
}

.guide-image {
    width:100%;
    height:100%;
    
}

.zenCarousel{
    background-color:none;
}

.zenverse-search-bar{
    border-radius: 10px;
    border-style: solid;
    border-color: $primary_black;
    border-width: 2px;
    margin-top:10px;
    background-color: #f2f3f5;
    color:$primary_black;
    padding-left:10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
    width:80%;
    margin-left: 5%;
    
}


.zenscape-preview-controls{
  display:flex; 
  flex-direction:row;
  width: 270px;
  max-width: 300px;
  margin: auto;
}

.portal-preview-image {
    width:100%;
    border-radius: 50px;
    object-fit: cover;
    border-radius: 50px;
    overflow: hidden;
    max-height:225px;
    max-width:275px;
    display: block;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
    border:solid;
    border-width: 2px;
    border-color: $primary_black;
}

.portal-preview-image-small{
    max-height:150px;
    max-width:175px;
}

.zenscape-preview-title{
    width:100%;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 15px;

}
.zenscape-yt-video{
    width:95%;
    max-width:430px;
    margin:auto;
    overflow: hidden;
    height:fit-content;
    border-color: $primary_black;
    border-style: solid;
    border-width: 2px;
    box-shadow: $primary_black 0px 0px 25px;
    margin-top:75px;
    margin-bottom: 50px;
    border-radius: 15px !important;
    overflow:hidden;
    background-color: rgba(0, 0, 0, 0.35);

}

.zenscape-preview-desc{
    text-align: center;
    font-size: 90%;
    width: 80%;
    margin: auto;
    margin-top: 10px;
}


.okashi{
    font-family: Okashi;
}

.zenK{
    font-family: ZenKurenaido;
}

.portal-enlarged-image{
    width:900px;
    height:600px;
    margin:auto;
    margin-top:40px;
    border-radius: 50px;
    border-color: $primary_black;
    border-style: solid;
    border-width: 6px;
}

.zenverseSidebarIcons{
    width:100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.zenscape-preview {
    width: 100vw;
    padding-top: 0px;
    margin:auto;
}


.zenportal-carousel{
    height:100vh;
    width:100vw;
    max-width:100vw;
    position: fixed;
    top:90px;
    //background-color: $navajoWhite;
}

.zen-portal-slider{
    height:100vh;
    min-height: 100vh;
    max-width:100vw;
    position:fixed;
    overflow-x: hidden;
}

@font-face {
    font-family: 'ZenKurenaido';
    src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

  }

  @font-face {
    font-family: 'Okashi';
    src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

  }

  @font-face {
    font-family: 'NashiMurah';
    src: local('NashiMurah'), url(../../static/fonts/Zen_Kurenaido/NashiMurah.otf) format('opentype');

  }

  @media (max-width:376px) {

    zenscape-jsx{
        padding-top: 5px;
    }
.portal-preview-image {
    max-height:185px;
    max-width:225px;
}

.portal-preview-image-small{
    max-height:145px;
    max-width:160px;
}
.portal-icon {
    width: 75px;
    height: 75px;
    margin-top: 10px;

}
.portal-icon-image {
    width:75px;
    height:75px;
    margin-top:0px;

}
.zenverseSidebar{

max-height:100px;
}
  }


  @media only screen and (min-width:410px) {
  .gong-option{
    width:50vw;
    min-width:150px;    
    max-width:300px;  
    margin-top:100px;
  }
}
  @media only screen and (min-width:480px) {

    .zenverseSidebar {
    max-height:150px;
    }
    .portal-icon {
        width:100px;
        height:100px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .portal-icon-image {
        width:100px;
        height:100px;
        margin-top:0px;
    
    }
    
    // .zenscape-preview {
    //     width:400px;
    //     height:470px;
    //     padding-top: 30px;
    //   }
      .zenscapeContainer {
        width:100%;
    }

    .zenscape-preview-title-mobile{
        display: none;
    }
    .zenscape-preview-title{
        display: block;
    }

.portal-preview-image {
    max-height: 200px;
}

.portal-preview-image-small{
    max-height:150px;
    max-width:190px;
}
  }


@media only screen and (min-width:1024px) {

.OhmSidebar{
    padding-left: 200px;
    max-height:125px;
}
.OhmSidebarHolder{
    max-height:125px;
}

.ohmContainer{
    padding-left:150px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin:auto;
    width:50%;
    padding-top:-25px;
    height:250px;
}
    .zenportal-carousel{
    }
    
    .zen-portal-slider{
        padding-left: 200px;
    }
    .zenverseSidebar {
        background-color: $budd_orange;
        width:150px;
        overflow-y: scroll;
        box-shadow: black 0px 0px 25px;
        display: absolute;
        white-space: normal;
        top: 0;
        overflow-x: auto;
        padding:0;
        height:100vh;
        min-height:100vh;
        padding-top:25px;
    }

    .zenscape-preview {
      width:400px;
      height:470px;
      background-color: $ming-fade;
      border-radius: 35px;
    }

    .zenverseSidebarIcons{
        width:100%;
        overflow-x: auto;
        overflow-y: scroll;
        min-height: 100%;
        max-height: 100%;
        white-space: nowrap;
    }

    .portal-icon {
        width:100px;
        height:100px;
        margin:auto;
    margin-bottom:5px;
    margin-top: 20px;
    display: block;
    position: relative;
    }
    .portal-icon-image {
        width:150px;
        height:115px;
        margin-left:-25px;
        margin-top:-5px;
        object-fit: contain;
        
    }
    
.slick-current{
    .zenscape-preview {
        width:400px;
        height:470px;
        background-color: $ming-green;
      }
    }
}


  @media only screen and (min-width:1200px) {
    .ohm-track{
        background-color: $toms_blue;
        height: 4px;
        margin-top: 48px;
        border-radius: 2px;
        margin-left: 50px;
        margin-right: 50px;
    }
    .ohm-thumb{
        background-size: contain;
        border-radius: 50%;
        width:100px;
        height:100px;
        border-color: $primary_black;
        border-style: solid;
        border-width: 3px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 25px;
    }
    .slider-holder{
        width: 800px;
        margin-left: 60px;
        height: 100px;
        margin-bottom: 25px;;
    }
    


    //   .OhmSidebar{
    //         width:140px;
    //         height:100vh;
    //         top:0;
    //         display: flex;
    //         flex-direction: column;
    // }
    .zen-sound-icon{
        margin: auto;
        margin-bottom: 15px;
    }


    .zenverse-located-sidebar{
        top:50px;
        padding-top:60px;

    }
    .helper-icons{
        display: block;
    }
    .ohm-home-image{
        display: block;
        margin: auto;
        width:140px;
        height:140px;
        margin-bottom:35px;
        background-size: cover;
        margin-left:25px;
        
    }
    .ohm-home-image:hover{
        width:160px;
        height:160px;
        margin-bottom:25px;
        margin-top:-10px;
        margin-left:10px;
    }


    .guide-icon {
        float: none;

    width:140px;
    height:140px;
    margin-top:5px;
    margin-bottom:35px;
    margin-left:30px;
    }
    .guide-icon:hover {
        width:150px;
        height:150px;
        margin-top:0px;
        margin-bottom:30px;
        margin-left:25px;
    }



}

  