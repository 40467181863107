$buddhas-robe: #033f63; //F37043
::-moz-selection{background:$buddhas-robe;color:#FFFFFF}
::selection{background:$buddhas-robe;color:#FFFFFF}
.clearfix{display:inline-block}
.clearfix::after{content:"";visibility:hidden;display:inline-block;font-size:0;clear:both;height:0}
.tekst{color:#363635;font-family:Arial,sans-serif;text-transform:uppercase;font-size:14px;line-height:28px;font-weight:bold;display:block;letter-spacing:2px;max-width:100%;text-align:center;position:absolute;bottom:20px;left:30px;z-index:51}
a{color:$buddhas-robe;text-decoration:none;cursor:pointer}
a:hover{text-decoration:underline}
.outer{display:table;margin:0 auto;height:100%}
.middle{display:table-cell;vertical-align:middle}
.main{position:relative;height:95px;margin:18px}
@media (max-width:275px){.main{-webkit-transform:scale(0.75);transform:scale(0.75)}}
@media (max-width:220px){.main{-webkit-transform:scale(0.5);transform:scale(0.5)}}
.buddha{position:relative;width:64px;height:95px;-webkit-animation:animUpDown 3s ease-in-out infinite;animation:animUpDown 3s ease-in-out infinite}
.buddha .legs{position:absolute;bottom:0;left:0;width:64px;height:25px;border-radius:25px;}
.buddha .tail{position:absolute;bottom:5px;left:8px;width:49px;height:41px;border-radius:19px;}
.buddha .head{position:absolute;top:0px;left:1px;width:62px;height:62px;border-radius:62px;background:#F2BC9B}
.buddha .ears{position:absolute;bottom:14px;width:14px;height:14px;border-radius:14px;background:#F2BC9B}
.buddha .ears.left{left:-5px}
.buddha .ears.right{right:-5px}
.buddha .hand{position:absolute;bottom:12px;left:50%;width:17px;height:17px;border-radius:17px;background:#F2BC9B;-webkit-transform:translateX(-50%);transform:translateX(-50%)}
.buddha .mouth{position:absolute;bottom:12px;left:50%;width:8px;height:3px;margin-left:-6px;border:2px solid #F05978;border-top:0;border-radius:0 0 10px 10px / 0 0 8px 8px}
.buddha .circle{position:absolute;top:22px;left:50%;width:6px;height:6px;border-radius:6px;background:#F05978;-webkit-transform:translateX(-50%);transform:translateX(-50%)}
.buddha .eye{position:absolute;bottom:25px;width:5px;height:2px;border:3px solid #363635;border-bottom:0;border-radius:5px 5px 0 0 / 5px 5px 0 0}
.buddha .eye.left{left:12px}
.buddha .eye.right{right:12px}
.shadow{position:absolute;bottom:-20px;display:block;width:64px;height:25px;background:rgba(0,0,0,0.2);border-radius:50%;-webkit-animation:animShadow 3s ease-in-out infinite;animation:animShadow 3s ease-in-out infinite}
@-webkit-keyframes animShadow{0%,100%{-webkit-transform:scale(1) rotateX(100deg);transform:scale(1) rotateX(100deg)}50%{-webkit-transform:scale(0.7) rotateX(100deg);transform:scale(0.7) rotateX(100deg)}}
@keyframes animShadow{0%,100%{-webkit-transform:scale(1) rotateX(100deg);transform:scale(1) rotateX(100deg)}50%{-webkit-transform:scale(0.7) rotateX(100deg);transform:scale(0.7) rotateX(100deg)}}
@-webkit-keyframes animUpDown{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-12px);transform:translateY(-12px)}}
@keyframes animUpDown{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-12px);transform:translateY(-12px)}}
.main:hover .buddha .head{-webkit-animation:animTopBottom 0.25s infinite;animation:animTopBottom 0.25s infinite}
@-webkit-keyframes animTopBottom{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-2px);transform:translateY(-2px)}}
@keyframes animTopBottom{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-2px);transform:translateY(-2px)}}


.choosePrimaryCol{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
}
.choosePrimaryColActive{
    border-width:2px;
    border-color:black;
    border-style: solid;
}
.chooseBlue{
    background-color: #033f63; 
}
.chooseOrange{
    background-color: #FFBC42; 
}

.chooseLRed{
    background-color: #D1603D; 
}