
$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen:#7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP:#BEB7DF;
$medPurple:#8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite:#FCEBC9;
$off-black:#282c33;
$totem:#9e2e0b;
$navajoLow:#f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white:#f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);

.gwtw-countdown{
  width:175px;
  height:175px;
  margin:auto;
  text-align: center;
  margin-top: 100px;
  font-size: 300%;
  line-height: 175px;
  color:$toms_blue;
  border-radius: 50%;
  border-color: $toms_blue;
  border-width: 1px;
  box-shadow: $toms_blue 0px 0px 5px, $toms_blue 0px 0px 5px inset;
}