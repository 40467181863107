$primary_black: #272727;
$toms_blue: rgb(3, 63, 99);
$ming-green: #28666E;
$ming-fade: rgba(40, 102, 110, 0.7);
$blue_fade: rgba(3, 63, 99, 0.7);
$bright-ornge: #FFBC42;
$gold-fade: rgba(255, 188, 66, 0.7);
$sage: #B5B682;
$dGreen: #7c9885;
$budd_orange: #D1603D;
$budd-fade: rgba(209, 96, 61, 0.7);
$maxBP: #BEB7DF;
$medPurple: #8A84E2;
$navajo_white: #FEDC97;
$navajo_fade: rgba(254, 220, 151, 0.7);
$navajo_deep_fade: rgba(254, 220, 151, 0.5);
$navajoWhite: #FCEBC9;
$off-black: #282c33;
$totem: #9e2e0b;
$navajoLow: #f2e7d2;
$navajoLow-fade: rgba(242, 231, 210, 0.7);
$off-white: #f7eddc;
$sage-fade: rgba(181, 182, 130, 0.7);

.offwT{
  background-color: $off-white;
}
.sageGlowButton {
  background-color: $sage !important;
  color: $primary_black;
}
.goodNewsHeader{
  color:$bright-ornge;
}
.mGlowButton {
  background-color: $ming-green;
  color: $bright-ornge;
}
.practitionerReadingList{
  padding-top:125px;
  width:350px;
  margin:auto;
}

.zen-reel-slider-item-holder{
  max-width: 90%;
  border-radius: 25px;
  overflow: hidden;
}


.reelStream{
  width:100vw;
  max-width:100vw;
  min-height: 100vh;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top:60px;
  background-color: $navajo_white;
}


.content-reccomendation-full-holder{
  background-color: $ming-green;
  border-radius: 25px;
  width:90%;
  margin:auto;
  padding:15px;
  padding-top:25px;

  h3{
    color:$bright-ornge !important;
  }

  h2{
    color:$navajo_white;
  }

  h4{
    color:$off-white;
  }
}

.followPractitionerButton{
  position:fixed;
  top:145px;
  left:15px;
}

.reelStreamColumn{
  width:50%;
  max-width:50vw;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  padding-top:10px;
  //background-color: $ming-fade;
}
.reelSteamSpacer{
  width:100%;
  background-color: $ming-fade;
  height:65px;
  font-family: Okashi;
  text-align: center;
  border-color: $ming-green;
  border-style: solid;
  border-width: 3px;
  color:$bright-ornge;
}

.content-reccomendation-full-holder{
  margin-top: -25px;

  h3{
    color:$toms_blue;
    font-weight: 900;
    margin-bottom: 0;
  }

  h2{
    margin-top:0;
  }
}
.zen-demo-video-player{
  position:fixed;
  width:100vw;
  height:100vh;
  min-height: 100vh;
  top:0;
  padding-top:200px;
  background-color: rgba(39, 39, 39, 0.8);
}

.zen-subscriptions-slider {
  width: 100vw;
  max-width: 100vw;
}
.nuggetSliderPreview{
  .nugget-image{
    width: 130px;
    max-height: 130px;
    object-fit: cover;
    display: block;
    margin: auto;
    bottom: 0;
    box-shadow: $primary_black 0px 0px 15px, $primary_black 0px 0px 15px;
    border-radius:15px;
    margin-top:20px;
  }

  h2{
    text-align: center;
    padding-top: 0;
    margin-top: 0;
    color:$bright-ornge;
    font-size:18px;
  }
  h3{
    text-align: justify;
    padding-left:15px;
    padding-right:15px;
    font-size:13px;
  }
}

.orderItemsCheckoutBanner{
    width:90%;
    background-color: $blue_fade;
    min-width:320px;
    max-width:375px;
    text-align: center;
    margin:auto;
    padding:10px;
    padding-bottom:7px;
    padding-top:7px;
    border-radius:5px;
    height:fit-content;
    color:$off-white;
    text-align: center;
    margin-bottom: 15px;
    border-color: $toms_blue;
    border-width: 0;
    border-style: solid;
    border-top-width: 5px;
    border-bottom-width: 5px;
}

.mediaQuotedText{
  font-style: italic;
  font-weight: 900;
  font-size: 150%;
  color: $primary_black;
}

.viewAsGeneralUserLink{
  position:fixed;
  top:145px;
  left:10px;
  display:flex;
  flex-direction: row;
  background-color: $ming-fade;
  color:$navajoWhite;
  width: fit-content;
  margin:auto;
  padding:3px;
  padding-top: 0;
  padding-left:5px;
  padding-right:5px;
  border:$toms_blue;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 7px;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px;
}


.zenPractitionerBlogLink{
  display:flex;
  flex-direction: row;
  background-color: $ming-fade;
  color:$navajoWhite;
  width: fit-content;
  margin:auto;
  padding:3px;
  padding-top: 0;
  padding-left:5px;
  padding-right:5px;
  border:$toms_blue;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 7px;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px;
}



.zenResourcePageSection{
  background-color:$navajo_white;
  padding-top:15px;
  padding-bottom:25px;
  max-width: 90%;
  margin:auto;
  margin-bottom: 35px;
  padding-left:35px;
  padding-right:35px;
  h1{
    font-family: Okashi;
  }
}
.accountTypesText{
  h1{
    font-family: 'ZenKurenaido';
  }
  h3{  
  text-align: left;
  }
}

.getStartedResourcePageSection .accountTypesText{
  h1{
    font-size: 125%;
  }
  h2{
    font-size:110%;
  }
}
.accountTypesSection{
  h4{
    text-align: justify;
  }
  
  h3{
    text-align: center;
  }
}

.nextStepsSection{
  background-color: $gold-fade;
}

.howToPostSection{
  background-color: $ming-fade;
}

.getStartedResourcePageSection{
  background-color:$sage-fade;
}

.howToSignUpSection{
  background-color: $blue_fade;
}

.resourcePageContents{
  margin:auto;
  background-color: $off-white;
  border-radius: 10px;
  min-width:315px;
  width:90%;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  h3{
    font-size:86%;
    font-family: Okashi;
  }
  h2{
    font-size:95%;
    margin-left: 25px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
  }

  h4{
    font-size:75%;
    margin-left: 50px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}


.practitionerWelcomeText{
}

.pbLink{
 display: block;
 position: relative;
 margin-top: 5px;
 margin-right: 5px;
 
}
.ecoPledgeBanner{
  background-color: $sage-fade;
  width:300px;
  display:flex;
  flex-direction: row;
  text-align: center;
  margin:auto;
  padding:10px;
  padding-bottom:7px;
  padding-top:7px;
  border-radius:5px;
  height:100px;
  margin-bottom: 15px;
  border-color: $sage;
  border-width: 0;
  border-style: solid;
  border-left-width: 5px;
  border-right-width: 5px;
}

.scrollActivateBanner{
  cursor: pointer;
}

.xLinkButton{
  
  border-radius:5px;
  background-image: url("../../static/photos/TwitterX.png");
  background-size: contain;
  background-repeat: no-repeat;
  width:60px;
  height:60px;
  margin:auto;
}

.instaButton{
  
  border-radius:5px;
  background-image: url("../../static/photos/instaLogoButton.png");
  background-size: contain;
  background-repeat: no-repeat;
  width:60px;
  height:60px;
  margin:auto;
}
.ecoFriendlyBannerIcon{
  border-radius:5px;
  background-image: url("../../static/photos/shop/eco-pledge.png");
  background-size: contain;
  background-repeat: no-repeat;
  width:80px;
  height:75px;
  margin:auto;
}

.fairTradePledgeIcon{
  
  background-image: url("../../static/photos/shop/ft.png");
}
.packagingPledgeIcon{
  background-image: url("../../static/photos/shop/fsc.png");
}

.ecoFriendlyPledgeIcon{
  background-image: url("../../static/photos/shop/eco-pledge.png");
}

.ecoFriendlyBannerText{
  margin:auto;
  width:200px;
}
.deleteLink{
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  background-size: contain;
  width:20px;
  height:20px;
  border-radius: 50%;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px;
  margin-left: 8px;
}

.deleteReccLink{
  
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  background-size: contain;
  width:25px;
  height:25px;
  border-radius: 50%;
  background-color: $off-white;
}

.deletePracticeIcon{
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  background-size: contain;
  width:25px;
  height:25px;
  border-radius: 50%;
  background-color: $off-white;

}
.stopDemoButton{
  float:right;
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  background-size: contain;
  width:40px;
  height:40px;
  margin-right:55px;
  background-color: $navajoLow;
  border-radius: 50%;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px;
}
.zenBasketItemRemove{
  float:right;
  margin-right:15px;
  margin-top:15px;
}

.viewProfileAsUserLink{
  margin:auto;
  color:$toms_blue;
  text-align: center;
}

.compHouseLogo{
  width:125px;
  height:125px;
  background-image: url("../../static/photos/home/CompaniesHouse.png");
  background-size: contain;
}
.zen-sub-card-perks-list {
  font-size: 75%;
  text-align: justify;
}

.bottomNextButtonHolder{
  width:100vw;
  box-shadow: $primary_black 0px 0px 8px;
  height:140px;
  margin-top: 0;
  padding-top:35px;
  position: fixed;
  bottom: 0;
  background-color: $ming-green;
}

.tomBlueText{
  color:$toms_blue;
}
.bottomNextButton{
  margin:auto;
  width:125px;
  background-color: $toms_blue;
  border-radius:10px;
  color:$bright-ornge;
  line-height: 30px;
  height:30px;
  padding:0;
}
.practitionerRecListHolder{
  max-height:75vh;
  overflow-y: scroll;
  padding-top: 10px;
  margin-bottom: 0;
// border-width: 0;
//   border-bottom-width: 3px;
//   border-style: solid;
//   border-color: $toms_blue;
}

.zen-image-option{
  width:175px;
  height:125px;
  margin:auto;
  margin-bottom: 15px;
}

.zen-image-option-image{
  width:125px;
  height:125px;
  display: block;
  margin:auto;
  object-fit: contain;
  border-radius: 5px;
  border-color: $primary_black;
  border-style: solid;
  border-width: 2px;
}

.zen-image-option-chosen{
  border-width: 5px;
  border-color: $sage;
}
.zenTermsAndConditions{
  h1{
    font-size: 105%;
  }
  h2{
    font-size: 80%;
    font-weight: 900;
    color:$ming-green;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h4{
    text-align: justify;
  }
  h3{
    text-align: justify;
  }
}

.zenPractitionerTermsAndConditions{

  padding-top:100px;
  padding-bottom:50px;
  h1{
    font-size: 105%;
    margin-bottom: 0;
  }
  h2{
    font-size: 80%;
    font-weight: 900;
    color:$ming-green;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h4{
    text-align: justify;
  }
  h3{
    text-align: justify;
  }

  .prcsNotice{
    color:$totem;
    text-align: center;
  }

  .tcsDate{
    font-size:90%;
    margin-top:0;
  }
}
.subscription-expiry-notice{

  background-color:$totem;
  color:$off-white;
  border-radius:10px;
  padding:8px;
  text-align: center;
  border-color: $off-black;
  border-style: solid;
  border-width: 2px;
  width:80%;
  margin:auto;

}

.zen-sub-card-perks-list{
  padding: 0;
  list-style-image: url('../../static/photos/tinyWhiteLogo.png');
  max-width: 80%;
  line-height: 15px;
  margin:auto;
}
.zen-content-list-item-holder{
  margin-bottom:20px;
}

.zen-sub-card-perks-list li{

margin-top: 5px;
}

.zen-sub-card-perks-list {
.innerText{
  position:relative !important;;
  top: -3px !important;
}}

.logoUpperLeft{
  position:absolute;
  top:15px;
  left:15px;
  width:55px;
  height:55px;
  background-image: url("../../static/photos/zencyclogo.png");
  background-size: contain;

}

.logoUpperRight{
  position:absolute;
  top:15px;
  right:15px;
  width:55px;
  height:55px;
  background-size: contain;
}

.shopBasketItemPreview{
  width:300px;
  margin:auto;
  margin-top:10px;
  background-color: $navajo_white;
  min-height:100px;
  border-radius:15px;
  box-shadow: $off-black 0px 0px 5px inset, $off-black 0px 0px 5px;
  padding:5px;
}

.shopBasketItemPreviewPhoto{
  width: 70px;
  height: 70px;
  min-height: 70px;
  min-width: 70px;
  margin: auto;
  position:relative;
  margin-top:15px;
  margin-left:15px;
  background-position: center;
  height: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: $primary_black 0px 0px 5px;
  border-radius:5px;
}

.zenBasketItemRemove{

}

.shopBasketItemPreviewDetails{
  width:150px;
  margin:auto;
  text-align:center;
}


// .zen-sub-card-perks-list li{
//   background-image: url("../../static/photos/zencyclogo.png");
//   background-position: left center;
//   background-size: 26px 26px;
//   line-height:1.3em;
//   margin: .25em 0;
//   padding-left:15px;
//   background:url('../../static/photos/zencyclogo.svg') no-repeat 10px 10px;
// }
  

// .zen-sub-card-perks-list li.icon {
//     background-image:url('../../static/photos/zencyclogo.png');
//     background-repeat:no-repeat;
//     background-position:3px 3px;
//     min-height:16px;
//     padding-left:20px;
//     background-color: red;
//     color:purple;
  
// }

.ActiveSubscriptionCard{
  border-color: $sage;
  border-style: solid;
  border-width:2px;
}

.sub-cancel-link{
  color:$totem;
  margin:auto;
  text-align: center;
  margin-top:15px;
}

.subscription-conf-billing-info {
  color: $totem;
  font-size: 85%;
  font-weight: 900;
  margin:auto;
  max-width:90%;
  text-align: center;
}

.my-zen-carousel{
  max-width: 750px;
  margin: auto;
  margin-top: 75px;
}

.zen-cards-slider {
  width: 100vw;
  max-width: 100vw;
  padding-top: -25px;
  padding-bottom: 25px;

}



.zen-retreat-preview {
  background-color: $ming-fade;
  max-width: 80%;
  ;
  margin: auto;
  padding: 10px;
  margin-bottom: 25px;
  border-color: $ming-green;
  border-radius: 10px;
  border-width: 0;
  border-left-width: 20px;
  border-style: solid;
  box-shadow: $primary_black 0px 0px 8px;
}

.zen-retreat-stream-ad{
  width:80%;
  margin:auto;
  padding-bottom: 10px;
}

.sticker-buy-amount-option {
  width: 80px;
  background-color: $ming-fade;
  max-width: 80%;
  ;
  margin: auto;
  padding: 10px;
  margin-bottom: 25px;
  border-color: $ming-green;
  border-radius: 10px;
  border-width: 0;
  border-bottom-width: 5px;
  border-style: solid;
  box-shadow: $primary_black 0px 0px 8px;
}

.sticker-buy-amount-option-active{
  background-color: $sage-fade;
  border-color: $sage;
}
.retreat-title {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin: auto;
  color: $bright-ornge;
  font-size: 80%;
  font-weight: 400;
}

.zen-retreat-stream-ad .retreat-title{
  color: $off-black;
}

.zen-retreat-price {
  color: $toms_blue;
  font-weight: 900;
}

.zen-retreat-location {
  font-family: "Okashi";
  color: $totem;
}

.zen-retreat-location-rating-num {
  color: $sage;
}

.zen-retreat-preview-image {}

.t-shirt-color-options {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.t-shirt-color-option {
  width: 33%;
  margin: auto;
}

.t-shirt-color-example {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: auto;
  box-shadow: $off-black 0px 0px 5px inset, $off-black 0px 0px 5px;
  margin-bottom: 4px;
}

.t-shirt-confirm-item-details {
  box-shadow: $off-black 0px 0px 5px;
  border-radius: 15px;
  padding: 25px;
  background-color: $navajo_white;

}

.t-shirt-color-example-chosen {
  border-color: $sage;
  border-width: 2px;
  border-style: solid;
  box-shadow: $sage 0px 0px 5px inset, $sage 0px 0px 5px;
  margin-bottom: 0px;
}

.t-shirt-size-name {
  padding: 10px;
  border-radius: 5px;
  border-color: $ming-green;
  border-width: 2px;
  border-style: solid;
  background-color: $ming-fade;
  margin: auto;
  margin-bottom: 10px;
  width: 50px;
  max-width: 55px;
  font-size: 75%;
}

.t-shirt-size-name-chosen {
  border-color: $sage;
  background-color: $sage-fade;

}

.grey-color-example {

  background-color: grey;
}

.white-color-example{
  background-color: $off-white;
}

.toms-color-example {
  background-color: $toms_blue;
}

.budd-color-example {
  background-color: $budd_orange;

}

.toms-blue-text {
  color: $toms_blue;
}

.zen-page-holder {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  overflow-y: scroll;
}

.GongWithWindPage {
  height: 100vh;
  background-color: $budd_orange;
  position: absolute;
  display: block;
  top: 0 !important;
  z-index: 100;
}

.ming-page-instructions-tag {
  font-weight: 900;
  color: $sage;
}

.shop-current-offer-rext {
  font-size: 110%;
  color: $toms_blue;
  font-weight: 900;
  text-align: center;
  margin-bottom: 0;

}

.shop-current-offer-ends {
  font-size: 75%;
  font-weight: 900;
  text-align: center;
  margin-top: 0;
}

.striked-text {
  text-decoration: line-through;
}

.zenWorkerQuote {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 75%;
  color:$bright-ornge;
}

.buyZenergyPointsOption {
  padding: 25px;
  background-color: $ming-fade;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 8px;
  border-color: $ming-green;
  border-width: 3px;
  border-style: solid;
  width: 125px;
}

.zenCWorkerAbout {
  overflow-y: scroll;
  height:100%;
  height: 300px;
  max-height: 300px;
}


.zenCWorkerHolder {
  background-color: $blue_fade;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  border-color: $toms_blue;
  border-width: 1px;
  box-shadow: $toms_blue 0px 0px 5px inset, $primary_black 0px 0px 5px ;
  border-style: solid;
  border-radius: 20px;
  margin: auto;
  height: 325px;
  margin-bottom:15px;
  margin-top:15px;
  overflow: hidden;
  padding-bottom: 15px;
}

.zenCWorkerName {
  font-weight: 900;
}

.zenCWorkerTitle {
  font-size: 75%;
  color:$navajo_white;
}

.donation-os {
  display: block;
  background-image: url("../../static/photos/zencyclogo.png");
  background-size: contain;
  background-repeat: repeat-x;
  min-height: 19px;
  min-width: 55px;
  height: 19px;
  width: 55px;
  max-width: 55px;
  margin: auto;
  margin-left: 5px;
}

.alexiaImage {
  background-image: url("../../static/photos/home/alexiaOtero.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 150px;
  max-height: 125px;
  border-radius: 15px;
  display: block;
  margin: auto;
  margin-top: 15px;
  border-color: $ming-green;
  border-width: 3px;
  border-style: solid;
}

.tomImage {
  background-image: url("../../static/photos/home/tomtemp2.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 132px;
  max-height: 132px;
  border-radius: 15px;
  display: block;
  margin: auto;
  margin-top: 15px;
  border-color: $ming-green;
  border-width: 3px;
  border-style: solid;
}

.zenLogoBacking {

  background-image: url("../../static/photos/zencyclogo.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.splashedBacking {
  background-image: url("../../static/photos/splashedstripes.png");
  background-size: contain;
  background-repeat: repeat-x;
  background-color: $navajoWhite;
}



.reveresedBacking {
  transform: rotate(180deg);
}

.gongs-holder {
  background-color: $navajoWhite;
}



.zenGlowButton {
  border-radius: 5px;
  box-shadow: $off-black 0px 0px 5px;
  width: fit-content;
  margin: auto;
  margin-bottom: 15px;
  padding: 5px;
  font-family: 'ZenKurenaido' !important;
  font-weight: 900;
  padding-bottom: 0;
  text-align: center;
  font-size: 18px;
}

.whiteGlowButton{
  background-color: $off-white;
  color:$primary_black;
}
.confirmChooseSessionBanner{
  .zenGlowButton{
    max-height: 35px !important;
    display:block;
    margin-top:10px;
  }
  }

.giantHomeButton{
  width:250px;
  height:65px;
  font-size: 150%;
  margin-top:35px;
}

.giantHomeButtonDesktop{

  width:450px;
  height:60px;
  font-size: 200%;
  margin-top:50px;
  margin-bottom: 100px;
}

.goldText {
  color: $bright-ornge;
}

.navColored {
  color: $navajo_white;
}

.navajoGlowButton {
  background-color: $navajoWhite;
  color: $toms_blue;
}

.readingListButton{
  background-color: $primary_black;
  color:$off-white;
  padding-left:8px;
  padding-right:8px;
}

.mingGlowButton {
  background-color: $ming-green;
  color: $bright-ornge;
}

.buddGlowButton {
  background-color: $budd_orange;
  color: $primary_black;
}

.sageGlowButton {
  background-color: $sage;
  color: $primary_black;
}

.goldGlowButton {
  background-color: $bright-ornge;
  color: $off-black;
}

.zenMoneyButton {
  margin: auto;
  min-width: 50px;
}

.ZenBasicPage {
  position: fixed;
  top: 0;
  padding-top: 105px;
  background-color: $navajoWhite;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  text-align: center;
  padding-bottom: 250px;
}

.philzentopry-text {
  max-width: 90vw;
  margin: auto;
  padding-bottom: 150px;
  padding-top:0;
  margin-top:0;
}

.ZenBasicPage h1 {
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;
}

.ZenBasicPage h3 {
  padding-left: 2%;
  padding-right:2%;
}

.zenSubHeader {
  margin-top: 0;
  padding-top: 0;
  line-height: 20px;
}

.zenSubHeadered {
  margin-bottom: 0;
  padding-bottom: 0;
}

.justifiedText {
  text-align: justify;
}

.ming-page-button {
  background-color: $ming-green;
  border-radius: 5px;
  box-shadow: $off-black 0px 0px 5px;
  width: fit-content;
  color: $bright-ornge;
  margin: auto;
  margin-bottom: 15px;
  padding: 5px;
}

.my-courses-page {
  position: fixed;
  top: 0;
  background-color: $navajoWhite;
  padding-top: 105px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 25px;
  width: 100vw;
}
.my-articles-page{
  
  position: fixed;
  top: 0;
  background-color: $navajoWhite;
  padding-top: 105px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 25px;
  width: 100vw;
}

.centered-text {
  width: fit-content;
  margin: auto;
  text-align: center;
}

.create-journal-entry-button {
  margin: auto;
  box-shadow: $toms_blue 0px 0px 5px inset, $toms_blue 0px 0px 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  text-align: center;
  color: $toms_blue;
  font-size: 15px;
  line-height: 15px;
  height: fit-content;
  padding-top: 1px;
}

.myZenProfileInactiveHeader {
  opacity: 0.6;
}

.practitionerProfileMainArea{
  overflow-y: scroll;
  max-height: 90vh;
  max-width: 100vw; 
  overflow-x: hidden;
  margin:auto;
}

.view-comments-button {
  height: 20px;
  line-height: 20px;
  box-shadow: $toms_blue 0px 0px 5px inset, $toms_blue 0px 0px 5px;
  padding: 8px;
  margin: auto;
  margin-top: 15px;
  $color: $navajo_fade;
}

.zenc-full-page-scroll {
  overflow: scroll;
  height: 650px;
  padding-bottom: 50px;
}

.journal-entry-preview {
  background-color: $blue_fade;
  border-color: $toms_blue;
  border-style: solid;
  border-width: 0;
  border-left-width: 12px;
  border-radius: 12px;
  padding: 10px;
  width: 80vw;
  max-width: 500px;
  margin: auto;
  margin-top: 25px;
  text-align: center;
}

html {
  background-color: $budd_orange;
  height: 100%;
  width: 100%;
  height: 100vh;
  max-width: 100%;

}

.finished-practice-details {
  margin-top: 35px;
  ;
  margin-bottom: 55px;
  ;
}

.sage-text {
  color: $sage !important;
}

.mood-options {
  display: flex;
  flex-direction: row;
  width: 300px;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 50px;
  height: fit-content;
}

.mood-option {
  width: 100px;
  height: 175px;
  margin: auto;
  text-align: center;
}

.mood-option-image {
  width: 90px;
  height: 90px;
  object-fit: contain;
  margin: auto;
}

.mood-preview-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin: auto;

}

.practitionerHomeContainer{
  -webkit-appearance: none;
}

.practitionerHomeContainer::-webkit-scrollbar{
  display:none;
}

.med-finish-screen {
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: #28666E;
  box-shadow: inset $off-black 0px 0px 45px;
  top: 0;
  text-align: center;
  padding: 50px;
}


.thumbs-rating-holder {
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  ;
  margin-bottom: 60px;
  ;
}

.pf-bookmark-holder {
  position: absolute;
  right: 0;
  top: 5px;
  width: 135px;
  display: flex;
  flex-direction: row;
}

.journal-thumbs-rating {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: auto;

}

.journal-entry-content {
  color: $off-white;
}

.zen-white-text {
  color: $off-white;
}

.thumbs-rating {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: auto;
}

.practice-finished-header {
  font-size: 270%;
  font-family: 'Okashi';
  font-weight: 200;
  color: $navajo_white !important;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;
  margin-bottom: 5px;

}

.minutes-acquired {
  color: $sage !important;
}

.zen-score {
  color: $bright-ornge !important;
}

.practice-finished-sub-header {
  margin-top: 15px;
  color: $navajo_white !important;
  padding-left: 25px;
  padding-right: 25px;
}

.practice-finished-skip {
  color: $navajoWhite !important;
}

.readAllAboutIt {
  background-color: $budd-fade;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $navajo_white;
  width: fit-content;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}

.MultiMediaPortal {
  background-color: $navajoWhite;
  top: 0;
  position: absolute;
  z-index: 9;
}

.ZenDenIndieRooms {
  position: absolute;
  z-index: 9;
  top: 0px;
}

.out-of-use {
  opacity: 0.4;
}

.practice-preview-controls {
  width: fit-content;
  margin: auto;
  margin-top: 5px;
}

.my-content-list {
  padding-bottom: 100px;
  overflow-y: scroll;
  height: 50vh;
}

.zencLoader {
  width: fit-content;
  padding-top: 50px;
  margin: auto;
}

.zen-target {
  display: flex;
  flex-direction: row;
  border-width: 0;
  border-left-width: 10px;
  border-right-width: 10px;
  border-style: solid;
  border-left-color: $ming-green;
  border-right-color: $toms_blue;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  height: 60px;
  margin-bottom: 25px;
}

.my-zen-targets {
  position: relative;
  overflow-y: scroll;
  padding-top: 15px;
}

.zen-target-goal-title {
  background-color: $ming-fade;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 900;
  line-height: 60px;
  color: $navajo_white;
}

.zen-target-goal-value {
  background-color: $blue-fade;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 250%;
  line-height: 60px;
  color: $bright-ornge;
}

.my-zen-planner-sessions {
  padding-top: 25px;
  padding-bottom: 25px;
  overflow-y: scroll;
  max-height: 60vh;
}

.activation-code-info {
  margin-top: 5px;
}

.intro-screen {
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: #28666E;
  box-shadow: inset $off-black 0px 0px 45px;
  top: 0;
  text-align: center;
  padding-top: 50px;
}

.prompt-qouted {
  font-size: 90%;
}

.zenc-page-header-quote{
  font-weight: 900 !important;
  margin-top:0;
  color:$ming-green;
}

.white-page-inset{
  background-color: $off-white;
  box-shadow: inset $off-black 0px 0px 15px;
  border-radius: 50px;
  padding:25px;
  margin:auto;
  margin-top:50px;
  margin-bottom:50px;
  max-width:300px;


}
.white-page-inset{

  ul {
    text-align: left;
    max-width: 300px;
    margin:auto;
  }
}
.planner-day-header {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
}

.paintedLineDivider{
  background-image: url("../../static/photos/paintedLine2.png");
  margin:auto;
  width:100%;
  background-size: contain;
  background-repeat: no-repeat;
  height:50px;
}
.paintedLine {
  background-image: url("../../static/photos/languageIcons/paintedLine.png");
  width: 105px;
  background-size: contain;
  height: 35px;
  display: block;
  background-position-y: bottom;
  position: relative;
  border-radius: 15px;
  margin-top: 13px;

}

.monksWanted-button {
  font-weight: 900;

}

.finish-session-icon {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  width: 75px;
  height: 75px;
  margin: auto;
  background-size: contain;
  border-radius: 50%;
  margin-top: 15px;
  box-shadow: inset $off-black 0px 0px 25px;
}

.navajo-next-button {
  background-image: url("../../static/photos/languageIcons/simpleArrow.png");
  width: 35px;
  height: 35px;
  margin: auto;
  background-size: contain;
  border-radius: 50%;
  margin-top: 15px;
  box-shadow: inset $off-black 0px 0px 25px;

}

.philzentropy {

  bottom: 0;
  width: 100vw;
}

.WelcomeQuizInner {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 90vw;
  margin: auto;
}

.ZenHomeVenom {
  background-color: $navajoWhite;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 35px;
}

.venomImage {
  width: 50%;
  display: block;
  margin: auto;
  min-width: 200px;
}

.venomText {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.ZenHomePactitioners {
  background-color: $ming-green;

}

.radar {
  width: 100vw;
  object-fit: contain;
  background-image: url("../../static/photos/home/radar.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  height: fit-content;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: 'Okashi';

}

.practitioner-invite-text {
  font-family: 'ZenKurenaido';
}

.ZenHomePhilzentropy {
  background-color: $navajoWhite;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
}

.zen-portal-starter-text {
  background-color: $navajo_deep_fade;
  border-color: $navajo_white;
  box-shadow: $off-black 0px 0px 5px;
  border-width: 0;
  border-bottom-width: 15px;
  border-top-width: 15px;
  border-style: solid;
  width: 70vw;
  padding: 25px;
  margin: auto;
  border-radius: 15px;
  font-family: 'ZenKurenaido';
  text-align: center;
  display: block;
  margin-top: 5vh;
}

.monksWanted-text {
  margin: auto;
  margin-top: 10px;
  max-width: 340px;
  color: $navajo_white;
}

.philzentropyTitle {
  font-size: 190%;
}

.ZenHomeBarcelona {
  padding-top: 30px;
  background-color: $navajoWhite;
  color: $toms_blue;
  font-family: 'Okashi';
  padding-bottom: 7px;
  overflow: hidden;
}

.zen-desktop {
  display: none;
}

.ZenHomeBarcelona h1 {
  font-weight: 400;
  font-size: 30px;
  line-height: 25px;
  margin-top: 0;
  padding-top: 0;
}

.madeInBarcelona {
  margin: auto;
  display: block;
  margin-top: 0px;
  bottom: 0;
  width: 100vw;
  max-width: 500px;
}

.madeInBarcelonaHeader {
  font-size: 65%;
  font-weight: 100;
}

.swapStreamsBannerFaded{
  opacity: 0;
  animation: fadeOut 0.4s;
}

.home-social-media-icons-faded {
  opacity: 0;
  animation: fadeOut 0.4s;
}

.content-recc-type-icon{
  text-align: center;
  width:80px;
  font-size: 300%;
  align-items: center;
  line-height:125px;
}

.external-content-recc{
  background-color: $ming-fade;
  border-color: $ming-green;
  border-width: 0;
  border-left-width: 7px;
  border-radius: 7px;
  width:80%;
  margin:auto;
  height:125px;
  margin-bottom:25px;
  margin-top:25px;


}

.reccomendation-type-dropdown{
  width:200px;
  margin:auto;
  padding-top:15px;
  text-align: center;
  .Dropdown-control{
    background: $off-white;
    border-color: $primary_black;
    border-style: solid;
    border-width: 2px;
    border-radius:6px;
  }
}

.recc-type-choice{
  height:25px;
  max-height: 30px;
}

.external-content-recc-details{
  margin:auto;
  h3{
    text-align: center;
  }
}



@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ZenHomeAboutUs-WWO-list li {
  margin-bottom: 10px;
  font-family: 'ZenKurenaido';
  list-style-type: none;
}

.ZenHomeAboutUs-WWO-list ul li::before {
  color: red;

  content: "";
}

.ZenHomeAboutUs-WWO-list {
  padding: 25px;
  padding-top: 0;
  padding-bottom: 0;
}

.log-in-header {
  margin-top: 40px;
}

.sign-up-launch {
  margin-top: 15px;
}

.mobile-only {
  display: block;
}

.PracticePreviewStartArrow {
  background-image: url("../../static/photos/languageIcons/goIcon.png");
  width: 100px;
  height: 100px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
}

.PracticePreviewStartArrow-small {
  width: 50px;
  height: 50px;
}

.PracticePreviewBookmark {
  background-image: url("../../static/photos/languageIcons/bookmark.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
}

.PracticePreviewBookmarked {
  background-image: url("../../static/photos/languageIcons/bookmarked.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
}

.PracticePreviewFavourite {
  background-image: url("../../static/photos/languageIcons/heart.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
}


.remove-practice-preview-symbol {
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;

}

.PracticePreviewFavourited {
  background-image: url("../../static/photos/languageIcons/heartSelected.png");
  width: 100px;
  height: 90px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
}

.list-item-control {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}

.SignUpRequestArrow {
  background-image: url("../../static/photos/NavajoWArrow.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 45px;
}

.ConfirmArrow {
  background-image: url("../../static/photos/NavajoWArrow.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 25px;
}

.practice-preview-controls {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
}

.goArrow {
  background-image: url("../../static/photos/languageIcons/goIcon.png");
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 25px;
  border-radius: 50%;
  box-shadow: $off-black 0px 0px 15px, inset $off-black 0px 0px 5px;
}

.sign-up-header {
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: 200;
}

.sign-up-details-container {}

.su-input-help-text {
  min-height: 25px;
  font-size: 70%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: 'ZenKurenaido';
}

.ZenHomeStreamItem {
  width: 100vw;
  color: $primary_black;
  box-shadow: inset $off-black 0px 0px 5px;
  overflow: hidden;
}

.smallTextLink {
  font-weight: 700 !important;
  font-size: 85%;
  color: $toms_blue;
}

.zen-home-p1 {
  min-height: 100vh;
  background-image: url("../../static/photos/splashedstripes.png");
  background-size: contain;
  background-repeat: repeat-x;
  background-color: $navajoWhite;
  padding-top: 25px;
}

.zenStreamExampleImage {
  margin: auto;
  width: 125px;
  height: 575px;
  background-image: url("../../static/photos/home/streamImage.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $navajoWhite;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: inset $off-black 0px 0px 5px, $off-black 0px 0px 5px;
}

.zenStreamExampleImageLarge {
  width: 260px;
  min-width: 260px;
  height: 1200px;
  background-image: url("../../static/photos/home/streamImage.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $navajoWhite;
  display: block;
  margin: auto;
  margin-top: 50px;
  margin-right: 50px;
  border-radius: 25px;
  box-shadow: inset $off-black 0px 0px 5px, $off-black 0px 0px 5px;
}

.ZenHomeAboutUs {
  text-align: center;
  padding-top: 30px;
}

.ZenHomeAboutUs h2 {
  color: $bright-ornge
}

.alternte {

  background-color: $ming-green;
  color: $navajoWhite;
}

.su-failed-text {
  color: $totem;
  font-weight: 500;
  font-size: 200%;
}
.totemText {
  color: $totem;
  font-weight: 900;
}

.MyChosenZenGoalsHeader {
  padding: 10px;
}

.su-errors-list li {
  color: $totem;
  width: fit-content;
  margin: auto;
  margin-top: 6px;
}

.journal-text-input {
  width: 300px;
  margin: auto;
  text-align: start;
  vertical-align: top;
  background-color: $navajoLow;
  border-style: solid;
  border-width: 2px;
  //border-left-width: 10px;
  border-radius: 5px;
  border-color: $ming-fade;
  //border-color: $budd-fade;
  display: block;
  margin-top: 15px;
  height: 275px;
  font-size: 20px;
  line-height: 20px;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;

}
.nugget-text-input {
  width: 300px;
  margin: auto;
  text-align: start;
  background-color: $navajoLow;
  border-style: solid;
  border-width: 2px;
  //border-left-width: 10px;
  border-radius: 5px;
  border-color: $ming-fade;
  //border-color: $budd-fade;
  display: block;
  margin-top: 15px;
  height: 225px;
  max-height: 225px;
  font-size: 20px;
  line-height: 20px;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;

}

.practice-text-input{
  width: 300px;
  margin: auto;
  text-align: start;
  background-color: $navajoLow;
  border-style: solid;
  border-width: 2px;
  //border-left-width: 10px;
  border-radius: 5px;
  border-color: $ming-fade;
  //border-color: $budd-fade;
  display: block;
  margin-top: 15px;
  height: 200px;
  max-height: 200px;
  font-size: 20px;
  line-height: 20px;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;

}

.nugget-caption-input{
  max-height: 175px;
}
.create-content-description-input{
  width: 300px;
  margin: auto;
  text-align: start;
  vertical-align: top;
  background-color: $navajoLow;
  border-style: solid;
  border-width: 2px;
  //border-left-width: 10px;
  border-radius: 5px;
  border-color: $ming-fade;
  //border-color: $budd-fade;
  display: block;
  margin-top: 15px;
  height: 275px;
  font-size: 20px;
  line-height: 20px;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
}


.su-text-input {
  background-color: #f2e7d2;
  border-style: solid;
  border-width: 0;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: $budd-fade;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  width: 85vw;
  height: 55px;
  font-size: 20px;
  max-width: 500px;
}

.readingListInput{
  background-color: #f2e7d2;
  border-style: solid;
  border-width: 0;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: $budd-fade;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  width: 85vw;
  height: 55px;
  font-size: 20px;
  max-width: 300px;
}

.add-link-input{
  background-color: $off-white;
  border:none !important;
  height:45px;
  width:230px;
}
.add-link-banner{
  width:300px;
  margin:auto;
  height:50px;
  background-color: $off-white;
  border-width: 2px;
  border-color: $sage;
  border-style: solid;
  border-radius: 8px;
  display:flex;
  flex-direction: row;
  margin-top: 15px;

  .addContentPlus{
    width:35px;
    height:35px;
    background-image: url("../../static/photos/languageIcons/addIcon.png");
    background-size: contain;
    margin:auto;
    margin-top: 10px;
    border-radius: 50%;
    box-shadow: $primary_black 0px 0px 7px, inset $primary_black 0px 0px 7px;
  }
  
}
.delivery-details-input {
  background-color: #f2e7d2;
  border-style: solid;
  border-width: 0;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: $budd-fade;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  width: 85vw;
  height: 35px;
  font-size: 26px;
  max-width: 500px;
}

.payment-value-input {
  background-color: #f2e7d2;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 10px;
  border-radius: 5px;
  border-color: $toms_blue;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  width: 100px;
  height: 55px;
  font-size: 20px;
  margin-bottom: 20px;
  ;
  margin-top: 10px;
  ;
}

.zen-card-code-input {
  background-color: #f2e7d2;
  border-style: solid;
  border-width: 0;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: $budd-fade;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  width: 55vw;
  height: 55px;
  font-size: 20px;
  max-width: 250px;
}

.validated-field {

  border-color: $sage;
}

.cs-duration-input {
  background-color: $navajo_white;
  border: none;
  display: inline-block;
  margin: auto;
  text-align: center;
  border-radius: 4px;
  margin-top: 15px;
  width: 70px;
  max-width: 75px;
  height: 60px;
  font-size: 36px;
  box-shadow: $off-black 0px 0px 5px;
  font-family: 'ZenKurenaido';
}

.cs-duration-input::placeholder {
  font-size: 20px;
}

.loaderHolder {
  width: 250px;
  margin: auto;
  color: $budd_orange;
}

.loadScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $navajoWhite;
  padding-top: 150px;
}

.home-launch-li-text {
  margin: auto;
  margin-top: 5px;
  border-radius: 5px;
  color: $primary_black;
  font-weight: 900;
  padding: 10px;
  margin-bottom: 0px;
  width: 250px;
  margin: auto;
  font-family: ZenKurenaido;
  font-size: 90%;
}

.sign-up-here-button {
  margin: auto;
  margin-top: 5px;
  background-color: $blue_fade;
  border-width: 0;
  border-bottom-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $toms_blue;
  width: fit-content;
  color: $bright-ornge;
  font-weight: 900;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  margin-bottom: 5px;
}

.zenBlockButton {
  background-color: $navajo_fade;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $navajo_white;
  width: fit-content;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}


.goldBlockButton {
  background-color: $bright-ornge;
}

.my-zen-skills-list {
  margin: auto;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 15px;
  min-height: 100px;
  border-radius: 8px;
  background-color: $off-white;
  box-shadow: inset $primary_black 0px 3px 6px;
  width: fit-content;
  max-width: 400px;
  list-style: none;
  /* Remove default bullets */
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 50px;
}

.my-zen-skill-list-item-title {
  font-weight: 900;
  font-size: 80%;
}

.my-zen-skills-list-title {
  text-align: center;
  margin-bottom: 10px;
}

.my-zen-skill-list-item-icon {
  font-size: 75%;
}

.my-zen-skill-list-item {
  margin-top: 5px;
}

.zen-button {
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;

  width: fit-content;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}

.white-button {
  background-color: $navajoLow;
  border-color: $navajo_fade;
}

.sage-button {
  background-color: $sage-fade;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $sage;
  width: fit-content;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}

.MyZenProgress {
  padding-top: 35px;
  overflow-y: scroll;
  max-height: 65vh;
}

.skill-map-stage {
  width: 320px;
  height: 320px;
  margin: auto;
  margin-top: 15px;
  background-color: $off-white;
  border-radius: 50%;
  box-shadow: inset $primary_black 0px 0px 8px;
  display: block;
}

.white-button {}

.task-button {
  background-color: $blue_fade;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  width: fit-content;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}

.zen-bot-button {
  background-color: $budd-fade;
  border-width: 0;
  border-bottom-width: 10px;
  border-color: $toms_blue;
  border-style: solid;
  border-radius: 5px;
  width: fit-content;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 0px 10px;
  color: $primary_black;
  margin: auto;
  color: $navajoLow;
  opacity: 0.6;
  text-align: center;

}

.task-button-alt {
  background-color: $ming-fade;
  border-width: 0;
  border-bottom-width: 10px;
  border-color: $blue-fade;
  border-style: solid;
  border-radius: 5px;
  width: fit-content;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 0px 10px;
  color: $primary_black;
  margin: auto;
  color: $navajoLow;
  text-align: center;
  font-size: 15px;
  line-height: 15px;
}

.incomplete-task-button {
  border-color: $budd_orange;
}

.completed-task-button {
  border-color: $sage;
  background-color: $sage-fade;
}

.prompt-qouted-auth {
  font-weight: 900;
}

.planner-set-goals-prompt {

  width: 75vw;
  margin: auto;
  margin-top: 30px;
  background-color: $navajo_fade;
  border-width: 0;
  border-bottom-width: 10px;
  border-top-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $navajo_white;
  padding: 15px;
  text-align: center;
}

.set-goals-prompt {
  position: fixed;
  z-index: 9;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: #28666E;
  box-shadow: inset $off-black 0px 0px 45px;
  top: 0;
  text-align: center;
  padding-top: 50px;
  padding: 50px;
  color: $bright-ornge;
  text-align: center;
}

.walkthrough-page {
  position: fixed;
  z-index: 9;
  width: 100%;
  // max-width: 100vw !important;
  height: 100vh;
  background-color: #28666E;
  box-shadow: inset $primary_black 0px 0px 45px; //$off-black 0px 0px 45px;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  text-align: center;
  color: $bright-ornge;
  display: block;
}

.walkthrough-page-text {
  padding-top: 50px;
  margin: auto;
  width: 70%;

}

.ming-button {
  background-color: $ming-fade;
  border-width: 0;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: $ming-green;
  width: fit-content;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: $primary_black 0px 5px 15px;
  color: $primary_black;
  margin: auto;
}

.philzentropyLearnMore {
  font-size: 110%;
  text-align: center;
  margin: auto;
  padding-bottom: 5px;
}

.bottom-b-button {
  border-width: 0 0 10px 0 !important;
}

.roots-button {
  font-size: 100%;
  margin-top: 5px;
  color: $ming-green;
  text-align: center;
  margin: auto;
  font-weight: 800;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  min-height: 100%;
  // background-color: $budd_orange;
  overflow-y: hidden;
}

code {

  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

a {
  text-decoration: none;
  color: inherit !important;
}

a:hover {
  text-decoration: none !important;
}

// .ZenCGradient1{
//   background: rgb(181,182,130);
//   background: radial-gradient(circle,#3396a3 0%, $ming-green 75%, $toms_blue 100%);
//   border-width: 0;
//   border-left: 8px;
//   border-color: #3396a3;
//   border-style: solid;
// }

.zen-search {
  display: none;
}


.ZenNavBar {
  width: 100vw;
  height: 80px;
  max-height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  border-bottom-style: solid;
  border-bottom-color: $primary_black;
  border-bottom-width: 2px;
  display: flex;
  flex-direction: row;
  box-shadow: $primary_black 0px 5px 15px;
  z-index: 100;
  max-width: 100vw;
  background-color: $budd_orange;
  margin-bottom: 0;
}

.noBorderNavBar {
  background-color: $budd_orange;
  width: 100vw;
  height: 90px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 100;
  max-width: 100vw;
  margin-bottom: 0;
}

.navbarisabledHolder {
  width: 100%;
  height: 90px;
  position: absolute;
  background-color: $primary_black;
  opacity: 0.9;
}

.ZenHomeHolder {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  z-index: 2;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ZenArms {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100vw;
  height: 600px;
  background-color: $navajoWhite;
  background-image: url("../../static/photos/home/arms.png");
  background-size: contain;
  background-repeat: repeat-y;
  background-position: bottom;

}

.home-social-media-icons {
  position: fixed;
  bottom: 0;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.home-social-media-icons-bottom {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.homeSM {
  margin: auto;
  color: $primary_black;
  font-size: 250%;
}

.log-in-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: $navajoWhite;
  text-align: center;
  padding-top: 15px;
}

.MyZenNavBar {
  background-color: $budd_orange;
  width: 100%;
  height: 30px;
  position: fixed;
  margin-top: 80px;
  top: 0;
  padding-top: 0;
  padding-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-color: $primary_black;
  border-bottom-width: 2px;
  display: flex;
  flex-direction: row;
  box-shadow: $primary_black 0px 5px 5px;
  z-index: 101;
  font-size: 65%;
  color: $navajo_white;
}

.noBorderedNavBar {
  border: none;
  box-shadow: none;
}

.GoldTier {
  color: gold;
}

.MultiChildItem {
  display: flex;
  flex-direction: row;
}

.NavBarMenuItem {
  width: 33%;
  margin: auto;
}

.NavbarCircleLogoHolder {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border-style: solid;
  border-color: $primary_black;
  border-width: 1px;
  background-size: cover;
  margin: auto;
  margin-top: 20px;
  box-shadow: $primary_black 0px 0px 15px;
}

.NavbarMenuIcon {
  font-size: 300%;
  text-align: center;
  color: black;
  margin-top: 20px;
  width: 100%;
}

.ghost-practice-warning {
  float: left;
  margin: 9px;
  font-size: 175%;
  ;
}

.task-icon{
  float: right;
  margin: 9px;
  margin-top: 25px;
  width: 32px;
  height: 32px;
}

.task-fa-icon {
  float: left;
  margin: 9px;
  font-size: 175%;
  ;
}

.task-logo-icon {


  background-image: url("../../static/photos/zencyclogo.png");
  background-size: cover;
}

.task-target-icon {
  background-image: url("../../static/photos/languageIcons/target.png");
  background-size: cover;
}


.ZenverseSidebarIcon {

  font-size: 350%;
  text-align: center;
  color: $primary_black;
  margin: 20px;
  width: 90%;
}

.nav-text-item {
  text-align: center;
  margin-top: 10px;
  font-size: 100%;
}

.NavbarLogoHolder {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 5px;

}

.zenergy-p {
  font-family: Okashi;
  //color: $medPurple;
}

.zencyclogo {
  background-image: url("../../static/photos/zencyclogo.png");

}

.ZencyclopaediaLogo {
  margin-top: 8px;
  width: 65px;
  height: 65px;
  background-image: url("../../static/photos/exploreIcons/info.png");
}

.choosePP {
  width: 150px;
  height: 150px;
  border-radius: 30px;
  background-size: cover;
  display: block;
  margin: auto;
  box-shadow: $primary_black 0px 0px 15px;
}

// .larry{
//   background-image: url("../../static/photos/userIcons/larry.png");
// }
// .leon{
//   background-image: url("../../static/photos/userIcons/leon.png");
// }
// .lion{
//   background-image: url("../../static/photos/userIcons/lion.png");
// }
// .freddie{
//   background-image: url("../../static/photos/userIcons/sageFrog.png");
// }
// .furgus{
//   background-image: url("../../static/photos/userIcons/furgus.png");
// }
// .flamingo{
//   background-image: url("../../static/photos/userIcons/flamingo.png");
// }

// .nirvana{
//   background-image: url("../../static/photos/userIcons/nirvana.png");
// }
.quiz-statement {
  margin: auto;
  color: $off-black;
  font-size: 150%;
  text-align: center;
  border-radius: 4px;
  box-shadow: $primary_black 0px 0px 5px;
  border-radius: 7.5px;
  background-color: $navajoLow;
  border-style: solid;
  border-width: 0;
  border-left-width: 15px;
  border-color: $navajo_white;
  width: 70vw;
  max-width: 70vw;
  padding: 15px;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.quiz-statement-background {
  width: 70vw;
  max-width: 70vw;
  margin: auto;
  height: fit-content;
  border-radius: 7.5px;
  background-color: $navajo_deep_fade;
  margin: auto;
  box-shadow: inset $primary_black 0px 0px 5px;
  display: block;

}

.MyZenLogo {
  background-image: url("../../static/photos/MediIcon.png");
  width:60px;
  height:60px;
}

.okashi {
  font-family: Okashi;
}


.zenK {
  font-family: ZenKurenaido;
}

.Hakubo {
  font-family: Hakubo;
}

.MyZenProfileNavBarLink {
  width: 33%;
  text-align: center;
  h2{
    margin-top: 0;
  }
}

.MyZenProfileNavBarLink:hover {
  text-decoration: none;
}

.MyZenJournal {
  padding-top: 170px;
  background-color: $navajoWhite;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
}

.MyZenPlanner {
  padding-top: 170px;
  background-color: $navajoWhite;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
}

.MyZenProfile {
  background-color: $navajoWhite;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  top: 0;
  margin-top: 75px;
}

.myZenMain{
  padding-top:75px;
}

@font-face {
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

@font-face {
  font-family: 'Okashi';
  src: local('Okashi'), url(../../static/fonts/Zen_Kurenaido/Okashi.ttf) format('truetype');

}

@font-face {
  font-family: 'HarukazeSolid';
  src: local('HarukazeSolid'), url(../../static/fonts/Zen_Kurenaido/HarukazeSolid.ttf) format('truetype');

}

@font-face {
  font-family: 'Hakubo';
  src: local('Hakubo'), url(../../static/fonts/Zen_Kurenaido/Hakubo.otf) format('opentype');

}

.nav-search-bar {
  height: 35px;
  width: 220px;
  border-radius: 15px;
  border-style: solid;
  border-color: $primary_black;
  border-width: 2px;
  margin-top: 25px;
  position: relative;
  left: 25px;
  background-color: $ming-green;
  color: $primary_black;
  padding-left: 10px;
  font-size: 125%;
  box-shadow: $primary_black 0px 0px 15px;
  font-family: 'ZenKurenaido';
  src: local('ZenKurenaido'), url(../../static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf) format('truetype');

}

.nav-search-bar::placeholder {
  color: $primary_black;
}

.stream-loading {
  width: 100vw;
  background-color: $navajoWhite;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  box-shadow: $primary_black 0px 0px 25px inset;
}

.choose-language {
  width: fit-content;
  margin: auto;
}

.MyZenInfoItem {
  font-size: 150%;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 800;
}

.my-zen-MyStuff-container {
  padding-top: 25px;
}

.MyZenDetails {
  margin: auto;
  margin-bottom: 30px;
  overflow-y:scroll;
  
}

.MyZenDetails::-webkit-scrollbar{
  display:none;
}

.MyZenContent {
  border-radius: 20px;
  width: 60%;
  background-size: contain;
  padding-right: 2%;
  min-width: 380px;
  margin: auto;
}

.MyZenContentNavigationRow {
  height: 70px;
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 50%;
}

.MyZenPracticesNavigationRow {
  height: 65px;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.MyZenPracticesPeriodToggleHolder {
  width: 33%;
}

.MyZenPracticesPeriodToggle {
  width: 50%;
  margin-left: 25%;
  text-align: center;
  height: 35px;
}

.PeriodToggleActive {

  border-style: solid;
  border-color: $primary_black;
  border-width: 0 0 2px 0;
}

.languageIcon {
  height: 40px;
  width: 40px;
  margin-left: 15px;
  margin-top: 4px;
}

.homeLanguageIcon {
  height: 40px;
  width: 40px;
  margin: auto;
  border-radius: 50%;
  background-color: $navajo_fade;
  border-radius: 50%;
  box-shadow: inset $primary_black 0px 5px 10px;
  display: flex;
  align-items: center;
}

.home-choose-langauge {
  width: 200px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.chooseEnglish {
  height: 20px;
  width: 20px;
  margin: auto;
  background-image: url('../../static/photos/languageIcons/engIcon.png');
  background-size: contain;

}

.chooseSpanish {
  height: 20px;
  width: 20px;
  margin: auto;
  background-image: url('../../static/photos/languageIcons/espIcon.png');
  background-size: contain;

}

.zenscapeControlIcon {
  background-size: contain;
  margin: auto;
  height: 60px;
  width: 60px;
  margin-top: 15px;
  background-repeat: no-repeat;
}

.favouriteScapeIcon {
  background-image: url('../../static/photos/languageIcons/heart.png');
  height: 80px;
  width: 80px;
  margin-top: 4px;
}

.favouritedScapeIcon {
  background-image: url("../../static/photos/languageIcons/heartSelected.png");
  height: 80px;
  width: 80px;
  margin-top: 4px;
}


.addZenscapeIcon {
  background-image: url('../../static/photos/languageIcons/plusIcon.png');
  background-size: contain;
}

.removeZenscapeIcon {
  background-image: url('../../static/photos/languageIcons/deleteIcon.png');
  background-size: contain;
}

.ZenscapeBackArrow {
  background-image: url("../../static/photos/NavajoWArrow.png");
  position: relative;
  transform: rotate(180deg);
  width: 100px;
  height: 100px;
  margin-top: 0px;
}

.ZenscapeStartMobile {
  background-image: url("../../static/photos/NavajoWArrow.png");
  position: relative;
}

.plusLanguageIcon {
  background-image: url('../../static/photos/languageIcons/plusLanguageIcon.png');
  background-size: contain;
  height: 40px;
  width: 40px;
  margin-left: 15px;
  margin-top: 4px;


}

.subPageNavHeader {
  width: 27%;
  text-align: center;
  margin: auto;
  color: $budd_orange;
}

.zenCardsFilterButton {
  width: 70px;
  text-align: center;
  margin: auto;
  font-size: 60%;
  border-radius: 5px;
  box-shadow: $off-black 0px 0px 4px;
  padding-bottom: 3px;
  color:$navajo_white;
}


.zenCardsFilterButton.inactiveZenCardsFilter {
    box-shadow: $off-black 0px 0px 4px inset;
}

.subPageActiveNavHeader {
  border-style: solid;
  border-color: $budd_orange;
  border-width: 0 0 2px 0;
}


.powerCardsFilterButton {
  background-color: $budd_orange;
}


.milestoneCardsFilterButton {
  background-color: $ming-green
}

.powerCardsFilterButton.inactiveZenCardsFilter {
    background-color: $budd-fade;

}

.subscriptionCardsFilterButton{
  background-color: $toms_blue

}
.subscriptionCardsFilterButton.inactiveZenCardsFilter {
    background-color: $blue-fade;
}

.milestoneCardsFilterButton{
  background-color: $ming-green;
}

.milestoneCardsFilterButton {
  .inactiveZenCardsFilter {
    background-color: $ming-fade;
  }
}

.ZenClogoLarge {
  background-image: url("../../static/photos/zencyclogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 0;
}

.myZenPlannerHeader {
  width: 27%;
  margin: auto;
  text-align: center;
  color: $budd_orange;
  font-size: 110%;
}

.zen-profile-lower-container {
  margin:auto;
  padding-top: 20px;
  overflow-y: scroll;
  max-height: 45vh;
  border:none;
}

.my-notifications-container {
  padding-top: 20px;
  margin: auto;
  width: 100%;
  overflow-y: scroll;
}


.MyZenProfilePic {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border-style: solid;
  border-color: $primary_black;
  border-width: 2px;
  display: block;
  margin: auto;
  margin-top: 25px;
  box-shadow: $off-black 0px 0px 10px;
  background-color: $ming-green;
  // object-fit: contain;
  overflow: hidden;
}



.MyPractitionerProfilePicHolder {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border-style: solid;
  border-color: $primary_black;
  border-width: 2px;
  display: block;
  margin: auto;
  margin-top: 25px;
  box-shadow: $off-black 0px 0px 10px;
}

.MyPractitionerProfilePic {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.myZenProfilePicChangeable{
  background-color: $ming-green;
  background-image: url("../../static/photos/languageIcons/mingEdit.png");
  background-size: contain;
  cursor:pointer;
}
.myZenProfilePicChangeable:hover{
  background-image: url("../../static/photos/languageIcons/mingEdit.png");
  background-size: contain;
  cursor:pointer;
  img{
    display:none;
  }
}

.practitionerProfilePic{
  width: 100px;
  width:100px;
  border-radius: 10px;
  border-style: solid;
  border-color: $ming-green;
  border-width: 4px;
  display: block;
  margin: auto;
  margin-top: 25px;
  box-shadow: $off-black 0px 0px 10px;
  overflow:hidden;
  padding:none;
}

.communityProfilePic{
  border-color: $budd_orange;
}

.MyZenProfilePicInactive {
  max-height: 80px;
  max-width: 80px;
}

.redOptionText{
text-align: center;
color:$budd_orange;
}
.MyZenProfilePicN {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border-style: solid;
  border-color: $primary_black;
  border-width: 2px;
  margin: 8px;
}

.MyZenHeader {
  width: 50%;
  text-align: center;
  border-radius: 20px;
  height: 100px;
  padding-top: 10px;
  color: $navajo_white;
}

.MyZenPathsHeader {
  background-color: $dGreen;
}

.MyZenPracticesHeader {
  background-color: $sage;
}

.MyZenHistoryHeader {
  background-color: $ming-green
}

.MyZenFavouritesHeader {
  background-color: $budd_orange;
}

.MyZenSFLHeader {
  background-color: $medPurple;
}

.MyZenFocusItem {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: -20px;
  border-radius: 10px;
  padding-top: 30px;
  overflow-y: scroll;
  height: 435px;
}

.ZoomedProfileItemHolder{
  width:100vw;
  height:100vw;
  position:fixed;
  z-index:99999999;
}

.ZoomedProfileItem{
  width:300px;
  height:fit-content;
  min-height:300px;
  margin:auto;
  border-radius: 25px;
  background-color: $ming-green;
  opacity:1;
  margin-top:150px;
  padding-top:13px;
  box-shadow: $primary_black 0px 0px 10px inset, $primary_black 0px 0px 15px;
}

// float:right;
// position:relative;
// margin-top:-150px;
// right:25px;
// width:25px;
// height:25px;
// background-color: pink;;
.CloseZoomedProfileItem{
  position:absolute;
  background-color: $off-white;
  border-color:$primary_black;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  margin-left:275px;
  margin-top:-20px;
  width:50px;
  height:50px;
  background-image: url("../../static/photos/languageIcons/deleteIcon.png");
  box-shadow: $primary_black 0px 0px 8px inset, $primary_black 0px 0px 8px;
  background-size: cover;
}

.ZoomedProfilePic{
  display:block;
  width:275px;
  height:275px;
  margin:auto;
  border-radius:25px;
  box-shadow: $primary_black 0px 0px 10px inset, $primary_black 0px 0px 5px;
}



.MyZenPaths {
  background-color: $sage;
}

.MyZenHistory {
  background-color: $dGreen;
}

.MyZenFavourites {
  background-color: $budd_orange;
}

.MyZenSFL {
  background-color: $toms_blue;
}

.MyZenPracticePreview {
  width: 96%;
  height: 50px;
  border-radius: 10px;
  margin-left: 2%;
}

.MyZenNotification {
  width: 90%;
  max-width: 400px;
  min-height: 50px;
  border-radius: 5px;
  border-width: 0;
  border-style: solid;
  border-left-width: 10px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  font-size: 90%;
}

.task-title {
  padding-top: 8px;
  font-size: 110%;
  width: 90%;
}

.MyZenFirsStepsNotification {
  background-color: $ming-fade;
  border-color: $ming-green;
}

.create-practice-controls {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
}

.MyZenPracticeSession {
  width: 80vw;
  min-height: 85px;
  border-radius: 5px;
  margin: auto;
  margin-top: 15px;
  padding: 15px;
  padding-top: 8px;
  border-width: 0;
  border-style: solid;
  border-left-width: 10px;
  text-align: center;
  max-width: 500px;
}

.ghost-practice {
  background-color: #272727;
  opacity: 80%;
  color: $budd_orange;
}



.nugget-title {
  text-align: center;
  font-size: 130%;
  color: $navajo_white;
}

.text-nugget-title{
  font-size: 95%;
  max-width: 175px;
  margin:auto;
  text-align: center;
  color:$off-white;
}

.nugget-content {
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 25px;
  font-size: 90%;
}
.instaPortraitAd{
  width: 1350px;
  height:1688px;
  background-image: url("../../static/photos/MovementBacking.png");
  
}

.instaAdText{
  color:$primary_black;
  font-size: 125px;
  width:1350px;
  text-align: center;
}
.text-nugget-content{
  text-align: center;
  font-size: 90%;
  padding-left:25px;
  padding-right: 25px;

}
.long-text-nugget-content{
  text-align: justify;

}

.image-nugget-content{
  margin-top:0;
  margin-bottom: 0;
  text-align: center;
  font-size: 90%;
}

.ZenHome {
  background-color: #033f63;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  bottom: 0;
  position: fixed;
}


.zen-home-logo-holder {
  width: 45%;
  height: 300px;
  background-color: $budd_orange;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
  border-style: solid;
  border-color: $primary_black;
  border-width: 0 3px 0 0;
}

.home-holder-move {
  width: 100%;
  height: 100px;
}


.zen-home-logo {

  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: 25px;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
}

.zen-home-logo-move {
  width: 80px;
  height: 80px;
  margin-top: 5px;
}

.zen-home-photo {

  width: 280px;
  height: 280px;
  margin: auto;
  margin-top: 10px;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
}

.zen-home-buddha-bot-holder {
  width: 55%;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
  left: 0;
}

.home-buddha-bot-holder-move {
  width: 0;
  height: 100px;
}

.welcome-hide {
  opacity: 0;
}

.home-buddha-bot-move {
  height: 50px;
  width: 50px;
  opacity: 0;
}

.large-screen-only {
  display: none;
}

.welcome-top-row {
  background-color: $sage;

  box-shadow: $off-black 0px 0px 85px;
}

.scroll-down-hint-text {
  color: $bright-ornge;
  margin-top: 75px;
  text-align: center;
}

.welcome-bottom-row {
  background-color: $ming-green;
  -webkit-transition: 1.8s;
  -moz-transition: 1.8s;
  -o-transition: 1.8s;
  transition: 1.8s;
  position: absolute;
  bottom: -20px;
  width: 100%;
  box-shadow: $off-black 0px 0px 85px;
}

.zen-sign-up-title {
  font-size: 180%;
  margin-top: 15px;
  margin-bottom: 0;
}
.SubscriptionTierColoured{
  font-size: 180%;
  margin-top: 15px;
  margin-bottom: 0;
}
.ZenSignUp {
  width: 100vw;
  height: 100vh;
  background-color: $navajoWhite;
  position: fixed;
  top: 0;
}

.zen-home-title-row {
  position: absolute;
  top: 300px;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
  height: 150px;
  padding: 15px;
  width: 100%;
}

.zen-su-title-row {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 20px;
}

.su-title-row {
  display: block;
}

.zen-home-welcome-to {
  color: $bright-ornge;
  font-size: 90%;
  margin-left: 280px;
}

.zen-home-title {

  color: $bright-ornge;
  font-size: 750%;
  margin-top: -20px;
  text-align: center;
}

.get-started {
  position: absolute;
  right: 30px;
  top: 15px;
}

.get-started-text {
  color: $bright-ornge;
  font-size: 110%;
  text-align: center;
  text-align: center;
}

.zen-home-alan-holder {
  width: 55%;
  height: 300px;
  background-color: $navajo_white;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
}

.zen-walkthrough-text-next {
  background-image: url("../../static/photos/helpArrow.png");
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
  background-size: cover;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: $primary_black 0px 5px 10px;
}

.walkthrough-arrow-down {
  transform: rotate(90deg);
  margin-top: 0px;
  margin-bottom: 10px;
}

.zen-walkthrough-text {
  display: flex;
  flex-direction: row;
  padding: 30px;
  color: $bright-ornge;
}

.zen-site-comms-holder {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  background-color: $off-black;
  opacity: 0.95;
  z-index: 99;
}

.link-session-title {
  padding-left: 45px;
  padding-right: 45px;
  margin: auto;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 25px;
}

.zen-walkthrough-helper {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: $off-black;
  opacity: 0.95;
  color: $bright-ornge;
  text-align: center;
  z-index: 111;

}

.navajoTextCol {
  color: $navajo_white;
}

.helper-bottom {
  top: auto;
  bottom: 0 !important;
  padding-bottom: 25px;
}

.signUpHolder {
  opacity: 0;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
  width: 100%;
  text-align: center;
}

.in-view {
  opacity: 1;
}

.logoSpinner {
  width: 300px;
  height: 300px;
  background-image: url('../../static/photos/zencyclogo.png');
  background-size: cover;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -o-transition: 3s;
  transition: 3s;
  margin: auto;
}

.WelcomeQuiz {
  width: 100%;
  margin: auto;
  padding-top: 25px;
  overflow-y: scroll;
  padding-bottom: 25px;
  height: 100vh;
  background-color: $navajoWhite;
  position: fixed;
  top: 0;
  text-align: center;
}

.WelcomeQuiz-Q1 {
  max-height: 200px;
  font-size: 65%;
}

.logoSpinnerSpun {

  opacity: 0;
  height: 0;
  width: 0;
}


.circle-zenscape-starter {
  margin: auto;
  margin-top: 105px;
  text-align: center;
  font-size: 550%;
  height: 400px;
  color: $sage;
  margin-left: -75px;
}

.zen-home-zencyc-holder {
  width: 45%;
  height: 300px;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
  transition: 5s;
  border-style: solid;
  border-color: $primary_black;
  border-width: 0 0 0 3px;
}

.zen-home-alan-holder {
  background-color: $navajo_white;
}

.alan-holder-move {
  width: 0;
  height: 110px;
  margin-top: 0;
}

.alan-p-holder-move {

  width: 0;
  height: 60px;
  margin-top: 0;
  height: 110px;
}

.alan-move {
  width: 0;
  height: 60px;
  margin-top: 0;
  opacity: 0;
}

.wiki-holder-move {
  height: 110px;
  padding-left: 5px;
  width: 80px;
  border-width: 0;
}

.wiki-move {
  width: 80px;
  height: 80px;
  margin-top: -15px;
  margin-left: 10px;
}

.quiz-AC4 {
  background-color: $budd_orange;
}

.quiz-AC3 {
  background-color: $sage;

}

.quiz-AC2 {

  background-color: $navajo_white;
}

.quiz-AC1 {

  background-color: $ming-green;
}

.quiz-ans {
  padding: 30px;
  text-align: center;
}

.quiz-ans:hover {
  cursor: pointer;
  color: $toms_blue;
}

.masterBot{
  width:150px;
  margin:auto;
  height:160px;
  background-size: contain;
  background-image: url("../../static/photos/zenbot/zenbotnew.png");
}

.zenBotNameTextP1{
  background-image: url("../../static/photos/zenbot/ZENBOTP1.png");
  background-size: contain;
  width:200px;
  height:85px;
  margin:auto;
}

.zenBotNameTextP2{
  background-image: url("../../static/photos/zenbot/ZENBOTP2.png");
  background-size: contain;
  width:200px;
  height:80px;
  margin:auto;
}


.babyBotNameTextP1{
  background-image: url("../../static/photos/zenbot/babybuddhatextP1.png");
  background-size: contain;
  width:200px;
  height:65px;
  margin:auto;
  margin-top: 50px;
}

.babyBotNameTextP2{
  background-image: url("../../static/photos/zenbot/babybuddhatextP2.png");
  background-size: contain;
  width:200px;
  height:42px;
  margin:auto;
}

.masterBotNameTextP1{
  background-image: url("../../static/photos/zenbot/MasterBotP1.png");
  background-size: contain;
  width:210px;
  height:50px;
  margin:auto;
  margin-top: 45px;
}
.masterBotNameTextP2{
  background-image: url("../../static/photos/zenbot/MasterBotTextP2.png");
  background-size: contain;
  width:100px;
  height:50px;
  margin:auto;
}
.monksWanted {
  max-width: 250px;
  margin: auto;
  color: $bright-ornge;
}

.justAnAd{
  background-color: $toms_blue;
  width:100vw;
  height:100vh;
  position: fixed;
  padding-top:100px;
  top:0;
  padding-left:100px;
  overflow-y: scroll;
}

.justAnAdTextHolder{
text-align: justify;
width:fit-content;
color:$off-white;
}


.justAnAdTextHolderRed{
background-color: $budd_orange;
color:$primary_black;
padding:35px;
.adRegularText{
  color:$off-white;
}
}

.justAnAdTextHolderRedBlack{
  background-color: $budd_orange;
  color:$primary_black;
  padding:35px;
  .adRegularText{
    color:$primary_black;
  }
  }

.adRegularText{
  margin-top: 0;
  margin-bottom: 0;
}

.adBoldText{
  margin-top: 0;
  margin-bottom: 0;
  color:$bright-ornge;
  font-weight: 900;
}

.zenBotSliderOption{
  background-color: $navajo_white;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px inset;
  border-color: $toms_blue;
  height: 310px;
  max-height: 310px;
  margin-top: 5px;
  margin-bottom: 25px;
  width:350px;
  max-width:350px;
  margin:auto;
  border-radius: 35px;
  margin-top: 10px;
}

.adRegularText{
  color:$off-white;
}

.premiumBadge{
background-color: $toms_blue;
color: $bright-ornge;
width:120px;
margin:auto;
text-align: center;
box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px inset;

}

.freeBadge{
  background-color: $sage;
  color: $primary_black;
  width:120px;
  margin:auto;
  text-align: center;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px inset;
  
  }

.betaBadge{
  background-color: $budd_orange;
  color: $primary_black;
  width:120px;
  margin:auto;
  text-align: center;
  box-shadow: $primary_black 0px 0px 5px, $primary_black 0px 0px 5px inset;
  
  }


.sign-up-quiz {
  z-index: 9;
  width: 40%;
  margin-left: 30%;
  height: 500px;
  top: 160px;
  opacity: 0;
  position: fixed;
}

.AboutUsHolder {
  z-index: 9;
  width: 40%;
  margin-left: 30%;
  height: 500px;
  top: 140px;
  opacity: 0;
  position: fixed;
}

.about-us {
  z-index: 9;
  width: 60%;
  margin-left: 20%;
  height: 500px;
  top: 140px;
  opacity: 0;
  position: fixed;

  opacity: 0;
  -webkit-transition: 1.7s;
  -moz-transition: 1.7s;
  -o-transition: 1.7s;
  transition: 1.7s;
  -webkit-transition-delay: 1.8s;
  -moz-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;


}

.show-about-us {
  opacity: 1;
}

.hide-about-us {
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}


.sign-up-quiz-active {
  opacity: 1;
}

.WelcomeQuiz-Q1-Answers {
  border-radius: 15px;
  overflow: hidden;
}

.quiz-top-row {
  background-color: $navajo_white;
}

.quiz-question-text {
  color: $budd_orange;
  text-align: center;
  margin-bottom: 30px;
}

.SM-icons {
  width: 300px;
  height: 50px;
  color: $off-black;
  font-size: 400%;
}

.val-email-prompt {
  width: 80vw;
  margin: auto;
  text-align: center;
}

.MyZenFirsStepsNotificationHighlighted {
  box-shadow: $bright-ornge 0px 5px 10px;
}

.zen-menu-item-prpgate-loader{
  color:$budd_orange;
  width: 90%;
  margin:auto;
}


.zen-shop-items-container {
  padding-top: 175px;
  padding-bottom: 200px;
}




@media only screen and (max-height:650px) {


  .subPageNavHeader {
    font-size: 95%;

  }

  .su-title-row {
    display: none;
  }

  .sign-up-header {
    margin-top: 25px;
  }

}

@media only screen and (max-width:480px) {

  .subPageNavHeader {
    font-size: 140%;

  }

  .monksWanted-text {
    padding-right: 130px;
    padding-left: 20px;
    text-align: justify;
  }

  .monksWanted-button {
    margin-right: 130px;
    margin-left: 25px;
    margin-top: 15px;
    text-align: justify;
    font-size: 90%;
  }

  .radar {
    min-height: 300px;
  }

  .quiz-statement {
    //padding: 12px;
    font-size: 120%;
  }

  .zen-shop-items-container {
    padding-top: 125px;
  }

  // .walkthrough-page{
  //   max-width:480px;
  //   width:480px;
  // }
}

@media only screen and (max-width:414px) {
  // .walkthrough-page{
  //   max-width:322px;
  //   width:322px;
  // }

  .journal-text-input {
    height: 300px;
  }
}


@media only screen and (max-width:376px) {

  .my-zen-MyStuff-container {
    padding-top: 25px;
    height: 150px;
    max-height: 150px;
    overflow-y: scroll;
    padding-bottom: 100px;
  }

  .zenc-full-page-scroll {
    height: 450px;
  }

  .zen-sign-up-title {
    font-size: 120%;
  }

  .ZenClogoLarge {
    margin-top: 25px;
    width: 210px;
    height: 210px;

  }

  .monksWanted {
    font-size: 130%;
  }

  .monksWanted-button {
    margin-right: 135px;
    margin-left: 20px;
    margin-top: 15px;
    text-align: justify;
  }

  .sign-up-here-button {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 90%;
  }

  .my-notifications-container {
    padding-top: 15px;
    height: 150px;
  }



  .su-text-input {
    height: 45px;
  }

  .WelcomeQuizInner {
    height: fit-content;
    min-height: 70vh;
  }

  .quiz-statement {
    //padding: 10px;
    font-size: 100%;
  }


  .journal-text-input {
    height: 275px;
  }

  // .walkthrough-page{
  //   //max-width:376px;
  //   max-width:300px !important;
  //   background-color: orange;
  //   width:200px;
  // }


  .helper-bottom {
    top: auto;
    bottom: 0 !important;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width:322px) {

  // .walkthrough-page{
  //   max-width:322px;
  // }
  .zen-sign-up-title {
    font-size: 90%;
  }

  .ZenClogoLarge {

    width: 210px;
    height: 210px;

  }



  .quiz-statement {
    //padding: 9px;
    font-size: 90%;
  }

  .monksWanted {
    font-size: 120%;
  }

  .radar {
    padding-bottom: 0;
  }

  .monksWanted-button {
    margin-left: 20px;
    margin-right: 125px;
    text-align: center;
    bottom: 0;
    background-color: $ming-green;
    border: none;
    box-shadow: none;
  }

  .monksWanted-text {
    padding-right: 100px;
    padding-left: 20px;
    text-align: justify;
  }

  .su-text-input {
    height: 40px;
  }
}

@media only screen and (min-width:410px) {
  .portalBottomReversed {
    margin-top: 75px !important;
    padding-top: 100px;
  }
}

@media only screen and (min-width:480px) {

  .my-notifications-container {
    padding-top: 15px;
  }
  .NavbarCircleLogoHolder {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-top: 13px;
  }

  .NavbarLogoHolder {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-top: 5px;
  }

  .ZencyclopaediaLogo {
    margin-top: 10px;
    width: 80px;
    height: 80px;

  }

  .mobile-only {
    display: none;
  }
}

@media only screen and (min-width:768px) {

  .tshirtModalHolder {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: fit-content;
  }

  .zen-shop-items-container {
    padding-top: 100px;
  }

  // .splashedBacking {
  //   background-image: none;
  // }
  .monksWanted {
    font-size: 250%;
    max-width: 900px;
    margin-top: 125px;
  }

  .monksWanted-text {
    max-width: 900px;
  }

  .monksWanted-button {
    margin-top: 50px;
    font-weight: 900;
    font-size: 150%;

  }

  .ZenHomeAboutUs-WWO-list {
    font-size: 160%;
  }

  .large-screen-only {
    display: block;
  }

  .small-screen-only {
    display: none;
  }


  .nav-text-item {
    font-size: 300%;
  }



  .MyZenProfile {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 65px;
  }

  .MyZenPlanner {
    padding-top: 175px;
  }


  .MyZenJournal {
    padding-top: 175px;
  }

  .MyZenContent {
    margin-top: 0;
  }

  .MyZenDetails {
    margin-top: 0;
    min-width: 600px;
  }

  .NavbarCircleLogoHolder {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-top: 8px;
  }

  .NavbarLogoHolder {
    width: 70px;
    height: 70px;
    max-width: 70px;
    max-height: 70px;
    border-radius: 35px;
    margin-top:-5px;
  }

  .ZencyclopaediaLogo {
    margin-top: -3px;
    width: 80px;
    height: 80px;

  }

  .zen-sign-up-title {
    font-size: 300%;
  }

  .ZenHomeHolder {
    min-height: 105vh;
  }

  .ZenHomeBarcelona {
    display: flex;
    flex-direction: row;
  }

  .madeInBarcelona {
    width: 70vw;
    min-width: 70vw;
    max-width: 750px;
  }

  .madeInBarcelonaHeader {
    font-size: 85%;
    font-weight: 100;
  }

  .madeInBarcelonaHeaderContainer {
    margin: auto;
  }

  .radar {
    min-height: 500px;
  }
}

@media only screen and (min-width:1024px) {

  .myZenMain{
    padding-top:50px;
  }

  .viewAsGeneralUserLink{
    top:130px;
  }
  .followPractitionerButton{
    position:fixed;
    top:165;
    right:75;
  }

  .imageReSizerContainer{
    display:flex;
    flex-direction: row;
  }

  .ZenNavBar{
    height:75px;
    padding-bottom: 0;
  }
  
  .noBorderNavBar {
    height:75px;
  }
  .imageResizeResults{
    width:375px;
    padding-top: 50px;
  }

  .confirmProfilePicSizeContainer{
    padding-top:0;
  }

  .changeProfRow{
    display:flex !important;
    flex-direction: row;
    width:700px;
  }

.confirmProfilePicSizeContainer{
  width:750px;
}
  .zen-demo-video-player{
    padding-top:50px;
  }
  .desktopFlex{
    display:flex;
    flex-direction: row;
    min-width:600px;
  }
  .dfWE{
    max-width:400px;
    padding-left:50px;
    padding-right:50px;
  }
  .zenResourcePageSection{

    max-width: 90%;
    margin:auto;
    margin-bottom: 55px;

  }

  .paintedLineDivider{
    margin:auto;
    width:100%;
    max-width: 850px;
    background-size: contain;
    background-repeat: no-repeat;
    height:75px;
    margin-bottom: 0;
  }

  .accountTypesText{
    display:flex;
    flex-direction: row;
    h3{  
      text-align: center;
      }
    div{
      width:50%;
      padding-left:45px;
      padding-right: 45px;
    }
  }

  .buttonRow{
    margin:auto;
    width:fit-content !important;
  }

  .forcedMT{
    margin-top: 8px !important;
  }
  
  // .MyZenProfilePic{
  //   width:130px;
  //   height:130px;
  // }

  // .PractitionerMyZenProfilePic{
  //   width: 125px;
  //   height: 125px;
  //   overflow: hidden;
  // }

  // .MyZenProfilePicInactive {
  //   max-height: 95px;
  //   max-width:95px;
  // }


  .tshirtModalHolder {
    border-style: solid;
    border-width: 2px;
    border-radius: 25px;
    margin-top: 25px;
  }
  .zenTermsAndConditions{
    h1{
      font-size: 160%;
    }
    h2{
      font-size: 100%;
    }
    h4{
      text-align: center;
    }
    h3{
      text-align: center;
    }
  }
  .create-content-description-input{
    height:175px;
    max-height: 175px;
  }
  .MyZenProfile{
    margin-top: 0;
    padding-top: 45px;
  }
  .SubscriptionTierColoured{
  }
  .my-zen-carousel{
    margin-top: 0;
  }
  .MyZenNavBar {
    font-size: 80%;
    height: 35px;
    padding-top: 0px;
    padding-bottom: 5px;
    margin-top: 69px;
    h2{
      margin-top:0;
      padding-top: 0;
    }
  }

.white-page-inset{
  max-width:500px;
}  


  .infomatic-header{
    margin-top:50px;
  }
  .zen-su-title-row {
    padding-top: 50px;
  }

  .zen-shop-items-container {
    padding-top: 55px;
  }
  .splashedBacking{
    background-image: url("../../static/photos/splashedstripesbs.png");
  }


  .zen-sign-up-title {
    font-size: 400%;
    margin-top:5px;
  }
  .zen-mobile {
    display: none;
  }

  .zen-desktop {
    display: block;
  }

}

@media only screen and (min-width:1200px) {
  .zen-search {
    display: block;
  }
}

.chosen-qs {
  border-color: $sage;
}

.to-be-removed-q {
  border-color: $budd_orange;
}

.new-q {
  border-color: $bright-ornge;
}

.rejected-qs {
  border-color: $totem;
}