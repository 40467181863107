
$toms_blue: rgb(3, 63, 99);
$blue_fade: rgba(3, 63, 99, 0.7);
$primary_black: #272727;
$bright-ornge: #FFBC42;
$navajo_white: #FCEBC9;

.bubble-energy-energy-holder{
  margin:auto;
  margin-top:0px;
  color:$toms_blue;
  background-color: $primary_black;
  box-shadow: $navajo_white 0px 0px 15px inset;
  height:350px;
  width:350px;
  max-height:350px;
  max-width:350px;
  min-height:350px;
  min-width:350px;
  border-radius:50px;
}
.bubble-energy-container {
  margin:auto;
  margin-top:50px;
  display:block;
  width: fit-content;
  position:fixed;
}
.bubble-energy-breath-text{
  color:$bright-ornge;
}


.bubble-energy-breath {
  width:250px; 
  height:250px;
  min-width:250px; 
  min-height:250px;
  max-width:250px; 
  max-height:250px;
  background:$navajo_white;
  border-radius:50%;
  border-color: $navajo_white;
  border-width: 2px;
  border-style: solid;
  animation:energy-breath 4s infinite alternate;
  box-shadow: $navajo_white 0px 0px 25px;
  margin:auto;
  display:block;
  margin-left:45px;
  margin-top:-5px;
}
.bubble-energy-breath-slow {
  animation:energy-breath 8s infinite alternate;
  animation-delay: 1s infinite;
}
.bubble-energy-breath-fast {
  animation:energy-breath 2s infinite alternate;
  animation-delay: 1s infinite;
}


.breath-energy-void{
display:block;
position:absolute;
background-color: $primary_black;
box-shadow: $primary_black 0px 0px 15px;
height:100px;
width:100px;
border-radius:50%;
margin:auto;
margin-top:125px;
margin-left:125px;
text-align:center;
z-index:51;
}


@keyframes energy-breath {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
    border-width: 10px;
  }
}

